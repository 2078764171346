<template>
    <div class="space-y-2">
        <div class="text-sm font-bold text-iel-blue">Stop #{{ stop.order }} ({{ stop.type }})</div>
        <div class="text-xs text-iel-dark-gray">
            {{ fullAddress }}
        </div>
        <AppCheckbox
            v-model="form.location"
            label="Copy"
            name="location" />
        <AppCheckbox
            v-if="form.location"
            v-model="form.details"
            label="Include Details"
            name="details" />
        <AppCheckbox
            v-if="form.location"
            v-model="form.lineItems"
            label="Include Line Items"
            name="line_items" />
    </div>
</template>

<script setup>
const { stop } = defineProps({
    stop: Object
});

const emit = defineEmits(['update']);

const form = ref({
    id: stop.id,
    location: true,
    details: true,
    lineItems: true
});

watch(
    form,
    value => {
        emit('update', toRaw(value));
    },
    { immediate: true, deep: true }
);

const fullAddress = computed(() => {
    const address = stop?.location?.address;
    if (!address) return '';

    return [address.address_1, address.address_2, address.city, address.region, address.postal_code]
        .filter(Boolean)
        .join(', ');
});
</script>
