<template>
    <li v-if="loadboard.layout !== 'list'">
        <AppCard
            :id="`load-card-${load.id}`"
            :theme="theme"
            :content-class="['p-2 flex flex-col gap-2', { '!cursor-default !select-none': !regular }]"
            overflow-class="overflow-visible"
            index
            @click-inside="handleClickInside">
            <template #title>
                <Link
                    :href="route('loads.show', load.id)"
                    class="font-semibold text-white">
                    {{ load.customer?.name }} #{{ load.po_number }}
                </Link>
            </template>
            <template #nav>
                <div
                    ref="nav"
                    class="relative flex items-center gap-3">
                    <p
                        class="text-iel-light-gray"
                        v-text="load.is_tonu ? 'TONU' : load.is_canceled ? 'CANCELED' : ''"></p>
                    <div
                        v-if="pastDueCount > 0 || warningCount > 0"
                        class="group">
                        <AppWarningIcon
                            size="md"
                            class="mr-1" />
                        <span
                            class="invisible absolute right-2 z-10 w-36 overflow-hidden text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                            {{ pastDueOrWarningTooltipText }}
                        </span>
                    </div>
                    <div
                        v-if="load.all_risk && !load.all_risk?.resolved_at"
                        class="group">
                        <AppIcon
                            name="fas fa-shield-exclamation"
                            class="text-white" />
                        <span
                            class="invisible absolute right-2 z-10 w-32 overflow-hidden text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                            {{ $t('All Risk Pending') }}
                        </span>
                    </div>
                    <div
                        v-if="load.all_risk?.resolution == 'approved'"
                        class="group">
                        <AppIcon
                            name="fas fa-shield-check"
                            class="text-white" />
                        <span
                            class="invisible absolute right-2 z-10 w-36 overflow-hidden text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                            {{ $t('All Risk Approved') }}
                        </span>
                    </div>
                    <div
                        v-if="load.all_risk?.resolution == 'denied'"
                        class="group">
                        <AppIcon
                            name="fas fa-shield-xmark"
                            class="text-white" />
                        <span
                            class="invisible absolute right-2 z-10 w-32 overflow-hidden text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                            {{ $t('All Risk Denied') }}
                        </span>
                    </div>
                    <AppIcon
                        v-if="load.claim"
                        name="fas fa-gavel"
                        class="text-white" />
                    <AppIcon
                        v-if="load.carrier_incident"
                        name="fas fa-handshake"
                        class="text-white" />
                    <Link
                        :href="route('loads.show', load.id)"
                        aria-label="navigate to this load's detail page">
                        <AppIcon
                            name="far fa-arrows-maximize"
                            class="text-white" />
                    </Link>
                    <LoadCardActionMenu
                        :load="load"
                        :options="options" />
                </div>
            </template>
            <LoadCardStatus :load="load" />
            <LoadPhases
                v-if="regular"
                :load="load"
                :force-checkbox-taskbar="true" />
            <LoadCardInfo
                :load="load"
                @scroll="scrollToText" />
            <LoadCardStopHighlight
                v-if="regular"
                :load="load" />
            <AppTabs
                v-if="regular"
                ref="tabs"
                class="max-h-80"
                @activated-tab="handleTabActivation">
                <AppTab
                    :title="$t('Notes')"
                    class="py-2"
                    active>
                    <LoadCardNotesTab :load="load" />
                </AppTab>
                <AppTab
                    id="load-text-tab"
                    :title="$t('Text')"
                    class="py-2">
                    <LoadCardTextTab
                        ref="textTabs"
                        :load="load" />
                </AppTab>
                <AppTab
                    :title="$t('Checkcalls')"
                    class="py-2">
                    <LoadCardCheckcallsTab :load="load" />
                </AppTab>
                <AppTab
                    :title="$t('Tracking')"
                    class="py-2">
                    <LoadTracking :load="load" />
                </AppTab>
                <AppTab
                    :title="$t('Docs')"
                    :badge="load.notifications_count"
                    class="py-2">
                    <LoadCardDocsTab
                        :object="load"
                        object-name="load"
                        :document-type-options="loadDocumentTypes"
                        :stops-options="stopsOptions"
                        :accepted-file-types="['application/pdf', 'image/jpeg', 'image/png']"
                        :store-route="route('loads.documents.store', { load: load })"
                        download-route-name="loads.documents.download"
                        show-route-name="loads.documents.show"
                        destroy-route-name="loads.documents.destroy"
                        update-route-name="loads.documents.update"
                        permission="upload_load_documents" />
                </AppTab>
                <AppTab
                    v-if="load.billingErrors.length"
                    :title="$t('Exceptions')"
                    :badge="load.billingErrors.length"
                    class="py-2">
                    <LoadCardBillingErrorsTab :load="load" />
                </AppTab>
            </AppTabs>
        </AppCard>
    </li>
</template>

<script setup>
import { nextTick, ref } from 'vue';
import { loadboard } from '@/stores';
import { loadDocumentTypes } from '@/data.js';

import axios from 'axios';

const { load, options } = defineProps({
    load: Object,
    options: Object
});

const tabs = ref();
const nav = ref();
const regular = computed(() => loadboard.value.layout === 'regular');
const textTabs = ref(null);

const stopsOptions = computed(() => {
    return (load.stops || []).map((stop, index) => {
        return {
            label: `Stop # ${index + 1} (${stop.location.name})`,
            value: stop.id
        };
    });
});

const warningCount = computed(() => {
    const now = new Date();
    return load.tasks.filter(
        task =>
            task.due_at &&
            !task.is_complete &&
            task.warning_minutes !== 0 &&
            now >= new Date(new Date(task.due_at).getTime() - task.warning_minutes * 60000) &&
            now < new Date(task.due_at)
    ).length;
});

const pastDueCount = computed(() => {
    const now = new Date();
    return load.tasks.filter(task => task.due_at && !task.is_complete && now >= new Date(task.due_at)).length;
});

const pastDueOrWarningTooltipText = computed(() => {
    return `${pastDueCount.value > 0 ? `${pastDueCount.value} overdue task(s)` : ''}${pastDueCount.value > 0 && warningCount.value > 0 ? ', ' : ''}${warningCount.value > 0 ? `${warningCount.value} task(s) due soon` : ''}`;
});

const theme = computed(() => (pastDueCount.value > 0 ? 'past_due' : warningCount.value > 0 ? 'warning' : 'blue'));

function handleClickInside() {
    if (loadboard.value.layout === 'mini') {
        loadboard.value.layout = 'regular';
        // Ensures clicked card is still visible after layout shift.
        nextTick(() => nav.value.scrollIntoView(true));
    }
}

function handleTabActivation(tab) {
    if (tab.title === 'Docs') {
        axios.post(route('read-notifications', { type: 'load', id: load.id }));
        load.notifications_count = 0;
    }
}

function scrollToText(tabName) {
    textTabs.value.activateTab(tabName);

    let tab = tabs.value.tabs.filter(tab => tab.title === 'Text')[0];
    tabs.value.activateTab(tab);

    nextTick(() => {
        const element = document.getElementById('load-text-tab');
        element.scrollIntoView({ behavior: 'smooth' });
    });
}
</script>
