<template>
    <AppCard
        :id="getId('load-*-pay-carrier', [load])"
        :title="$t('Pay Carrier')"
        theme="blue"
        content-class="p-4">
        <LoadBillCarrierRates :load="load" />

        <div class="flex items-center justify-between gap-2">
            <div class="flex flex-col gap-2">
                <AppHeading size="md">{{ $t('Accessorials') }}</AppHeading>
                <p>{{ load.total_carrier_accessorial_amount }}</p>
            </div>
            <div class="flex flex-col gap-2">
                <AppHeading size="md">{{ $t('Advances') }}</AppHeading>
                <p>{{ load.total_advances }}</p>
            </div>
            <div class="flex flex-col gap-2">
                <AppHeading size="lg">{{ $t('Total Carrier Pay') }}</AppHeading>
                <p class="text-lg">{{ load.grand_carrier_total }}</p>
            </div>
        </div>
    </AppCard>
</template>

<script setup>
import { getId } from '@/helpers';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});
</script>
