<template>
    <div id="tenders-list">
        <TendersList
            v-if="generalboard.layout === 'list'"
            :active="activeTenders"
            :inactive="inactiveTenders"
            :options="options" />
        <TenderCards
            v-if="generalboard.layout === 'card'"
            :tenders="activeTenders.data"
            :options="options" />
    </div>
</template>
<script setup>
import { generalboard } from '@/stores';
import TendersLayout from '@/Layouts/TendersLayout.vue';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import TenderCards from '@/Pages/Tenders/Partials/TenderCards.vue';
import TendersList from '@/Pages/Tenders/Partials/TendersList.vue';

defineOptions({
    layout: [AuthenticatedLayout, TendersLayout]
});

const { activeTenders, inactiveTenders } = defineProps({
    activeTenders: {
        type: Object,
        required: true,
        default: () => {}
    },
    inactiveTenders: {
        type: Object,
        required: true,
        default: () => {}
    },
    options: {
        type: Object,
        required: true,
        default: () => ({
            rejection_reasons: []
        })
    }
});
</script>
