import { sum } from 'lodash-es';

export function useLoadStats(load) {
    const reactiveLoad = isRef(load) ? load : ref(load);

    if (!isRef(load)) {
        watch(
            () => load,
            newLoad => {
                reactiveLoad.value = newLoad;
            },
            { deep: true }
        );
    }

    const carrierPay = computed(() => parseFloat(reactiveLoad.value.total_pay_carrier_amount ?? 0));

    const carrierMinPay = computed(() => parseFloat(reactiveLoad.value.min_pay_carrier_amount ?? 0));

    const carrierMaxPay = computed(() => parseFloat(reactiveLoad.value.max_pay_carrier_amount ?? 0));

    const carrierAccessorials = computed(() =>
        sum(
            reactiveLoad.value.accessorialTransactions
                .filter(item => item.pay_carrier)
                .map(item => parseFloat(item.total))
        )
    );

    const carrierAdvances = computed(() =>
        sum(
            reactiveLoad.value.advances
                .filter(advance => advance.charge_carrier)
                .map(advance => parseFloat(advance.amount))
        )
    );

    const carrierAdvancesThirdParty = computed(() =>
        sum(
            reactiveLoad.value.advances
                .filter(advance => advance.sent_to === 'third_party')
                .map(advance => parseFloat(advance.amount))
        )
    );

    const customerAccessorials = computed(() =>
        sum(
            reactiveLoad.value.accessorialTransactions
                .filter(item => item.bill_to_customer)
                .map(item => parseFloat(item.total))
        )
    );

    const customerBilling = computed(() => parseFloat(reactiveLoad.value.total_customer_billing_amount ?? 0));

    const customerBillingTotal = computed(() => customerBilling.value + customerAccessorials.value);

    const carrierTotalPay = computed(() => carrierPay.value + carrierAccessorials.value - carrierAdvances.value);

    const profit = computed(() => customerBillingTotal.value - carrierTotalPay.value);

    const margin = computed(() =>
        profit.value && profit.value > 0 ? (profit.value / customerBillingTotal.value) * 100 : 0
    );

    return {
        carrierPay,
        carrierMinPay,
        carrierMaxPay,
        carrierAccessorials,
        carrierAdvances,
        carrierAdvancesThirdParty,
        customerAccessorials,
        customerBilling,
        customerBillingTotal,
        carrierTotalPay,
        profit,
        margin
    };
}
