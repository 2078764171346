<template>
    <AppCard
        :id="getId('load-*-details', [load])"
        :title="$t('Load Details')"
        theme="blue"
        content-class="p-4">
        <div class="grid grid-cols-12 gap-x-2 gap-y-4">
            <div class="col-span-2">
                <AppSelect
                    :id="getId('load-*-transportation-mode', [load])"
                    name="transportation_mode"
                    :model-value="load.transportation_mode"
                    permission="update_load_details"
                    :url="url"
                    :options="transportationModes"
                    :confirmation="confirmation"
                    :disabled="isDisabled" />

                <AppErrorMessage
                    :id="getId('load-*-transportation-mode-warning', [load])"
                    name="all_risk_required_ltl"
                    :error="allRiskRequiredLTL" />
            </div>

            <div class="col-span-2">
                <AppSelect
                    :id="getId('load-*-value-range', [load])"
                    name="value_range"
                    label="Load Value"
                    :model-value="load.value_range"
                    permission="update_load_details"
                    :url="url"
                    :options="valueRanges"
                    :confirmation="confirmation"
                    :disabled="isDisabled" />

                <AppErrorMessage
                    :id="getId('load-*-value-range-warning', [load])"
                    name="all_risk_required_value_range"
                    :error="allRiskRequiredValueRange" />
            </div>

            <AppSelect
                :id="getId('load-*-commodity-type', [load])"
                name="commodity_type"
                :model-value="load.commodity_type"
                permission="update_load_details"
                :url="url"
                :options="commodityTypes"
                :confirmation="confirmation"
                :disabled="isDisabled"
                class="col-span-2" />

            <AppSelect
                :id="getId('load-*-commodity-value', [load])"
                name="commodity_value"
                label="Commodity"
                :model-value="load.commodity_value"
                permission="update_load_details"
                :url="url"
                :options="commodityValues"
                :confirmation="confirmation"
                :disabled="isDisabled"
                class="col-span-2" />

            <AppSelect
                :id="getId('load-*-required-equipment-type', [load])"
                name="required_equipment_type"
                :model-value="load.required_equipment_type"
                permission="update_load_details"
                :url="url"
                :confirmation="confirmation"
                :disabled="isDisabled"
                :options="equipmentTypes"
                class="col-span-2" />

            <div class="col-span-2 grid grid-cols-3 items-start gap-0.5">
                <AppInput
                    :id="getId('load-*-temperature-value', [load])"
                    v-model="temperatureValue"
                    permission="update_load_details"
                    name="temperature"
                    :url="url"
                    class="col-span-2"
                    :confirmation="confirmation"
                    :disabled="tempDisabled || isDisabled" />

                <AppSelect
                    :id="getId('load-*-temperature-units', [load])"
                    name="temperature_units"
                    label=""
                    class="relative -bottom-6"
                    :model-value="temperatureUnitValue"
                    permission="update_load_details"
                    :url="url"
                    :options="{ c: 'C', f: 'F' }"
                    :confirmation="confirmation"
                    :disabled="tempDisabled || isDisabled" />
            </div>

            <AppDisplayField
                :id="getId('load-*-trip-miles', [load])"
                label="Trip Miles"
                :value="load.trip_miles?.toLocaleString()"
                :error="tripMilesError"
                class="col-span-1" />

            <AppInput
                :id="getId('load-*-customer-miles', [load])"
                v-model="load.customer_miles"
                permission="update_load_details"
                name="customer_miles"
                :disabled="isDisabled"
                :url="url"
                class="col-span-2" />

            <AppInput
                :id="getId('load-*-equipment length', [load])"
                v-model="load.equipment_length"
                permission="update_load_details"
                name="equipment_length"
                label="Equip. Length"
                :url="url"
                :confirmation="confirmation"
                :disabled="isDisabled"
                class="col-span-1" />

            <div class="col-span-2 flex items-end justify-center">
                <AppDisplayField
                    :id="getId('load-*-weight-value', [load])"
                    label="Total Weight"
                    :value="load.total_load_weight" />

                <AppDisplayField
                    :id="getId('load-*-weight-units', [load])"
                    label=""
                    :value="load.total_load_weight_description" />
            </div>

            <AppInput
                :id="getId('load-*-total-pallets', [load])"
                v-model="load.total_pallets"
                permission="update_load_details"
                name="total_pallets"
                :disabled="isDisabled"
                :url="url"
                class="col-span-2" />

            <AppInput
                :id="getId('load-*-total-pieces', [load])"
                v-model="load.total_pieces"
                permission="update_load_details"
                name="total_pieces"
                :disabled="isDisabled"
                :url="url"
                class="col-span-2" />
        </div>

        <div class="grid grid-cols-12 gap-x-2 gap-y-4 pt-3">
            <AppSelect
                id="rate-type"
                name="rate_type"
                :model-value="load.rate_type"
                permission="update_load_details"
                :url="url"
                :options="rateTypes"
                :confirmation="confirmation"
                :disabled="isDisabled"
                class="col-span-3" />

            <AppInput
                id="load-details-customer-po"
                v-model="load.customer_po"
                permission="update_load_details"
                name="customer_po"
                label="Customer PO"
                :disabled="isDisabled"
                :url="url"
                class="col-span-3" />

            <AppInput
                v-if="load.customer?.tracking_service === 'fourkites'"
                id="fourkites-shipmemnt-id"
                v-model="load.fourkites_shipment_id"
                permission="update_load_details"
                name="fourkites_shipment_id"
                label="Fourkites Shipment Id"
                :disabled="isDisabled"
                :url="url"
                class="col-span-3" />
        </div>

        <hr class="my-4 border-iel-gray" />

        <div class="grid grid-cols-2 gap-2">
            <div
                :id="getId('load-*-details-references', [load])"
                class="w-full">
                <AppHeading
                    :id="getId('load-*-references-header', [load])"
                    size="lg">
                    References
                </AppHeading>

                <table
                    v-if="form.references.length"
                    :id="getId('load-*-details-references-table', [load])"
                    class="NX-mobile-stacked w-full">
                    <thead>
                        <tr>
                            <th>{{ $t('Type') }}</th>
                            <th>{{ $t('Number') }}</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(reference, index) in form.references"
                            :key="index">
                            <td>
                                <div class="flex items-center gap-2">
                                    <AppSelect
                                        :id="getId('load-*-reference-*-description', [load, { id: index }])"
                                        v-model="reference.description"
                                        permission="update_load_billing_details"
                                        :name="`load-${load.id}-reference-description-${index}`"
                                        label=""
                                        class="w-full"
                                        :disabled="isDisabled"
                                        :options="{
                                            BOL: 'BOL',
                                            'Delivery Number': 'Delivery Number',
                                            PRO: 'PRO',
                                            SAP: 'SAP',
                                            'Customer Reference': 'Customer Reference',
                                            'Freight Agreement Number': 'Freight Agreement Number',
                                            'Purchase Order Number': 'Purchase Order Number',
                                            'Release Number': 'Release Number',
                                            Other: 'Other'
                                        }" />

                                    <AppInput
                                        v-if="reference.description === 'Other'"
                                        :id="getId('load-*-reference-*-other-description', [load, { id: index }])"
                                        v-model="reference.other"
                                        permission="update_load_billing_details"
                                        :name="`load-${load.id}-other-reference-description-${index}`"
                                        placeholder="Type"
                                        :disabled="isDisabled" />
                                </div>
                            </td>

                            <td>
                                <AppInput
                                    :id="getId('load-*-reference-*-number', [load, { id: index }])"
                                    v-model="reference.number"
                                    permission="update_load_billing_details"
                                    :name="`load-${load.id}-reference-number-${index}`"
                                    label=""
                                    placeholder="Number"
                                    :disabled="isDisabled" />
                            </td>

                            <td>
                                <AppButton
                                    :id="getId('load-*-reference-*-remove-button', [load, { id: index }])"
                                    variant="outline"
                                    icon="far fa-trash-can"
                                    type="button"
                                    permission="update_load_billing_details"
                                    :disabled="isDisabled"
                                    @click="removeReference(index)" />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p
                    v-else
                    class="text-sm">
                    {{ $t('This load has no references.') }}
                </p>

                <AppButton
                    :id="getId('load-*-reference-add-button', [load])"
                    permission="update_load_billing_details"
                    icon="far fa-circle-plus"
                    class="mt-2"
                    :disabled="!load.can.view_load_details"
                    @click="addReference">
                    {{ $t('Reference') }}
                </AppButton>
            </div>

            <AppTextarea
                :id="getId('load-*-general-notes', [load])"
                v-model="load.internal_general_notes"
                permission="update_load_details"
                name="internal_general_notes"
                label="Internal General Notes"
                input-class="resize-none"
                rows="8"
                :url="generalNotesUrl"
                :disabled="isDisabled" />
        </div>
    </AppCard>
</template>

<script setup>
import { useLoadDetails } from '@/Composables/useLoadDetails';
import { useRateCon } from '@/Composables/useRateCon';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const loadRef = toRef(() => load);
const ratecon = useRateCon(loadRef);

const {
    url,
    generalNotesUrl,
    equipmentTypes,
    valueRanges,
    transportationModes,
    commodityTypes,
    commodityValues,
    rateTypes,
    form,
    temperatureValue,
    temperatureUnitValue,
    tripMilesError,
    removeReference,
    addReference,
    tempDisabled,
    isDisabled,
    getId,
    confirmation
} = useLoadDetails(load, {
    entityType: 'load',
    usePermissions: true,
    useConfirmation: true,
    confirmChange: ratecon.confirmChange
});

const allRiskRequiredLTL = computed(() => {
    if (load.transportation_mode === 'LTL' && !load.all_risk) {
        return 'Please create an all risk insurance request for this load.';
    }
    return null;
});

const allRiskRequiredValueRange = computed(() => {
    if (load.value_range === '$100001+' && !load.all_risk) {
        return 'Please create an all risk insurance request for this load.';
    }
    return null;
});
</script>
