<template>
    <div class="flex-1 rounded-md bg-iel-light-gray p-3 text-center font-bold">
        <div :class="[textSize]">{{ label }}</div>
        <div class="text-xl">{{ value }}</div>
    </div>
</template>

<script setup>
defineProps({
    label: String,
    value: String,
    textSize: {
        type: String,
        default: 'text-sm'
    }
});
</script>
