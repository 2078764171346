<template>
    <AppCard
        :title="$t('Documents')"
        theme="white">
        <div class="flex flex-col gap-4 p-2">
            <CarrierDocsList v-if="carrier.documents.length > 0">
                <CarrierDocsItem
                    v-for="document in carrier.documents"
                    :key="document.id"
                    :carrier="carrier"
                    :document="document"
                    :document-types="documentTypes" />
            </CarrierDocsList>
            <div
                v-else
                class="text-sm">
                {{ $t('No documents found on this carrier.') }}
            </div>
            <AppDocsDragDrop
                :id="`carrier-${carrier.id}-files-new`"
                v-model="pending"
                permission="upload_carrier_documents"
                :accepted-file-types="['application/pdf', 'image/jpeg', 'image/png']" />
            <div
                v-if="pending.length > 0"
                class="flex flex-col">
                <h2 class="text-sm font-bold">{{ $t('Pending Uploads') }}</h2>
                <CarrierDocsList>
                    <CarrierDocsPendingItem
                        v-for="(file, index) in pending"
                        :key="file.name"
                        :carrier="carrier"
                        :pending-file="file"
                        :document-types="documentTypes"
                        @remove="removePending(index)" />
                </CarrierDocsList>
            </div>
        </div>
    </AppCard>
</template>

<script setup>
defineProps({
    carrier: Object,
    documentTypes: Object
});

const pending = ref([]);

function removePending(i) {
    pending.value.splice(i, 1);
}
</script>
