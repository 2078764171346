<template>
    <label
        v-if="label"
        :for="name"
        class="block text-nowrap text-sm"
        :class="[{ 'text-iel-dark-gray': !hasCustomTextColor }, textClasses]"
        data-test="label">
        {{ label }}
        <span
            v-if="required"
            class="select-none text-iel-dark-red">
            *
        </span>
    </label>
</template>

<script setup>
const { textClasses } = defineProps({
    label: String,
    name: String,
    required: Boolean,
    textClasses: {
        type: String,
        required: false
    }
});

const hasCustomTextColor = computed(() => {
    return textClasses && textClasses.includes('text-iel-');
});
</script>
