<template>
    <ul
        v-if="quotes.length > 0"
        class="grid grid-cols-3 gap-2">
        <li
            v-for="quote in quotes"
            :id="getId('quote-*-card', [quote])"
            :key="quote.id">
            <AppCard
                theme="blue"
                index>
                <template #title>
                    <Link
                        :href="route('customers.show', quote.customer.id)"
                        class="flex gap-2 overflow-hidden font-semibold text-white">
                        <p class="underline">{{ quote.customer?.name }}</p>
                        <p>#{{ quote.id }}</p>
                    </Link>
                </template>

                <div class="border-b">
                    <QuoteTasks :quote="quote" />
                </div>

                <div
                    :id="getId('quote-*-stops-table', [quote])"
                    class="grid w-full gap-8 px-2 py-4">
                    <table class="w-full whitespace-nowrap text-left">
                        <thead>
                            <tr class="pb-4">
                                <th>{{ $t('Lane') }}</th>
                                <th>{{ $t('Date') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="quote.lane">
                                <tr>
                                    <td class="py-1 text-sm">{{ formatLane(quote).origin }}</td>
                                    <td class="py-1 text-sm">{{ formatLane(quote).startDate }}</td>
                                </tr>
                                <tr>
                                    <td class="py-1 text-sm">{{ formatLane(quote).destination }}</td>
                                    <td class="py-1 text-sm">{{ formatLane(quote).endDate }}</td>
                                </tr>
                            </template>
                            <tr v-else>
                                <td
                                    colspan="2"
                                    class="py-1 text-center text-sm"></td>
                            </tr>
                        </tbody>
                    </table>

                    <div
                        :id="getId('quote-*-load-meta', [quote])"
                        class="flex items-start gap-8">
                        <div>
                            <p class="mb-2 font-semibold">Equipment Required</p>
                            <p class="text-sm">{{ quote.required_equipment_type }}</p>
                        </div>
                        <div>
                            <p class="mb-2 font-semibold">Weight</p>
                            <p class="text-sm">
                                {{ quote.total_load_weight }}
                                {{ quote.total_load_weight_description }}
                            </p>
                        </div>
                        <div>
                            <p class="mb-2 font-semibold">Miles</p>
                            <p class="text-sm">{{ quote.trip_miles }}</p>
                        </div>
                    </div>
                </div>

                <template #nav>
                    <Link
                        :id="getId('view-quote-*-details', [quote])"
                        :href="route('quotes.show', quote.id)"
                        aria-label="navigate to this quote's detail page">
                        <AppIcon
                            name="far fa-arrows-maximize"
                            class="text-white" />
                    </Link>
                </template>
            </AppCard>
        </li>
    </ul>

    <p
        v-else
        class="p-4">
        {{ $t('There are no quotes.') }}
    </p>
</template>

<script setup>
import { getId, formatLane } from '@/helpers';

const { quotes } = defineProps({
    quotes: {
        type: Array,
        required: true,
        default: () => []
    }
});
</script>
