<template>
    <div class="grid grid-cols-2 gap-3 text-sm text-iel-gray">
        <div>
            <p>{{ $t('Carrier') }}</p>
            <div class="font-medium text-iel-blue">
                <a
                    v-if="load.carrier"
                    :href="route('carriers.show', load.carrier.id)">
                    {{ load.carrier?.name }}
                </a>
                <p v-else>-</p>
            </div>
        </div>
        <div>
            <p>{{ $t('Dispatcher') }}</p>
            <div class="flex items-center gap-1">
                <div class="font-medium text-iel-blue">{{ load.dispatcher?.name || '-' }}</div>
                <template v-if="load.dispatcher?.phone_number">
                    <a
                        :href="`tel:${load.dispatcher.phone_number}`"
                        :title="load.dispatcher.phone_number"
                        class="block">
                        <AppIcon
                            class="h-4 cursor-pointer text-iel-blue transition hover:text-iel-orange"
                            name="fas fa-phone" />
                    </a>
                    <button
                        :disabled="loadDisabled"
                        @click="scrollToText('Dispatcher')">
                        <AppIcon
                            class="h-5 cursor-pointer text-iel-blue transition hover:text-iel-orange"
                            name="fal fa-comment-sms" />
                    </button>
                </template>
            </div>
        </div>
        <div>
            <p>{{ load.driver2 ? $t('Driver 1') : $t('Driver') }}</p>
            <div class="flex items-center gap-1">
                <div class="font-medium text-iel-blue">{{ load.driver?.name || '-' }}</div>
                <template v-if="load.driver?.phone_number">
                    <a
                        :href="`tel:${load.driver.phone_number}`"
                        :title="load.driver.phone_number"
                        class="block">
                        <AppIcon
                            class="h-4 cursor-pointer text-iel-blue transition hover:text-iel-orange"
                            name="fas fa-phone" />
                    </a>
                    <button
                        :disabled="loadDisabled"
                        @click="scrollToText('Driver')">
                        <AppIcon
                            class="h-5 cursor-pointer text-iel-blue transition hover:text-iel-orange"
                            name="fal fa-comment-sms" />
                    </button>
                </template>
            </div>
        </div>
        <div v-if="load.driver2">
            <p>{{ $t('Driver 2') }}</p>
            <div class="flex items-center gap-1">
                <p class="mb-1 font-medium text-iel-blue">{{ load.driver2?.name || '-' }}</p>
                <template v-if="load.driver2?.phone_number">
                    <a
                        :href="`tel:${load.driver2.phone_number}`"
                        :title="load.driver2.phone_number"
                        class="block">
                        <AppIcon
                            class="h-4 cursor-pointer text-iel-blue transition hover:text-iel-orange"
                            name="fas fa-phone" />
                    </a>
                    <button
                        :disabled="loadDisabled"
                        @click="scrollToText('Driver 2')">
                        <AppIcon
                            class="h-5 cursor-pointer text-iel-blue transition hover:text-iel-orange"
                            icon="fal fa-comment-sms" />
                    </button>
                </template>
            </div>
        </div>
    </div>
    <hr />
</template>

<script setup>
const { load } = defineProps({
    load: Object
});

const emit = defineEmits(['scroll']);

function scrollToText(tag) {
    emit('scroll', tag);
}

const loadDisabled = computed(() => load.is_tonu || load.is_canceled);
</script>
