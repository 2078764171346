<template>
    <AppCard
        :title="$t('Load Details')"
        theme="blue"
        content-class="p-4">
        <div class="grid grid-cols-12 gap-x-2 gap-y-4">
            <AppDisplayField
                :id="getId('tender-*-transportation-mode', [tender])"
                name="transportation_mode"
                :value="tender.transportation_mode"
                :label="$t('Transportation Mode')"
                class="col-span-2" />
            <AppDisplayField
                :id="getId('tender-*-value-range', [tender])"
                name="value_range"
                :value="tender.value_range"
                :label="$t('Value Range')"
                class="col-span-2" />
            <AppDisplayField
                :id="getId('tender-*-commodity-type', [tender])"
                name="commodity_type"
                :value="tender.commodity_type"
                :label="$t('Commodity Type')"
                class="col-span-2" />
            <AppDisplayField
                :id="getId('tender-*-commodity-value', [tender])"
                name="commodity_value"
                :value="tender.commodity_value"
                :label="$t('Commodity')"
                class="col-span-2" />
            <AppDisplayField
                :id="getId('tender-*-required-equipment-type', [tender])"
                name="required_equipment_type"
                :value="tender.required_equipment_type"
                :label="$t('Required Equipment Type')"
                class="col-span-2" />
            <div class="col-span-2 grid grid-cols-3 items-start gap-1">
                <AppDisplayField
                    :id="getId('tender-*-temperature', [tender])"
                    name="temperature"
                    :value="tender.temperature"
                    :label="$t('Temperature')"
                    class="col-span-2" />
                <AppDisplayField
                    :id="getId('tender-*-temperature-units', [tender])"
                    name="temperature_units"
                    :value="tender.temperature_units"
                    class="relative -bottom-6"
                    label="" />
            </div>
            <AppDisplayField
                :id="getId('tender-*-customer-miles', [tender])"
                name="customer_miles"
                :value="tender.customer_miles"
                :label="$t('Customer Miles')"
                class="col-span-2" />
            <AppDisplayField
                :id="getId('tender-*-equipment-length', [tender])"
                name="equipment_length"
                :value="tender.equipment_length"
                :label="$t('Equipment Length')"
                class="col-span-1" />
            <div class="col-span-2 flex items-end justify-center gap-1">
                <AppDisplayField
                    :id="getId('tender-*-total-weight', [tender])"
                    name="total_weight"
                    :value="tender.total_load_weight"
                    :label="$t('Total Weight')" />
                <AppDisplayField
                    :id="getId('tender-*-total-weight-units', [tender])"
                    name="total_weight_units"
                    :value="tender.total_load_weight_description"
                    label="" />
            </div>
            <AppDisplayField
                :id="getId('tender-*-total-pallets', [tender])"
                name="total_pallets"
                :value="tender.total_pallets"
                :label="$t('Total Pallets')"
                class="col-span-2" />
            <AppDisplayField
                :id="getId('tender-*-total-pieces', [tender])"
                name="total_pieces"
                :value="tender.total_pieces"
                :label="$t('Total Pieces')"
                class="col-span-2" />
        </div>

        <hr
            class="my-4 border-iel-gray"
            disabled />

        <div class="grid grid-cols-2 gap-2">
            <div class="w-full">
                <AppHeading size="lg">
                    {{ $t('References') }}
                </AppHeading>

                <table
                    v-if="form.references.length"
                    :id="getId('tender-*-details-references-table', [tender])"
                    class="NX-mobile-stacked w-full">
                    <thead>
                        <tr>
                            <th>{{ $t('Type') }}</th>
                            <th>{{ $t('Number') }}</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(reference, index) in form.references"
                            :key="index">
                            <td>
                                <div class="flex items-center gap-2">
                                    <AppDisplayField
                                        :id="getId('tender-*-reference-*-description', [tender, { id: index }])"
                                        :value="reference.description"
                                        :name="`tender-${tender.id}-reference-description-${index}`"
                                        label=""
                                        class="w-full" />
                                    <AppDisplayField
                                        v-if="reference.description === 'Other'"
                                        :id="getId('tender-*-reference-*-other-description', [tender, { id: index }])"
                                        :value="reference.other"
                                        :name="`tender-${tender.id}-other-reference-description-${index}`"
                                        label=""
                                        class="w-full" />
                                </div>
                            </td>
                            <td>
                                <AppDisplayField
                                    :id="getId('tender-*-reference-*-number', [tender, { id: index }])"
                                    :value="reference.number"
                                    :name="`tender-${tender.id}-reference-number-${index}`"
                                    label=""
                                    class="w-full" />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p
                    v-else
                    class="text-sm">
                    {{ $t('This tender has no references.') }}
                </p>
            </div>

            <AppTextarea
                :id="getId('tender-*-details-general-notes', [tender])"
                :model-value="tender.general_notes"
                name="internal_general_notes"
                label="General Notes"
                rows="8"
                disabled />
        </div>
    </AppCard>
</template>

<script setup>
import { useLoadDetails } from '@/Composables/useLoadDetails';

const { tender } = defineProps({
    tender: {
        type: Object,
        required: true,
        default: () => {}
    }
});

const { form, getId } = useLoadDetails(tender, {
    entityType: 'tender'
});
</script>
