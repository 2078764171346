<template>
    <div class="sr-only">
        <h2>{{ title }}</h2>
        <ul
            v-for="(label, index) in data.labels"
            :key="index">
            <li>{{ label }}: {{ data.datasets[0].data[index] }}</li>
        </ul>
    </div>
</template>

<script setup>
defineProps({
    title: String,
    data: {
        type: [Object, Array],
        default: () => []
    }
});
</script>
