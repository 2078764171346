<template>
    <tr>
        <td>
            <AppInput
                :id="getId('load-*-*-*-quantity', idParams)"
                :scope="transaction.id"
                :model-value="transaction.quantity"
                name="quantity"
                placeholder="Quantity"
                :permission="permission"
                type="number"
                :min="0"
                :url="url"
                :disabled="isDisabled" />
        </td>

        <td>
            <AppInput
                :id="getId('load-*-*-*-amount', idParams)"
                :scope="transaction.id"
                :model-value="transaction.amount"
                name="amount"
                placeholder="Amount"
                type="number"
                :permission="permission"
                :min="0"
                :url="url"
                :disabled="isDisabled" />
        </td>

        <td>
            <AppSelect
                :id="getId('load-*-*-*-type', idParams)"
                :scope="transaction.id"
                :model-value="transaction.line_type"
                name="line_type"
                required
                placeholder="Type"
                :permission="permission"
                :options="rateTypes"
                :url="url"
                :disabled="isDisabled" />
        </td>

        <td>
            <div class="whitespace-nowrap text-sm">{{ formatMoney(transaction.total) }}</div>
        </td>

        <td>
            <AppInput
                :id="getId('load-*-*-*-note', idParams)"
                :scope="transaction.id"
                :model-value="transaction.note"
                name="note"
                placeholder="Note"
                type="text"
                :permission="permission"
                :url="url"
                :disabled="isDisabled" />
        </td>

        <td>
            <AppButton
                :id="getId('load-*-*-*-remove-button', idParams)"
                variant="outline"
                icon="far fa-trash-can"
                type="button"
                :disabled="loading || isDisabled"
                :permission="permission"
                class="ml-auto"
                @click="removeRate(transaction.id)" />
        </td>
    </tr>
</template>

<script setup>
import { router, usePage } from '@inertiajs/vue3';
import { zipObject, values } from 'lodash-es';
import { formatMoney, getId } from '@/helpers';
import { useTasks } from '@/Composables/useTasks';

const { load, transaction, permission } = defineProps({
    load: {
        type: Object,
        required: true
    },
    transaction: {
        type: Object,
        default: () => {}
    },
    permission: String
});

const url = route('loads.billing.thirdParty.update', { load, transaction });

const page = usePage();
const rateTypes = zipObject(
    values(page.props.options.third_party_rate_types),
    values(page.props.options.third_party_rate_types)
);

const loading = ref(false);

function removeRate(transaction) {
    router.delete(route('loads.billing.thirdParty.destroy', [load, transaction]), {
        preserveScroll: true,
        only: ['load'],
        onBefore() {
            loading.value = true;
        },
        onSuccess() {
            loading.value = false;
        }
    });
}

const isDisabled = computed(() => {
    return (
        load.is_canceled_or_tonu ||
        !load.can.view_load_details ||
        useTasks(load).hasCompletedTasks(['pay_carrier', 'collect_payment'])
    );
});

const idParams = [{ id: load.id }, { id: transaction.bill_type }, { id: transaction.id }];
</script>
