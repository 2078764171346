<template>
    <section
        :id="getId('quote-*-stops-*-box-line-items', [quote, stop])"
        class="p-2">
        <h2 class="font-bold text-iel-blue">{{ $t('Line Items') }}</h2>
        <ul
            v-if="stop.line_items.length > 0"
            :id="`quote-${quote.id}-stop-${stop.id}-line-items`">
            <li
                v-for="item in stop.line_items"
                :id="`line-item-${item.id}`"
                :key="item.id"
                class="mb-4 grid items-end gap-2 pt-8 first-of-type:pt-0 lg:grid-cols-3 xl:grid-cols-11 xl:pt-0">
                <AppInput
                    :id="`quote-${quote.id}-stop-${stop.id}-shipment-reference-number-${item.id}`"
                    v-model="item.shipment_reference_number"
                    permission="update_stops"
                    name="shipment_reference_number"
                    :scope="stop.id"
                    label="Shipment Ref#"
                    :url="route('provisionalLoadStops.lineItems.update', item)" />
                <AppInput
                    :id="`quote-${quote.id}-stop-${stop.id}-shipment-po-${item.id}`"
                    v-model="item.shipment_po"
                    permission="update_stops"
                    name="shipment_po"
                    :scope="stop.id"
                    label="Shipment PO"
                    :url="route('provisionalLoadStops.lineItems.update', item)" />
                <AppInput
                    :id="`quote-${quote.id}-stop-${stop.id}-weight-${item.id}`"
                    v-model="item.weight"
                    permission="update_stops"
                    name="weight"
                    :scope="stop.id"
                    label="Weight"
                    :url="route('provisionalLoadStops.lineItems.update', item)" />
                <AppSelect
                    :id="`quote-${quote.id}-stop-${stop.id}-weight-unit-${item.id}`"
                    name="weight_unit"
                    :scope="stop.id"
                    label="Unit"
                    class="min-w-[theme('spacing.14')]"
                    permission="update_stops"
                    :model-value="item.weight_unit"
                    :options="$page.props.units.weight"
                    :url="route('provisionalLoadStops.lineItems.update', item)" />
                <AppInput
                    :id="`quote-${quote.id}-stop-${stop.id}-volume-${item.id}`"
                    v-model="item.volume"
                    permission="update_stops"
                    name="volume"
                    :scope="stop.id"
                    label="Volume"
                    :url="route('provisionalLoadStops.lineItems.update', item)" />
                <AppSelect
                    :id="`quote-${quote.id}-stop-${stop.id}-volume-unit-${item.id}`"
                    name="volume_unit"
                    :scope="stop.id"
                    label="Unit"
                    class="min-w-[theme('spacing.14')]"
                    permission="update_stops"
                    :model-value="item.volume_unit"
                    :options="$page.props.units.volume"
                    :url="route('provisionalLoadStops.lineItems.update', item)" />
                <AppInput
                    :id="`quote-${quote.id}-stop-${stop.id}-pieces-${item.id}`"
                    v-model="item.pieces"
                    permission="update_stops"
                    name="pieces"
                    :scope="stop.id"
                    label="Pieces"
                    :url="route('provisionalLoadStops.lineItems.update', item)" />
                <AppSelect
                    :id="`quote-${quote.id}-stop-${stop.id}-piece-unit-${item.id}`"
                    name="pieces_unit"
                    :scope="stop.id"
                    label="Unit"
                    class="min-w-[theme('spacing.14')]"
                    permission="update_stops"
                    :model-value="item.pieces_unit"
                    :options="$page.props.units.pieces"
                    :url="route('provisionalLoadStops.lineItems.update', item)" />
                <AppInput
                    :id="`quote-${quote.id}-stop-${stop.id}-description-${item.id}`"
                    v-model="item.description"
                    permission="update_stops"
                    name="description"
                    :scope="stop.id"
                    label="Commodity Description"
                    :url="route('provisionalLoadStops.lineItems.update', item)"
                    class="col-span-2" />
                <div>
                    <AppButton
                        icon="far fa-trash-can"
                        variant="outline"
                        class="inline-block transition hover:bg-iel-light-gray"
                        permission="update_stops"
                        @click="deleteLineItem(index)">
                        {{ $t('Delete') }}
                    </AppButton>
                </div>
            </li>
        </ul>
        <div v-else>{{ $t('No line items for this stop') }}</div>
        <AppButton
            :id="`quote-${quote.id}-stop-${stop.id}-add-new-line-item-button`"
            icon="fal fa-pen"
            class="mt-2 transition hover:bg-white hover:text-iel-blue"
            permission="update_stops"
            @click="addLineItem">
            {{ $t('New Line Item') }}
        </AppButton>
    </section>
</template>

<script setup>
import { router, useForm } from '@inertiajs/vue3';
import { getId } from '@/helpers';

const { quote, stop } = defineProps({
    quote: Object,
    stop: Object
});

function deleteLineItem(lineItem) {
    if (confirm('Delete Line Item?')) {
        router.delete(route('provisionalLoadStops.lineItems.destroy', [stop, lineItem]), {
            preserveScroll: true,
            onSuccess: () => {
                router.reload();
            }
        });
    }
}

function addLineItem() {
    router.post(route('provisionalLoadStops.lineItems.store', [stop]), null, {
        preserveScroll: true,
        onSuccess: () => {
            router.reload();
        }
    });
}

const form = useForm({
    references: stop.references?.length > 0 ? stop.references : [{ number: null, description: null, other: null }]
});

provide('form', form);
</script>
