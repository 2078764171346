<template>
    <div class="group relative">
        <a
            :href="`tel:${number}`"
            class="flex items-center gap-2">
            <AppIcon
                name="far fa-phone-volume"
                class="size-5 opacity-50 group-hover:opacity-100" />
            <div :class="{ 'flex items-center gap-2': inline }">
                <div :class="{ 'text-xs': !inline }">{{ $t(title) }}:</div>
                <div class="font-bold">{{ name }}</div>
            </div>
        </a>

        <div
            v-if="tooltip"
            class="absolute -bottom-8 z-50 hidden w-32 rounded-md border bg-white p-1 text-center text-sm font-semibold text-black shadow-md group-hover:block">
            {{ number }}
        </div>

        <div
            v-if="tooltipText"
            class="absolute -bottom-28 z-50 hidden w-60 rounded-md border bg-black p-1 text-center text-sm font-semibold text-white shadow-md group-hover:block">
            <slot name="text" />
        </div>
    </div>
</template>

<script setup>
defineProps({
    number: String,
    name: String,
    title: String,
    tooltip: Boolean,
    tooltipText: Boolean,
    inline: Boolean
});
</script>
