<template>
    <LoadCardNotes
        class="mt-2"
        :load="load" />
</template>

<script setup>
const { load } = defineProps({
    load: Object
});
</script>
