<template>
    <section
        :id="getId('tender-*-stops-*-box-line-items', [tender, stop])"
        class="p-2">
        <h2 class="font-bold text-iel-blue">{{ $t('Line Items') }}</h2>
        <ul
            v-if="stop.line_items.length > 0"
            :id="`tender-${tender.id}-stop-${stop.id}-line-items`">
            <li
                v-for="item in stop.line_items"
                :id="`line-item-${item.id}`"
                :key="item.id"
                class="mb-4 grid items-end gap-2 pt-8 first-of-type:pt-0 lg:grid-cols-3 xl:grid-cols-10 xl:pt-0">
                <AppDisplayField
                    :id="`tender-${tender.id}-stop-${stop.id}-shipment-reference-number-${item.id}`"
                    :value="item.shipment_reference_number"
                    name="shipment_reference_number"
                    :label="$t('Shipment Ref #')" />
                <AppDisplayField
                    :id="`tender-${tender.id}-stop-${stop.id}-shipment-po-${item.id}`"
                    :value="item.shipment_po"
                    name="shipment_po"
                    :label="$t('Shipment PO')" />
                <AppDisplayField
                    :id="`tender-${tender.id}-stop-${stop.id}-weight-${item.id}`"
                    :value="item.weight"
                    name="weight"
                    :label="$t('Weight')" />
                <AppDisplayField
                    :id="`tender-${tender.id}-stop-${stop.id}-weight-unit-${item.id}`"
                    :value="item.weight_unit"
                    name="weight_unit"
                    :label="$t('Unit')"
                    class="min-w-[theme('spacing.14')]" />
                <AppDisplayField
                    :id="`tender-${tender.id}-stop-${stop.id}-volume-${item.id}`"
                    :value="item.volume"
                    name="volume"
                    :label="$t('Volume')" />
                <AppDisplayField
                    :id="`tender-${tender.id}-stop-${stop.id}-volume-unit-${item.id}`"
                    :value="item.volume_unit"
                    name="volume_unit"
                    :label="$t('Unit')"
                    class="min-w-[theme('spacing.14')]" />
                <AppDisplayField
                    :id="`tender-${tender.id}-stop-${stop.id}-pieces-${item.id}`"
                    :value="item.pieces"
                    name="pieces"
                    :label="$t('Pieces')" />
                <AppDisplayField
                    :id="`tender-${tender.id}-stop-${stop.id}-piece-unit-${item.id}`"
                    :value="item.pieces_unit"
                    name="pieces_unit"
                    :label="$t('Unit')"
                    class="min-w-[theme('spacing.14')]" />
                <AppDisplayField
                    :id="`tender-${tender.id}-stop-${stop.id}-description-${item.id}`"
                    :value="item.description"
                    name="description"
                    :label="$t('Commodity Description')"
                    class="col-span-2" />
            </li>
        </ul>
        <div v-else>{{ $t('No line items for this stop') }}</div>
    </section>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { getId } from '@/helpers';

const { tender, stop } = defineProps({
    tender: Object,
    stop: Object
});

const form = useForm({
    references: stop.references?.length > 0 ? stop.references : [{ number: null, description: null, other: null }]
});

provide('form', form);
</script>
