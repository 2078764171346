<template>
    <AppIcon
        :title="mpStatusMessage"
        name="fa-circle"
        :class="mpStatusIconColor"
        class="h-5 w-5"
        solid />
</template>

<script setup>
const { mpStatusColor, mpStatusMessage } = defineProps({
    mpStatusColor: String,
    mpStatusMessage: String
});

const mpStatusColorMap = {
    red: 'text-iel-red',
    green: 'text-iel-green',
    yellow: 'text-iel-yellow'
};

const mpStatusIconColor = computed(() => mpStatusColorMap[mpStatusColor]);
</script>
