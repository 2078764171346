<template>
    <AppCard
        title="Claims"
        theme="light_gray"
        content-class="p-2 grid w-full gap-2 md:grid-cols-2">
        <AppDisplayField
            :id="`customer-group-${customerGroup.id}-total-active-claims`"
            label="Total Active Claims"
            :value="customerGroup.group_total_active_claims" />
    </AppCard>
</template>

<script setup>
defineProps({
    customerGroup: Object
});
</script>
