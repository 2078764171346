<template>
    <div class="grid grid-cols-4 items-center gap-1 border bg-iel-lightest-gray px-3 py-2 text-iel-blue">
        <div
            v-if="request.type === 'Credit Increase'"
            class="justify flex gap-1">
            <AppButton
                v-if="!request.completed_at && request.assignee_id === page.props.auth.user.id"
                id="approve-credit-request-button"
                class="p-2"
                permission="update_customer_credit_request"
                :disabled="approvalForm.isOpen"
                @click="approveCreditRequest">
                {{ $t('Approve') }}
            </AppButton>
            <AppButton
                v-if="!request.completed_at && request.assignee_id === page.props.auth.user.id"
                id="deny-credit-request-button"
                class="p-2"
                permission="update_customer_credit_request"
                :disabled="denialForm.isOpen"
                @click="denyCreditRequest">
                {{ $t('Deny') }}
            </AppButton>
        </div>
        <div
            v-if="request.type === 'Add Customer to Group'"
            class="justify flex gap-1">
            <AppButton
                v-if="!request.completed_at && request.assignee_id === page.props.auth.user.id"
                id="approve-group-request-button"
                class="p-2"
                :disabled="approvalForm.isOpen"
                @click="approveGroupRequest">
                {{ $t('Approve') }}
            </AppButton>
            <AppButton
                v-if="!request.completed_at && request.assignee_id === page.props.auth.user.id"
                id="deny-group-request-button"
                class="p-2"
                :disabled="denialForm.isOpen"
                @click="denyGroupRequest">
                {{ $t('Deny') }}
            </AppButton>
        </div>
        <div
            v-if="
                request.type === 'Customer Activation' ||
                request.type === 'Customer Contract' ||
                request.type === 'Customer Insurance'
            ">
            <AppButton
                v-if="!request.completed_at && request.assignee_id === page.props.auth.user.id"
                id="add-customer-button"
                icon="far fa-circle-plus"
                :disabled="form.processing"
                permission="update_customer_activation_request"
                @click="completeRequest">
                {{ $t('Complete Request') }}
            </AppButton>
        </div>
        <div v-if="request.type === 'Customer Activation'">
            <form id="toggle-active-inactive-form">
                <AppToggle
                    v-model="form.is_active"
                    :label="['Active', 'Inactive']"
                    permission="toggle_customer"
                    class="font-bold uppercase text-iel-blue"
                    @click="toggleIsActive"
                    @change="submit" />
            </form>
        </div>
        <div v-if="request.entity.customer_group">
            <span class="mr-1 font-bold">{{ $t('Group') }}:</span>
            <a
                :href="route('customers.groups.show', request.entity.customer_group.id)"
                class="cursor-pointer underline"
                target="_blank">
                {{ request.entity.customer_group.name }}
            </a>
        </div>
        <div v-else-if="request.prospective_group_name">
            <span class="mr-1 font-bold">{{ $t('Group') }}:</span>
            <a
                :href="route('customers.groups.show', request.details.customer_group_id)"
                class="cursor-pointer underline"
                target="_blank">
                {{ request.prospective_group_name }}
            </a>
        </div>
        <div>
            <span class="mr-1 font-bold">{{ $t('Broker') }}:</span>
            <span>{{ request.broker?.name }}</span>
        </div>
        <div v-if="request.broker">
            <span class="mr-1 font-bold">{{ $t('Manager') }}:</span>
            <span v-if="request.broker.manager_name">{{ request.broker.manager_name }}</span>
            <span v-else>--</span>
        </div>
        <div
            v-if="request.broker"
            class="col-span-2">
            <span class="mr-1 font-bold">{{ $t('Location') }}:</span>
            <span v-if="request.broker.office_location">{{ request.broker.office_location }}</span>
            <span v-else>--</span>
        </div>
    </div>
</template>

<script setup>
import { useForm, usePage, router } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import { approvalForm, denialForm } from '@/stores';

const { t } = useI18n();
const page = usePage();

const props = defineProps({
    request: Object
});

const form = useForm({
    is_active: props.request.entity.is_active
});

provide('form', form);

const emit = defineEmits(['success']);

function toggleIsActive(event) {
    if (!confirm(t("Are you sure you want to change this customer's status?"))) event.preventDefault();
}

function completeRequest() {
    if (confirm(t('Are you sure you want to mark this request as completed?'))) {
        router.patch(
            route('tickets.update', props.request.id),
            {
                completed_at: new Date()
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    emit('success');
                }
            }
        );
    }
}

const increase_amount_usd = parseFloat(props.request.details.increase_amount ?? 0);
const available_credit_usd = parseFloat((props.request.entity.available_credit?.amount / 100).toFixed(2));

const approveCreditRequest = () => {
    approvalForm.value.setAmount((increase_amount_usd + available_credit_usd).toFixed(2));
    approvalForm.value.setIsOpen(true);
    denialForm.value.setIsOpen(false);
};

const denyCreditRequest = () => {
    approvalForm.value.setIsOpen(false);
    denialForm.value.setIsOpen(true);
};

const approveGroupRequest = () => {
    // set group_id for the customer to props.request.details.group_id
    router.patch(
        route('customers.update', props.request.entity.id),
        {
            customer_group_id: props.request.details.customer_group_id,
            group_reason: props.request.details.group_reason,
            billing_contact_name: props.request.details.billing_contact_name,
            billing_contact_emails: props.request.details.billing_contact_emails,
            ticket_id: props.request.id
        },
        {
            preserveScroll: true,
            onSuccess: () => {
                emit('success');
            }
        }
    );
};

const denyGroupRequest = () => {
    router.patch(
        route('tickets.update', props.request.id),
        {
            completed_at: new Date()
        },
        {
            preserveScroll: true,
            onSuccess: () => {
                emit('success');
            }
        }
    );
};

function submit() {
    form.patch(route('customers.status.update', props.request.entity.id));
}
</script>
