<template>
    <div class="grid gap-2">
        <div class="grid grid-cols-2 gap-2">
            <AppInput
                v-model="flag.all_risk.commodity"
                :readonly="true"
                :disabled="true"
                name="commodity" />
            <AppInput
                v-model="flag.all_risk.transportation_mode"
                :readonly="true"
                :disabled="true"
                name="transportation_mode" />
            <AppInput
                v-model="flag.all_risk.equipment_type"
                :readonly="true"
                :disabled="true"
                name="equipment_type" />
            <AppInput
                v-model="flag.all_risk.amount"
                :readonly="true"
                :disabled="true"
                name="load_value" />
        </div>
        <div class="grid grid-cols-2 gap-2">
            <div class="space-y-4">
                <div>
                    <AppAutocomplete
                        :model-value="assignee?.id"
                        name="user_id"
                        permission="update_all_risk_record"
                        :label="$t('Assignee')"
                        :options-url="route('users.autocomplete')"
                        :placeholder="$t('Search for an assignee...')"
                        :initial-options="
                            assignee?.id
                                ? [
                                      {
                                          value: assignee.id,
                                          display: assignee.name
                                      }
                                  ]
                                : []
                        "
                        :url="url" />
                    <div
                        v-if="!assignee?.id"
                        class="pt-2">
                        <AppButton
                            v-if="!flag.all_risk.resolved_at"
                            :id="`report-${flag.id}-assign-button`"
                            variant="primary"
                            size="sm"
                            class="cursor-pointer"
                            permission="update_all_risk_record"
                            @click="$emit('assign-to-me')">
                            {{ $t('Assign To Me') }}
                        </AppButton>
                    </div>
                </div>
                <AppSelect
                    id="resolution"
                    name="resolution"
                    permission="update_all_risk_record"
                    :model-value="flag.all_risk?.resolution"
                    :url="url"
                    :options="{ approved: 'Approve', denied: 'Deny' }" />
            </div>
        </div>
    </div>
</template>

<script setup>
defineEmits(['assign-to-me']);

const props = defineProps({
    flag: Object,
    assignee: Object
});

const url = route('loads.all-risk.update', [props.flag.id, props.flag?.all_risk?.id]);
</script>
