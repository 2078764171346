<template>
    <AppCard
        :title="$t('Remit Info')"
        theme="white"
        content-class="p-2 @container"
        class="grid">
        <div class="grid gap-4">
            <AppInput
                id="remit_address_name"
                v-model="form.remit_address_name"
                :url="remitUrl"
                name="remit_address_name"
                permission="update_remit_info"
                :label="$t('Name')" />
            <CarrierRemitAddressCard />
        </div>
        <div class="mt-6">
            <div class="flex justify-start gap-6">
                <div>
                    <AppHeading
                        size="sm"
                        class="pb-2 text-iel-gray">
                        {{ $t('Driver ComCheck') }}
                    </AppHeading>
                    <AppCheckbox
                        id="driver-comcheck-accessorials"
                        v-model="form.has_driver_accessorials"
                        permission="update_comcheck_info"
                        :url="comcheckUrl"
                        name="has_driver_accessorials"
                        :label="$t('Accessorials')" />
                    <AppCheckbox
                        id="driver-comcheck-fuel-advance"
                        v-model="form.has_driver_fuel_advance"
                        permission="update_comcheck_info"
                        :url="comcheckUrl"
                        name="has_driver_fuel_advance"
                        :label="$t('Fuel Advance')" />
                </div>
                <div
                    class="via-neutral-500 dark:via-neutral-400 min-h-[1em] w-px self-stretch bg-gradient-to-tr from-transparent to-transparent opacity-25" />
                <div>
                    <AppHeading
                        size="sm"
                        class="pb-2 text-iel-gray">
                        {{ $t('Dispatcher ComCheck') }}
                    </AppHeading>
                    <AppCheckbox
                        id="dispatcher-comcheck-accessorials"
                        v-model="form.has_dispatcher_accessorials"
                        permission="update_comcheck_info"
                        :url="comcheckUrl"
                        name="has_dispatcher_accessorials"
                        :label="$t('Accessorials')" />
                    <AppCheckbox
                        id="dispatcher-comcheck-fuel-advance"
                        v-model="form.has_dispatcher_fuel_advance"
                        permission="update_comcheck_info"
                        :url="comcheckUrl"
                        name="has_dispatcher_fuel_advance"
                        :label="$t('Fuel Advance')" />
                </div>
            </div>
        </div>
    </AppCard>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';

const page = usePage();

const remitUrl = route('carriers.remit.update', [page.props.carrier.id]);
const comcheckUrl = route('carriers.comcheck.update', [page.props.carrier.id]);

const form = useForm({
    remit_address_name: page.props.carrier.remit_address_name,
    has_dispatcher_accessorials: page.props.carrier.has_dispatcher_accessorials,
    has_dispatcher_fuel_advance: page.props.carrier.has_dispatcher_fuel_advance,
    has_driver_accessorials: page.props.carrier.has_driver_accessorials,
    has_driver_fuel_advance: page.props.carrier.has_driver_fuel_advance
});

provide('form', form);
</script>
