<template>
    <section :id="getId('load-*-stops-*-box-schedule', [load, stop])">
        <div class="mb-2 grid grid-cols-12 gap-2">
            <AppRadioGroup
                name="has_stop_window"
                label="Type"
                inline
                :scope="stop.id"
                :model-value="stop.has_stop_window"
                :url="url"
                permission="update_stops"
                :disabled="load.is_canceled_or_tonu || !load.can.view_load_details"
                :confirmation="confirmation"
                :options="[
                    { value: true, label: 'FCFS' },
                    { value: false, label: 'Appt' }
                ]"
                class="col-span-2" />
            <AppDateTime
                v-model:datetime="stop.stop_window_begins_at"
                v-model:timezone="stop.stop_window_begins_at_timezone"
                permission="update_stops"
                name="stop_window_begins_at"
                :scope="stop.id"
                :label="`Stop Plan Arrival ${stop.has_stop_window ? 'Window Start' : ''}`"
                :field-names="{ datetime: 'stop_window_begins_at', timezone: 'stop_window_begins_at_timezone' }"
                :url="url"
                :disabled="load.is_canceled_or_tonu || !load.can.view_load_details"
                :confirmation="confirmation"
                class="col-span-4" />
            <template v-if="stop.has_stop_window">
                <AppDateTime
                    v-model:datetime="stop.stop_window_ends_at"
                    v-model:timezone="stop.stop_window_ends_at_timezone"
                    permission="update_stops"
                    name="stop_window_ends_at"
                    :scope="stop.id"
                    :label="`Stop Plan Arrival ${stop.has_stop_window ? 'Window End' : ''}`"
                    :field-names="{ datetime: 'stop_window_ends_at', timezone: 'stop_window_ends_at_timezone' }"
                    :url="url"
                    :disabled="load.is_canceled_or_tonu || !load.can.view_load_details"
                    :confirmation="confirmation"
                    class="col-span-4" />
            </template>
            <AppInput
                v-model="stop.appointment_number"
                permission="update_stops"
                name="appointment_number"
                :scope="stop.id"
                :url="url"
                :disabled="load.is_canceled_or_tonu || !load.can.view_load_details"
                label="Appointment #"
                class="col-span-2" />
        </div>
        <div class="grid grid-cols-12 items-center gap-2">
            <AppTextarea
                :model-value="stop.note?.content"
                permission="update_stops"
                :scope="stop.id"
                label="Notes"
                name="note"
                :url="url"
                :disabled="load.is_canceled_or_tonu || !load.can.view_load_details"
                class="col-span-4 resize-none"
                rows="4" />
            <div class="col-span-2 mx-auto">
                <AppLabel
                    label="Third Party Scheduler:"
                    class="mb-2" />
                <a
                    v-if="stop.location.shippingDetails.third_party_scheduler_url"
                    :href="stop.location.shippingDetails.third_party_scheduler_url"
                    class="underline">
                    {{ stop.location.shippingDetails.third_party_scheduler_url }}
                </a>
                <div v-else>-</div>
            </div>
            <div class="col-span-6 max-h-44 w-full overflow-auto bg-iel-light-gray p-2">
                <table class="relative w-full whitespace-nowrap">
                    <thead>
                        <tr class="border-b border-iel-gray text-left text-iel-gray">
                            <th class="pb-2">{{ $t('Name') }}</th>
                            <th class="pb-2">{{ $t('Phone') }}</th>
                            <th class="pb-2">{{ $t('Email') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="contact in contacts"
                            :key="contact.id">
                            <td class="pt-2">{{ contact?.name }}</td>
                            <td class="pt-2">
                                <a :href="`tel:${contact?.phone_number}`">
                                    {{ contact?.phone_number }}
                                </a>
                            </td>
                            <td class="pt-2">
                                <a :href="`email:${contact?.email_address}`">
                                    {{ contact?.email_address }}
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { getId } from '@/helpers';

const { load, stop } = defineProps({
    load: Object,
    stop: Object
});

const form = useForm({
    stop_window_begins_at: '',
    stop_window_begins_at_timezone: '',
    stop_window_ends_at: '',
    stop_window_ends_at_timezone: ''
});

provide('form', form);

const url = route('stops.update', stop);

const contacts = computed(() =>
    stop.type === 'pickup' ? stop.location.shippingContacts : stop.location.receivingContacts
);

const ratecon = useRateCon(toRef(() => load));
const confirmation = ratecon.confirmChange;
</script>
