<template>
    <div>
        <AppHeading
            :id="getId('customer-*-invoice-details-heading', [load.customer])"
            size="xl"
            class="mb-4">
            {{ $t('Invoice Details') }}
        </AppHeading>
        <!-- billing table -->
        <div
            :id="getId('customer-*-invoice-details-billing-table', [load.customer])"
            class="grid grid-cols-4 gap-4">
            <div class="font-bold">{{ $t('Billing Contact') }}</div>
            <div class="font-bold">{{ $t('Billing Method') }}</div>
            <div class="font-bold">{{ $t('Billing Emails') }}</div>
            <div class="font-bold">{{ $t('Billing Notes') }}</div>

            <div>{{ load.customer.billing_contact_name || ' - ' }}</div>
            <div>{{ load.customer.billing_method || ' - ' }}</div>
            <div>{{ load.customer.billing_contact_emails || ' - ' }}</div>
            <div>{{ load.customer.billing_notes || ' - ' }}</div>
        </div>
        <!-- invoice paid table -->
        <div
            :id="getId('customer-*-invoice-details-paid-table', [load.customer])"
            class="mt-4 grid grid-cols-4 gap-4">
            <div class="font-bold">{{ $t('Invoice Date') }}</div>
            <div class="font-bold">{{ $t('Invoice Paid Date') }}</div>
            <div class="font-bold">{{ $t('Invoice Paid Amount') }}</div>
            <div class="font-bold">{{ $t('Invoice Due Amount') }}</div>

            <div class="text-sm">{{ formatDate(load.billingDetail?.invoice_happened_at) || ' - ' }}</div>
            <div class="text-sm">{{ formatDate(load.billingDetail?.paid_happened_at) || ' - ' }}</div>
            <div class="text-sm">{{ load.billingDetail?.paid_amount || ' - ' }}</div>
            <div class="text-sm">{{ load.billingDetail?.amount_due || ' - ' }}</div>
        </div>
    </div>
</template>

<script setup>
import { getId } from '@/helpers.js';
import { formatDate } from '@/helpers.js';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});
</script>
