<template>
    <div class="flex items-center">
        <div
            v-for="tab in tabs"
            :key="tab"
            class="text-md cursor-pointer rounded-md rounded-b-none border-iel-light-gray px-2 py-1"
            :class="[
                activeTab === tab ? 'border-l border-r border-t  font-bold text-iel-blue' : 'border-b text-iel-gray'
            ]"
            @click="activeTab = tab">
            {{ tab }}
        </div>
    </div>
    <LoadCardText
        ref="loadText"
        :load="load"
        :active-tab="activeTab"
        class="max-w-screen" />
</template>

<script setup>
const { load } = defineProps({
    load: Object
});

defineEmits(['success']);

const tabs = ref([]);
const activeTab = ref();
const loadText = ref();

function activateTab(targetTab) {
    let tab = tabs.value.filter(tab => tab == targetTab)[0];
    if (!tab || tab == undefined) {
        return;
    }
    activeTab.value = targetTab;
    loadText.value.showingCreateMessageForm = true;
}

function setTabs() {
    if (load.dispatcher?.phone_number) {
        tabs.value.push('Dispatcher');
        activeTab.value = 'Dispatcher';
    }

    if (load.driver?.phone_number) {
        tabs.value.push('Driver');
        if (!activeTab.value) {
            activeTab.value = 'Driver';
        }
    }

    if (load.driver2?.phone_number) {
        tabs.value.push('Driver 2');
    }
}

defineExpose({ activateTab });

onMounted(() => {
    setTabs();
});
</script>
