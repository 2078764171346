<template>
    <div
        class="space-y-1"
        data-test="wrapper">
        <span
            v-if="label"
            class="block text-sm text-iel-dark-gray"
            data-test="label">
            {{ label }}
        </span>
        <Component
            :is="href ? 'Link' : 'div'"
            :href="href || undefined"
            class="block w-full py-1 font-bold sm:text-sm"
            :class="[
                {
                    'text-iel-gray': !value,
                    'text-iel-blue underline hover:text-iel-blue/75': href
                },
                error ? 'border-iel-light-red' : 'border-iel-silver'
            ]">
            {{ value || '–' }}
        </Component>
        <AppErrorMessage
            :name="label"
            :error="error" />
    </div>
</template>

<script setup>
defineProps({
    value: [String, Number],
    href: String,
    label: String,
    error: [String, Boolean]
});
</script>
