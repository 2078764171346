<template>
    <AppTablePill
        :columns="[
            { name: 'id', label: $t('ID'), sortable: true },
            { name: 'name', label: $t('Name'), sortable: true },
            { name: 'roles', label: $t('Roles Assigned') },
            { name: 'actions', label: '' }
        ]"
        :classes="{ row: 'align-top' }"
        :data="users.data"
        data-key="users">
        <template #name-cell="{ row }">
            <div class="font-bold text-iel-blue">{{ row.name }}</div>
        </template>
        <template #roles-cell="{ row }">
            <div class="font-bold italic text-iel-gray/80">
                {{ row.roles.length > 0 ? row.roles.map(r => r.name).join(', ') : 'No roles assigned.' }}
            </div>
        </template>
        <template #actions-cell="{ row }">
            <AppButton
                :id="`view-user-${row.id}`"
                :href="route('users.show', row.id)">
                {{ $t('View/Edit') }}
            </AppButton>
        </template>
    </AppTablePill>
    <AppPagination :meta="users.meta" />
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import UserLayout from '@/Layouts/UserLayout.vue';

defineOptions({
    layout: [AuthenticatedLayout, UserLayout]
});

defineProps({
    users: Object
});
</script>
