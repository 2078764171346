<template>
    <div
        :id="getId('load-*-summary', [load])"
        class="flex gap-4">
        <div class="flex flex-1 flex-col gap-4">
            <LoadStopsList
                :load="load"
                :display-columns="['type', 'location', 'time', 'appt', 'po', 'miles']"
                header-nav="TOTAL_MILES"
                :is-sortable="false"
                :has-delete="false"
                :hides-rows="true" />
            <LoadSummaryDetails :load="load" />
            <div
                v-if="load.claim || load.carrier_incident"
                id="load-flags-detail-section">
                <div class="grid grid-cols-2 gap-2 p-2">
                    <AppCard
                        v-if="load.claim"
                        title="Claims Flag"
                        theme="blue">
                        <LoadClaimsFlag />
                    </AppCard>
                    <div v-else />
                    <AppCard
                        v-if="load.carrier_incident"
                        title="Carrier Relations Flag"
                        theme="blue">
                        <LoadCarrierRelationsFlag />
                    </AppCard>
                    <div v-else />
                </div>
            </div>
        </div>
        <LoadSummaryFinances :load="load" />
    </div>
</template>

<script setup>
import { getId } from '@/helpers';

defineProps({
    load: Object
});
</script>
