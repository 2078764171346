<template>
    <!-- App -->
    <AppErrorNotification />
    <AppNoteCreateModal />

    <!-- Carrier -->
    <CarrierContactModal />
    <CarrierCreateModal />
    <CarrierTicketModal />
    <CarrierVehicleCreateModal />
    <CarrierVehicleEditModal />

    <!-- Customer -->
    <CustomerActivationModal />
    <CustomerContactModal />
    <CustomerContractRequestModal />
    <CustomerCreateModal />
    <CustomerCreditRequestModal />
    <CustomerEDISetupRequestModal />
    <CustomerGroupAddCustomerModal />
    <CustomerGroupCreateModal />
    <CustomerInsuranceRequestModal />
    <CustomerPartnerVendorModal />

    <!-- Load -->
    <LoadCarrierAdvanceTermsModal />
    <LoadCarrierBookModal />
    <LoadCheckcallModal />
    <LoadComcheckModal />
    <LoadComcheckTransferModal />
    <LoadCopyModal />
    <LoadCreateModal />
    <LoadCustomerAddModal />
    <LoadDispatchSheetSendModal />
    <LoadFlagCreateModal />
    <LoadMacropointModal />
    <LoadRateConSendModal />
    <LoadSnoozeModal />
    <LoadStopCreateModal />
    <LoadTaskModal />
    <LoadTonuModal />

    <!-- Location -->
    <LocationContactModal />
    <LocationCreateModal />

    <!-- Quote -->
    <QuoteBidCreateModal />
    <QuoteCreateModal />
    <QuoteStopCreateModal />

    <!-- Report -->
    <ReportModal />

    <!-- Role -->
    <RoleUsersAddModal />
    <RoleModal />

    <RejectTenderModal />
</template>
