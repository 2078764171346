<template>
    <div>
        <div class="flex items-center gap-x-2">
            <Link
                v-for="option in layoutOptions"
                :id="`loadboard-${option.layout}-layout-toggle`"
                :key="option.layout"
                :href="route('loads.index', route().params)"
                class="rounded p-0.5"
                :class="loadboard.layout === option.layout ? 'bg-iel-light-gray' : 'hover:bg-iel-silver'"
                :aria-label="`view ${option.layout} layout`"
                @click="setLayout(option.layout)">
                <AppIcon
                    :name="option.icon"
                    :class="option.iconClass" />
            </Link>
            <Link
                id="loadboard-list-layout-toggle"
                :href="route('loads.list', route().params)"
                class="rounded p-0.5"
                :class="loadboard.layout === 'list' ? 'bg-iel-light-gray' : 'hover:bg-iel-silver'"
                @click="setLayout('list')">
                <AppIcon
                    name="far fa-bars"
                    class="h-5 w-5" />
            </Link>
        </div>
    </div>
</template>

<script setup>
import { loadboard } from '@/stores';

const route = inject('route');
const layoutOptions = computed(() => [
    {
        layout: 'regular',
        icon: 'far fa-bars',
        iconClass: 'rotate-90 h-5 w-5'
    },
    {
        layout: 'mini',
        icon: 'far fa-grid',
        iconClass: 'h-5 w-5'
    }
]);

function setLayout(layout) {
    loadboard.value.layout = layout;
}
</script>
