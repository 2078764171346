<template>
    <AppCard
        title="Load History"
        theme="light_gray"
        class="span-all">
        <div class="mb-2 flex flex-col gap-4 pt-2 xl:flex-row xl:justify-between">
            <div class="grid grid-cols-2 gap-2">
                <AppButton
                    icon="fal fa-file-arrow-down"
                    @click="tabulator.download('xlsx', 'data.xlsx')">
                    XLSX
                </AppButton>
                <AppButton
                    icon="fal fa-file-arrow-down"
                    @click="downloadPDF">
                    PDF
                </AppButton>
            </div>
            <div class="grid gap-2 text-iel-gray md:items-center">
                <div>
                    <AppSearchInput
                        v-model="filter"
                        name="filter-records"
                        class="xl:ml-6"
                        placeholder="Search All Load History" />
                </div>
            </div>
        </div>
        <div ref="table" />
    </AppCard>
    <div class="sr-only">
        <h2>Load History</h2>
        <ul>
            <li
                v-for="(record, index) in records"
                :key="index">
                <p>Load Date: {{ record.created_at }}</p>
                <p>PO Number: {{ record.load_po }}</p>
                <p>Customer PO: {{ record.customer_po }}</p>
                <p>Lane: {{ record.lane }}</p>
                <p>Amount: {{ record.amount }}</p>
                <p>Carrier: {{ record.carrier_names }}</p>
                <p>Pay Truck: {{ record.pay_truck }}</p>
                <p>Margin: {{ record.profit_margin }}</p>
                <p>Profit: {{ record.profit }}</p>
                <p>Delivery Date: {{ record.delivered_at }}</p>
                <p>Sales Person: {{ record.owned_by_name }}</p>
                <p>On Time: {{ record.eta_status }}</p>
                <p>Claim: {{ record.has_claim }}</p>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { watchDebounced } from '@vueuse/core';
import { formatDate } from '@/helpers.js';

const page = usePage();
const customerId = page.props.customer.id;

const table = ref(); // The reference to the table element
let tabulator = null; // The variable to hold the table
const filter = ref();
const records = ref([]);

function moneyFormatter(value) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

function downloadPDF() {
    tabulator.download('pdf', 'data.pdf', {
        title: 'Payment History for ' + page.props.customer.name,
        autoTable: {
            columnStyles: {
                3: { halign: 'right' },
                4: { halign: 'right' },
                5: { halign: 'right' },
                6: { halign: 'right' },
                7: { halign: 'right' }
            }
        }
    });
}

watchDebounced(
    filter,
    value => {
        if (!value) return tabulator.clearFilter();
        tabulator.setFilter('load_po', '=', value);
    },
    { debounce: 1000 }
);

onMounted(() => {
    tabulator = new Tabulator(table.value, {
        ajaxURL: route('customers.loadHistory', customerId),
        ajaxURLGenerator: function (url, config, params) {
            const searchParams = new URLSearchParams({ page: params.page, size: params.size });

            if (params?.sort?.length) {
                searchParams.append('sort_field', params.sort.at(0).field);
                searchParams.append('sort_dir', params.sort.at(0).dir);
            }

            if (params?.filter?.length) {
                searchParams.append('filter', params.filter.at(0).value);
            }

            const queryString = searchParams.toString();
            return `${url}?${queryString}`;
        },
        ajaxResponse: function (url, params, response) {
            records.value = response.data?.map(record => ({
                ...record,
                created_at: record.created_at ? formatDate(record.created_at) : null,
                delivered_at: record.delivered_at ? formatDate(record.delivered_at) : null
            }));

            return { ...response, data: records.value };
        },
        pagination: true,
        paginationMode: 'remote',
        paginationSize: 50,
        paginationSizeSelector: [5, 10, 25, 50],
        maxHeight: 600, // Set the height of the table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
        paginationCounter: 'pages',
        locale: true,
        langs: {
            en: {
                pagination: {
                    page_size: 'rows per page',
                    first: 'First',
                    first_title: 'First Page',
                    last: 'Last',
                    prev: '<<',
                    next: '>>',
                    next_title: 'Next Page'
                }
            }
        },
        sortMode: 'remote',
        layout: 'fitDataStretch',
        filterMode: 'remote',
        columns: [
            { title: 'Load Date', field: 'created_at' },
            { title: 'PO Number', field: 'load_po' },
            { title: 'Customer PO', field: 'customer_po' },
            { title: 'Lane', field: 'lane' },
            {
                title: 'Amount',
                field: 'amount',
                formatter: 'money',
                formatterParams: {
                    symbol: '$'
                },
                hozAlign: 'right',
                accessorDownload: moneyFormatter
            },
            { title: 'Carrier', field: 'carrier_names' },
            {
                title: 'Pay Truck',
                field: 'pay_truck',
                formatter: 'money',
                formatterParams: {
                    symbol: '$'
                },
                hozAlign: 'right',
                accessorDownload: moneyFormatter
            },
            {
                title: 'Margin',
                field: 'profit_margin',
                formatter: cell => cell.getValue() + '%',
                hozAlign: 'right'
            },
            {
                title: 'Profit',
                field: 'profit',
                formatter: 'money',
                formatterParams: {
                    symbol: '$'
                },
                hozAlign: 'right',
                accessorDownload: moneyFormatter
            },
            { title: 'Delivery Date', field: 'delivered_at' },

            { title: 'Sales Person', field: 'owned_by_name' },
            { title: 'On Time', field: 'eta_status' },
            { title: 'Claim', field: 'has_claim' }
        ]
    });
});
</script>

<style>
.span-all {
    column-span: all;
}

/* Table Elements */
.tabulator {
    @apply !rounded-md !bg-iel-light-gray;
}

.tabulator-header {
    @apply !pt-4 !text-left;
}

.tabulator-header .tabulator-col {
    @apply !mr-2;
}

.tabulator-header,
.tabulator-col,
.tabulator-row {
    @apply !border-r-0 !bg-iel-light-gray !px-1;
}

.tabulator-cell {
    @apply !mr-2 !border-r-0 !text-left;
}

.tabulator-row {
    @apply !border-b !border-iel-light-gray !p-2;
}

.tabulator-col-title {
    @apply !text-iel-gray;
}

.tabulator-footer {
    @apply !border-t-0 !bg-white !text-black;
}

.tabulator .tabulator-footer .tabulator-footer-contents {
    @apply !flex-col;
}

/* Pagination */
.tabulator-paginator {
    @apply !flex w-full !flex-initial !flex-wrap items-center !px-4 !py-2 !text-xs !text-black;
}

.tabulator .tabulator-footer .tabulator-page {
    @apply !mx-0 !rounded-none !border-0 !border-b !border-l !border-t !bg-white !px-4 !py-2 !text-xs !font-normal !text-black;
}

.tabulator .tabulator-footer .tabulator-page:last-of-type {
    @apply !border-r;
}

.tabulator .tabulator-footer .tabulator-pages {
    @apply !mx-0;
}

.tabulator .tabulator-footer .tabulator-page:disabled {
    @apply !font-normal !text-iel-gray !opacity-100;
}

.tabulator .tabulator-footer .tabulator-page.active {
    @apply !border-r-0 !bg-iel-light-gray;
}

.tabulator .tabulator-footer .tabulator-page-size {
    @apply !order-5 !ml-auto !cursor-pointer !rounded-none !border-none !bg-white !py-2 !pl-2 !pr-8 !text-sm font-bold !underline;
}

.tabulator-paginator label {
    @apply !order-last !text-sm !font-bold;
}

/* Screen Queries */

@media (min-width: 1536px) {
    .tabulator-tableholder {
        @apply !overflow-x-hidden;
    }

    .tabulator-col,
    .tabulator-cell {
        @apply !min-w-7;
    }
}

@media (min-width: 1669px) {
    .tabulator-col,
    .tabulator-cell {
        @apply mr-4 !min-w-24;
    }
}

@media (min-width: 1885px) {
    .tabulator-col,
    .tabulator-cell {
        @apply !min-w-36;
    }
}

@screen md {
    .tabulator .tabulator-footer .tabulator-footer-contents {
        @apply !flex-row-reverse;
    }
}

@media (min-width: 1536px) {
    .tabulator-tableholder {
        @apply !overflow-x-hidden;
    }

    .tabulator-col,
    .tabulator-cell {
        @apply !min-w-7;
    }
}

@media (min-width: 1669px) {
    .tabulator-col,
    .tabulator-cell {
        @apply mr-4 !min-w-24;
    }
}

@media (min-width: 1885px) {
    .tabulator-col,
    .tabulator-cell {
        @apply !min-w-36;
    }
}
</style>
