<template>
    <div>
        <ul
            v-if="load.billingErrors.length > 0"
            role="list"
            class="px-1">
            <li
                v-for="billingError in load.billingErrors"
                :id="`billingError-${billingError.id}`"
                :key="billingError.id"
                class="mb-1">
                <div class="grid grid-cols-5 gap-2 py-1">
                    <div class="col-span-5 w-full">
                        <div
                            class="rounded-t-md bg-iel-blue p-1 text-xs text-white md:flex md:items-center md:justify-between xl:px-2">
                            <p class="pr-2">{{ billingError.short_name }}</p>
                            <time
                                class="whitespace-nowrap"
                                :datetime="billingError.created_at">
                                {{ $t(formatDate(billingError.created_at)) }}
                            </time>
                        </div>
                        <div
                            class="max-h-20 w-auto overflow-y-auto rounded-b-md border border-iel-blue/30 bg-white p-1"
                            tabindex="0">
                            <p>{{ $t(billingError.body) }}</p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div
            v-else
            class="mb-4">
            {{ $t('No Billing Errors found on this load.') }}
        </div>
    </div>
</template>

<script setup>
import { formatDate } from '@/helpers.js';

const { load } = defineProps({
    load: Object
});
</script>
