<template>
    <div class="flex items-center gap-2">
        <div class="circular-progress">
            <svg
                width="29"
                height="29">
                <!-- Background Circle -->
                <circle
                    class="circle-bg"
                    cx="14.5"
                    cy="14.5"
                    r="11"></circle>

                <!-- Progress Circle -->
                <circle
                    class="circle-progress stroke-iel-light-green"
                    :style="{ strokeDashoffset: strokeDashOffset }"
                    cx="14.5"
                    cy="14.5"
                    r="11"></circle>

                <!-- Center Dot -->
                <circle
                    cx="14.5"
                    cy="14.5"
                    r="2.5"
                    class="fill-iel-light-green"></circle>
            </svg>
        </div>

        <div class="text-xs font-semibold">
            <p
                v-if="currentStopStatus.statusText !== 'Trip Completed'"
                class="text-white">
                Stop {{ currentStopStatus.currentStopIndex + 1 }}
            </p>
            <p class="text-iel-light-green">{{ currentStopStatus.statusText }}</p>
        </div>
    </div>
</template>

<script setup>
const { stops } = defineProps({
    stops: Array
});

const numberOfStops = computed(() => stops.length);

const circleRadius = 11;
const circumference = 2 * Math.PI * circleRadius;

const segmentSize = computed(() => circumference / numberOfStops.value);

const progress = computed(() => stops.filter(stop => stop.departed_at).length);

const strokeDashOffset = computed(() => {
    return (numberOfStops.value - progress.value) * segmentSize.value;
});

const currentStopStatus = computed(() => {
    const allDeparted = stops.every(stop => stop.departed_at);

    if (allDeparted) {
        return { currentStopIndex: -1, statusText: 'Trip Completed' };
    }

    const currentStopIndex = stops.findIndex(stop => !stop.departed_at);

    let statusText = 'En Route';
    if (currentStopIndex !== -1) {
        const currentStop = stops[currentStopIndex];

        if (currentStop.arrived_at && !currentStop.completed_at && !currentStop.departed_at) {
            statusText = 'Arrived';
        } else if (currentStop.arrived_at && currentStop.completed_at && !currentStop.departed_at) {
            statusText = 'Completed';
        }
    }

    return { currentStopIndex, statusText };
});
</script>

<style scoped>
.circular-progress {
    position: relative;
    width: 29px;
    height: 29px;
}

.circle-bg {
    stroke: #e5e7eb;
    fill: none;
    stroke-width: 2.5;
}

.circle-progress {
    fill: none;
    stroke-width: 2.5;
    stroke-dasharray: 69;
    transform: rotate(-90deg);
    transform-origin: center;
    transition: stroke-dashoffset 0.3s ease;
}
</style>
