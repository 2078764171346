<template>
    <div
        :id="getId('load-*-progress-bar', [load])"
        class="relative">
        <div
            class="h-2.5 overflow-hidden rounded-sm bg-iel-light-gray"
            :style="{
                '--progress': `${progress}%`
            }">
            <div class="h-full w-[var(--progress)] bg-iel-blue"></div>
        </div>
        <div class="absolute inset-0 flex items-center">
            <template
                v-for="(stop, index) in stops"
                :key="index">
                <svg
                    v-if="stop.type === 'pickup'"
                    :style="{ '--progress': `${stop.progress}%` }"
                    class="absolute left-[var(--progress)] -translate-x-1/2 fill-white stroke-iel-gray"
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg">
                    <polygon
                        points="10,4 16,16 4,16"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
                <svg
                    v-else-if="stop.type === 'dropoff'"
                    :style="{ '--progress': `${stop.progress}%` }"
                    class="absolute left-[var(--progress)] -translate-x-1/2 fill-white stroke-iel-gray"
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg">
                    <polygon
                        points="4,4 16,4 10,16"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
                <svg
                    v-else
                    :style="{ '--progress': `${stop.progress}%` }"
                    class="absolute left-[var(--progress)] -translate-x-1/2 fill-white stroke-iel-gray"
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg">
                    <polygon
                        points="10,3 16,10 10,17 4,10"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </template>
        </div>
    </div>
</template>

<script setup>
import { isNil } from 'lodash-es';
import { getId } from '@/helpers';

const { load } = defineProps({
    load: Object
});

const progress = computed(() => {
    if (isNil(load.miles_completed) || isNil(load.trip_miles) || load.trip_miles === 0) {
        return 0;
    }
    return (load.miles_completed / load.trip_miles) * 100;
});

const stops = computed(() =>
    load.stops
        .map((stop, index) => {
            const progress = load.stops
                .slice(0, index)
                .reduce((total, s) => total + (s.miles_to_next_stop / load.trip_miles) * 100, 0);

            const nextStop = load.stops[index + 1];
            const type =
                load.stops.length > 2 &&
                nextStop &&
                stop.location_id === nextStop.location_id &&
                stop.type !== nextStop.type
                    ? 'both'
                    : stop.type;

            return { ...stop, type, progress };
        })
        .filter((stop, index, stops) => stops[index - 1]?.type !== 'both')
);
</script>
