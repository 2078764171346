<template>
    <div v-if="load.macropointOrder && load.macropointOrder.status.message === 'Tracking Completed Successfully'">
        <p
            :id="`load-card-${load.id}-actions-tracking-completed`"
            class="block w-full cursor-not-allowed px-4 py-2 text-left text-sm leading-5 text-iel-blue"
            disabled>
            {{ $t('Tracking Completed') }}
        </p>
    </div>
    <div
        v-else-if="
            load.macropointOrder &&
            load.macropointOrder.status.message !== 'Stopped By Creator' &&
            load.macropointOrder.status.message !== 'Unknown'
        ">
        <button
            :id="`load-card-${load.id}-actions-edit-macropoint-order-button`"
            class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
            :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
            type="button"
            :disabled="selectionDisabled"
            @click="editMacropointOrder">
            {{ $t('Update Macropoint Order') }}
        </button>
        <button
            :id="`load-card-${load.id}-actions-cancel-macropoint-order-button`"
            class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
            :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
            type="button"
            :disabled="selectionDisabled"
            @click="cancelMacropointOrder">
            {{ $t('Cancel Macropoint Order') }}
        </button>
    </div>
    <div v-else>
        <button
            v-if="load.can.create_macropoint_order"
            :id="`load-card-${load.id}-actions-create-macropoint-order-button`"
            class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
            :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
            type="button"
            :disabled="selectionDisabled"
            @click="createMacropointOrder">
            {{ $t('Create Macropoint Order') }}
        </button>
    </div>
</template>

<script setup>
import Swal from 'sweetalert2';
import { Confirm } from '@/Composables/useSwal.js';
import { router } from '@inertiajs/vue3';
import { computed } from 'vue';

const { open: openLoadMacropointModal } = useModal('LoadMacropointModal');

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const selectionDisabled = computed(() => {
    if (load.is_tonu || load.is_canceled) {
        return true;
    }
});

function createMacropointOrder() {
    if (load.can?.create_macropoint_order?.length) {
        return Swal.fire({
            title: `Load #${load.id} is not ready for MacroPoint`,
            html: `<div class="flex justify-center">
                <ul class="text-left list-disc">
                    ${load.can.create_macropoint_order.map(reason => `<li>${reason}</li>`).join('')}
                </ul>
            </div>`,
            icon: 'error'
        });
    }
    openLoadMacropointModal({
        mode: 'create',
        load: load,
        title: 'Enable Macropoint Order'
    });
}

function editMacropointOrder() {
    openLoadMacropointModal({
        mode: 'edit',
        load: load,
        macropointOrder: load.macropointOrder,
        title: 'Update Macropoint Order'
    });
}

function cancelMacropointOrder() {
    Confirm.fire({
        title: 'Cancel Macropoint Order',
        text: 'Are you sure you would like to cancel the MacroPoint order for this load?'
    }).then(result => {
        if (result.isConfirmed) {
            router.delete(route('loads.macropoint.destroy', [load, load.macropointOrder])),
                {
                    preserveScroll: true,
                    only: ['load'],
                    onSuccess() {
                        close();
                    }
                };
        }
    });
}
</script>
