<template>
    <Head :title="customerGroup.name" />
    <AppHeader
        title="Customer Groups"
        :icon-component="IconCustomers" />
    <AppPanels
        id="customer-group-show-toggle"
        :title="customerGroup.name"
        :sections="{
            details: 'Details',
            accounting: 'Accounting',
            claims: 'Claims',
            external: 'External',
            billing: 'Billing',
            customers: 'Customers'
        }">
        <template #actions>
            <CustomerGroupActionMenu :customer-group="customerGroup" />
        </template>
        <template #details>
            <CustomerGroupDetailsCard :customer-group="customerGroup" />
        </template>
        <template #accounting>
            <CustomerGroupAccountingCard :customer-group="customerGroup" />
        </template>
        <template #claims>
            <CustomerGroupClaimsCard :customer-group="customerGroup" />
        </template>
        <template #external>
            <CustomerGroupExternalCard :customer-group="customerGroup" />
        </template>
        <template #billing>
            <CustomerGroupBillingCard :customer-group="customerGroup" />
        </template>
        <template #customers>
            <CustomerGroupCustomersTable :customer-group="customerGroup" />
        </template>
    </AppPanels>
</template>

<script setup>
import IconCustomers from '/resources/img/icon-customers.svg';

defineProps({
    customerGroup: Object
});
</script>
