<template>
    <div>
        <PowerBIReport v-if="source === 'power_bi'" />
    </div>
</template>

<script setup>
import PowerBIReport from '@/Pages/Reports/Partials/PowerBIReport.vue';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import ReportLayout from '@/Layouts/ReportLayout.vue';

defineOptions({
    layout: [AuthenticatedLayout, ReportLayout]
});

defineProps({
    reports: Object,
    tiers: Object,
    source: String
});
</script>
