<template>
    <form
        class="flex flex-col gap-2 rounded-md border border-iel-gray bg-white p-2"
        @submit.prevent="submit">
        <div class="gap-x-2 md:grid md:grid-cols-2">
            <AppAutocomplete
                v-model="form.address"
                name="address_1"
                :label="$t('Address 1')"
                :options-url="route('addresses.autocomplete')"
                :placeholder="$t('Search for an address...')"
                :initial-value="form.address.address_1"
                :disabled="isReadOnly"
                :is-read-only="isReadOnly"
                required />
            <AppInput
                v-model="form.address.address_2"
                name="address_2"
                :disabled="isReadOnly" />
            <AppInput
                v-model="form.address.city"
                name="city"
                :disabled="isReadOnly" />
            <AppInput
                v-model="form.address.region"
                name="region"
                :disabled="isReadOnly" />
            <AppInput
                v-model="form.address.postal_code"
                name="address.postal_code"
                :disabled="isReadOnly" />
            <AppSelect
                v-model="form.address.country"
                name="country"
                :options="{ CA: 'CA', MX: 'MX', US: 'US' }"
                :disabled="isReadOnly" />
        </div>
        <div class="flex items-center gap-2">
            <AppButton
                v-show="isReadOnly"
                :disabled="form.processing"
                @click="isReadOnly = false">
                {{ $t('Edit') }}
            </AppButton>
            <AppButton
                v-show="!isReadOnly"
                type="submit"
                :disabled="form.processing">
                {{ $t('Save') }}
            </AppButton>
            <p
                v-if="form.isDirty"
                class="text-sm italic text-iel-dark-gray">
                {{ $t('Unsaved changes') }}
            </p>
        </div>
        <div
            v-if="form.errors.address"
            class="flex w-full items-center gap-1 rounded border border-iel-light-red bg-iel-light-red px-1 py-0.5 text-sm text-iel-dark-red">
            <AppIcon
                name="far fa-circle-exclamation"
                class="h-4 w-4" />
            <p>{{ form.errors.address }}</p>
        </div>
    </form>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
const page = usePage();

const form = useForm({
    address: {
        address_1: page.props.address.address_1,
        address_2: page.props.address.address_2,
        city: page.props.address.city,
        region: page.props.address.region,
        postal_code: page.props.address.postal_code,
        country: page.props.address.country,
        latitude: page.props.address.latitude,
        longitude: page.props.address.longitude
    }
});

provide('form', form);

const isReadOnly = ref(true);

function submit() {
    form.patch(route('locations.update', page.props.location.id), {
        preserveScroll: true,
        onSuccess() {
            window.location.reload();
            isReadOnly.value = true;
        }
    });
}
</script>
