<template>
    <div
        v-if="items && items.length > 0"
        class="small-scrollbar flex items-center gap-2 overflow-x-auto overflow-y-hidden whitespace-nowrap bg-white pt-2 text-sm">
        <div
            v-for="(task, index) in items"
            :id="getId('load-*-taskbar-task-*', [load, { id: task.alias }])"
            :key="task.id"
            class="-mb-px flex cursor-pointer items-center gap-2"
            @click="task.tabHandler">
            <div
                class="flex items-center gap-2"
                :class="[
                    triggersTabChange ? 'border-b-4' : '',
                    triggersTabChange && selectedTask === task.name ? 'border-iel-blue' : 'border-transparent'
                ]">
                <div
                    class="flex size-4 items-center justify-center border border-iel-blue"
                    :class="[
                        unauthorized ? 'hover:none cursor-not-allowed' : 'cursor-pointer',
                        task.is_complete ? 'bg-iel-blue' : 'bg-white',
                        task.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                    ]"
                    @click.stop="task.checkboxHandler">
                    <AppIcon
                        v-if="task.is_complete"
                        name="far fa-check"
                        class="text-white" />
                </div>
                <div class="py-2">
                    {{ task.title }}
                </div>
                <div
                    v-if="task.past_due || task.due_soon"
                    :id="getId('load-*-taskbar-task-*-overdue', [load, { id: task.alias }])"
                    class="relative flex items-center space-x-1">
                    <AppButton
                        :id="getId('load-*-taskbar-task-*-snooze-button', [load, { id: task.alias }])"
                        size="sm"
                        variant="invisible"
                        :disabled="load.is_canceled"
                        @click="snoozeTask(task)">
                        <AppIcon name="fas fa-alarm-snooze" />
                    </AppButton>
                    <AppWarningIcon
                        :id="getId('load-*-taskbar-task-*-overdue-icon', [load, { id: task.alias }])"
                        class="tooltip"
                        size="sm"
                        :disabled="task.disabled">
                        <span class="tooltiptext">
                            <p v-if="task.past_due">Past Due</p>
                            <p v-else>Due Soon</p>
                        </span>
                    </AppWarningIcon>
                </div>
            </div>
            <div
                v-if="index < tasks.length - 1"
                class="w-12 border-t border-iel-light-gray" />
        </div>
    </div>
</template>

<script setup>
import { getId } from '@/helpers';
import { router } from '@inertiajs/vue3';
import { groupBy, pick, kebabCase } from 'lodash-es';
import Swal from 'sweetalert2';

const { load, tasks, triggersTabChange } = defineProps({
    load: Object,
    tasks: Array,
    triggersTabChange: Boolean
});

const tasksByPhase = computed(() => groupBy(load.tasks, 'phase'));

const step = useLoadStepsStore(load.id).step;

const selectedTask = computed(() => {
    if (step.value.task) return step.value.task;

    const firstTask = tasksByPhase.value[step.value.phase][0];
    if (isUntabbable(firstTask)) return step.value.phase;

    return firstTask.name;
});

const items = computed(() => {
    return (tasks || []).map(task => {
        // Normalize the task name to use it in ID attributes
        const alias = kebabCase(task.name);

        // Determine if the task is disabled
        const disabled = isTaskDisabled(task);

        // Set the appropiate checkbox handler, which will
        // be executed when the task checkbox is clicked
        let checkboxHandler = () => null;
        if (!disabled) {
            if (task.type === 'manual') {
                checkboxHandler = () =>
                    useModal('LoadTaskModal').open({
                        title: 'Edit/Complete Task',
                        mode: 'edit',
                        load: load,
                        task
                    });
            }

            if (
                ['create_build', 'route', 'setup_appointments'].includes(task.name) ||
                (task.name === 'invoice' && load.customer.billing_method === 'mail')
            ) {
                checkboxHandler = () => toggleTask(task);
            }

            if (task.name === 'invoice_customer' && load.customer.billing_method !== 'mail') {
                checkboxHandler = () => invoiceCustomer();
            }

            if (task.name === 'dispatch' || task.stop_id) {
                checkboxHandler = () => openCheckcall(task);
            }
        }

        // Set the appropriate tab handler, which will
        // be executed when the tab is clicked
        const tabHandler = !triggersTabChange || isUntabbable(task) ? () => null : () => selectStep(task);

        return { ...task, checkboxHandler, tabHandler, alias, disabled };
    });
});

function isUntabbable(task) {
    const untabbable = [
        'dispatch',
        'stop_status',
        'collect_paperwork',
        'ready_to_be_invoiced',
        'invoice_customer',
        'pay_carrier',
        'collect_payment'
    ];

    return untabbable.includes(task.name) || task.type === 'manual';
}

function selectStep(task) {
    const key = step.value.phase + '.' + task.name;
    localStorage.setItem(`load_${load.id}_step_${step.value.phase}`, key);
    useLoadStepsStore(load.id).setStep(key);
}

function toggleTask(task) {
    router.patch(
        route('loads.tasks.update', [load, task]),
        { is_complete: !task.is_complete },
        {
            preserveScroll: true
        }
    );
}

function openCheckcall(task) {
    const stop = load.stops.find(stop => stop.id === task.stop_id);
    const stopType = stop ? stop.type : null;
    const action = task.name.split('_').pop();
    let eventName;

    if (stop) {
        if (action === 'arrived') {
            eventName = stopType === 'pickup' ? 'Arrived at Pickup Location' : 'Arrived at Delivery Location';
        } else if (action === 'completed') {
            eventName = stopType === 'pickup' ? 'Loaded' : 'Unloaded';
        } else if (action === 'departed') {
            eventName = stopType === 'pickup' ? 'Departed Pickup Location' : 'Departed Delivery Location';
        }
    } else {
        eventName = 'Dispatch';
    }

    const existingCheckcall = load.trackings.find(
        tracking =>
            tracking.type === 'CC' &&
            !tracking.deleted_at &&
            (tracking.stop_id === stop?.id || (!stop && tracking.stop_id === null)) &&
            tracking.event === eventName
    );

    let defaults;
    if (existingCheckcall) {
        defaults = pick(existingCheckcall, [
            'stop_id',
            'event',
            'reason',
            'source',
            'event_timezone',
            'city',
            'region',
            'postal_code',
            'temperature',
            'internal_note',
            'external_note'
        ]);
        defaults.task = task.name;
        defaults.event_happened_at = existingCheckcall.event_happened_at.substring(0, 16);
    } else {
        defaults = {
            task: task.name,
            event: eventName,
            stop_id: stop ? stop.id : null
        };
    }

    const params = {
        mode: existingCheckcall ? 'edit' : 'create',
        checkcall: existingCheckcall,
        load,
        stop,
        defaults,
        task
    };

    useModal('LoadCheckcallModal').open(params);
}

function invoiceCustomer() {
    router.post(
        route('loads.customerInvoice.send', [load]),
        {},
        {
            preserveScroll: true,
            onError(e) {
                return Swal.fire({
                    title: e.message || 'Failed',
                    text: 'Ensure this load is ready to be invoiced.',
                    icon: 'error'
                });
            }
        }
    );
}

function snoozeTask(task) {
    useModal('LoadSnoozeModal').open({
        task: {
            load,
            task
        }
    });
}

// Check user perms to tell if checkboxes should be disabled by isTaskDisabled function
const { can } = useAuth();
const unauthorized = computed(() => !can('edit_load_tasks'));

function isTaskDisabled(task) {
    if (load.is_canceled || ['ready_to_be_invoiced', 'collect_paperwork'].includes(task.name)) return true;
    if (load.is_tonu) return isTonuTaskDisabled(task);
    if (unauthorized.value) return true;
    return false;
}

const isTonuTaskDisabled = task => {
    const specialTasks = [
        'collect_paperwork',
        'ready_to_be_invoiced',
        'invoice_customer',
        'pay_carrier',
        'collect_payment'
    ];

    const { total_pay_carrier_amount, total_customer_billing_amount } = load;

    if (total_pay_carrier_amount > 0 && total_customer_billing_amount > 0) {
        return !specialTasks.includes(task.name);
    }

    if (total_pay_carrier_amount > 0) {
        return task.name !== 'pay_carrier';
    }

    if (total_customer_billing_amount > 0) {
        return !specialTasks.filter(taskName => taskName !== 'pay_carrier').includes(task.name);
    }

    return false;
};
</script>
