<template>
    <div class="flex items-center gap-4">
        <AppCard
            v-for="box in boxes"
            :key="box.title"
            :title="box.title"
            theme="blue"
            class="@container"
            content-class="p-4 grid grid-cols-4 @2xl:grid-cols-7 gap-4 text-sm"
            overflow-class="overflow-visible">
            <div
                v-for="cell in box.cells"
                :key="cell.label">
                <p class="pb-4 font-bold">{{ cell.label }}</p>
                <div v-if="cell.label === 'Pieces'">
                    <AppDropdown
                        v-if="load.stops?.length"
                        align="bottom-right"
                        content-width="w-80">
                        <template #trigger>
                            <div
                                class="flex items-center gap-1"
                                :class="[{ underline: load.stops?.length }]">
                                <p>View All Pieces</p>
                                <AppIcon
                                    v-if="load.stops?.length"
                                    name="far fa-chevron-down"
                                    class="h-3 w-3" />
                            </div>
                        </template>

                        <template #content>
                            <div class="py-2">
                                <div
                                    v-for="stop in load.stops"
                                    :key="stop.order"
                                    class="hover:bg-gray-100 px-4 py-2">
                                    <div class="flex items-center justify-between">
                                        <p class="font-medium">Stop {{ stop.order }}, {{ stop.location.name }}</p>
                                        <p>{{ stop.pieces }} {{ stop.pieces_unit || '-' }}</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </AppDropdown>
                    <p v-else>-</p>
                </div>
                <p
                    v-else
                    class="capitalize">
                    {{ cell.value }}
                </p>
            </div>
        </AppCard>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { formatComboVals } from '@/helpers';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const boxes = computed(() => {
    return [
        {
            title: 'Load Details',
            cells: [
                {
                    label: 'Equipment',
                    value: formatComboVals(load.equipment_length, load.required_equipment_type, "'")
                },
                {
                    label: 'Temp',
                    value: formatComboVals(load.temperature, load.temperature_units?.toUpperCase(), '°')
                },
                {
                    label: 'Total Trip Miles',
                    value: load.trip_miles?.toLocaleString() ?? '-'
                },
                {
                    label: 'Commodity',
                    value: load.commodity_value ?? '-'
                },
                {
                    label: 'Pallets',
                    value: load.total_pallets ?? '-'
                },
                {
                    label: 'Pieces',
                    dropdownContent: load.stops
                },
                {
                    label: 'Total Load Weight',
                    value: `${load.total_load_weight ?? ''} ${load.total_load_weight_description ?? '-'}`
                }
            ]
        }
    ];
});
</script>
