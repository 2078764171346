<template>
    <LoadStopsList
        :load="load"
        :display-columns="['type', 'location', 'time', 'appt', 'po', 'miles', 'internal-note', 'status']"
        :has-delete="false"
        :open-stop-id="openStopId" />
</template>

<script setup>
const { load } = defineProps({
    load: Object
});

const openStopId = computed(() => {
    return load.stops.find(stop => !stop.arrived_at || !stop.completed_at || !stop.departed_at)?.id;
});
</script>
