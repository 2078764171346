<template>
    <Head title="Loadboard" />
    <LoadHeader :loads="loads" />
    <slot />
</template>

<script setup>
const { loads } = defineProps({
    loads: {
        type: Object,
        required: true,
        default: () => {}
    }
});
</script>
