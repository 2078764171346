<template>
    <div
        v-if="tasksByPhase[selectedTab]"
        class="overflow-hidden bg-white">
        <div class="small-scrollbar flex gap-5 overflow-x-auto whitespace-nowrap p-3">
            <LoadLegacyCheckboxButton
                v-for="task in tasksByPhase[selectedTab]"
                :id="`loadcard-${task.type}-${task.phase}-${task.id}-${task.name.replaceAll('_', '-')}-task-button`"
                :key="task.id"
                :class="{ hidden: task.name === 'dispatch' && !load.stops.length }"
                :checked="task.is_complete"
                :load="load"
                :task="task"
                permission="edit_load_tasks"
                @complete-task="handleTaskClick(task)">
                {{ task.title }}
            </LoadLegacyCheckboxButton>
        </div>
    </div>
</template>

<script setup>
const { load, selectedTab } = defineProps({
    load: Object,
    selectedTab: String
});

const { tasksByPhase, handleTaskClick } = useTasks(toRef(() => load));
</script>
