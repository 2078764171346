<template>
    <section :id="getId('load-*-carrier-rates', [load])">
        <AppHeading size="lg">
            {{ $t('Carrier Pay Lines') }}
        </AppHeading>

        <table
            v-if="load.carrierTransactions.length"
            :id="getId('load-*-carrier-billing-rates-table', [load])"
            class="NX-mobile-stacked w-full">
            <thead>
                <tr>
                    <th>{{ $t('Location') }}</th>
                    <th>{{ $t('Type') }}</th>
                    <th>{{ $t('Quantity') }}</th>
                    <th>{{ $t('Amount') }}</th>
                    <th>{{ $t('Total') }}</th>
                    <th>{{ $t('Note') }}</th>
                </tr>
            </thead>

            <tbody>
                <LoadTransaction
                    v-for="rate in load.carrierTransactions"
                    :key="rate.id"
                    permission="update_load_billing_details"
                    :load="load"
                    :transaction="rate"
                    :disable-context="load.paidCarrier || !load.can.view_load_details" />
            </tbody>
        </table>

        <div
            v-else
            class="text-sm">
            {{ $t('This load has no carrier pay line items.') }}
        </div>

        <div class="mt-4 flex items-center justify-between gap-2">
            <AppButton
                id="load-add-carrier-rate-button"
                type="button"
                icon="far fa-circle-plus"
                permission="update_load_billing_details"
                :disabled="load.is_canceled_or_tonu || load.paidCarrier || !load.can.view_load_details"
                @click="debouncedAddCarrierRate">
                {{ $t('Carrier Pay Line') }}
            </AppButton>
            <AppHeading
                :id="getId('load-*-carrier-rates-total', [load])"
                size="md">
                {{ $t('Total') }} {{ formatMoney(load.total_pay_carrier_amount) }}
            </AppHeading>
        </div>
    </section>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { debounce } from 'lodash-es';
import { formatMoney, getId } from '@/helpers';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

function addCarrierRate() {
    router.post(
        route('loads.billing.store', load.id),
        { quantity: 1, amount: 0, line_type: 'Flat Rate', bill_type: 'carrier' },
        {
            preserveScroll: true,
            only: ['load']
        }
    );
}

const debouncedAddCarrierRate = debounce(addCarrierRate, 300);
</script>
