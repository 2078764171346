<template>
    <div class="flex flex-wrap gap-4 xl:flex-nowrap">
        <div
            v-if="load.can.view_load_details"
            class="w-full xl:w-1/2">
            <LoadBillCustomerBilling :load="load" />
        </div>
        <div class="flex w-full flex-col gap-4 xl:w-1/2">
            <LoadBillPayCarrier :load="load" />
            <LoadBillThirdParty :load="load" />
        </div>
    </div>
</template>

<script setup>
defineProps({
    load: {
        type: Object,
        required: true
    }
});
</script>
