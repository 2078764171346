<template>
    <AppCard
        title="Carrier Pay Details"
        theme="blue"
        content-class="p-4 flex flex-col gap-8">
        <LoadCarrierBudget :load="load" />
        <LoadPlanCarrierRates :load="load" />
    </AppCard>
</template>

<script setup>
defineProps({
    load: {
        type: Object,
        required: true
    }
});
</script>
