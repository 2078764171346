<template>
    <AppCard
        :id="getId('quote-*-details', [quote])"
        :title="$t('Load Details')"
        theme="blue"
        content-class="p-4">
        <div class="grid grid-cols-12 gap-x-2 gap-y-4">
            <div class="col-span-2">
                <AppSelect
                    :id="getId('quote-*-transportation-mode', [quote])"
                    name="transportation_mode"
                    :model-value="quote.transportation_mode"
                    :url="url"
                    :options="transportationModes" />

                <AppErrorMessage
                    :id="getId('quote-*-transportation-mode-warning', [quote])"
                    name="all_risk_required_ltl" />
            </div>

            <div class="col-span-2">
                <AppSelect
                    :id="getId('quote-*-value-range', [quote])"
                    name="value_range"
                    :label="$t('Load Value')"
                    :model-value="quote.value_range"
                    :url="url"
                    :options="valueRanges" />

                <AppErrorMessage
                    :id="getId('quote-*-value-range-warning', [quote])"
                    name="all_risk_required_value_range" />
            </div>

            <AppSelect
                :id="getId('quote-*-commodity-type', [quote])"
                name="commodity_type"
                :model-value="quote.commodity_type"
                :url="url"
                :options="commodityTypes"
                class="col-span-2" />

            <AppSelect
                :id="getId('quote-*-commodity-value', [quote])"
                name="commodity_value"
                :label="$t('Commodity')"
                :model-value="quote.commodity_value"
                :url="url"
                :options="commodityValues"
                class="col-span-2" />

            <AppSelect
                :id="getId('quote-*-required-equipment-type', [quote])"
                name="required_equipment_type"
                :model-value="quote.required_equipment_type"
                :url="url"
                :options="equipmentTypes"
                class="col-span-2" />

            <div class="col-span-2 grid grid-cols-3 items-start gap-0.5">
                <AppInput
                    :id="getId('quote-*-temperature-value', [quote])"
                    v-model="temperatureValue"
                    name="temperature"
                    :url="url"
                    :disabled="tempDisabled"
                    class="col-span-2" />

                <AppSelect
                    :id="getId('quote-*-temperature-units', [quote])"
                    name="temperature_units"
                    label=""
                    class="relative -bottom-6"
                    :model-value="temperatureUnitValue"
                    :url="url"
                    :disabled="tempDisabled"
                    :options="{ c: 'C', f: 'F' }" />
            </div>

            <AppDisplayField
                :id="getId('quote-*-trip-miles', [quote])"
                :label="$t('Trip Miles')"
                :value="quote.trip_miles?.toLocaleString()"
                :error="tripMilesError"
                class="col-span-1" />

            <AppInput
                :id="getId('quote-*-customer-miles', [quote])"
                v-model="quote.customer_miles"
                name="customer_miles"
                :url="url"
                class="col-span-2" />

            <AppInput
                :id="getId('quote-*-equipment length', [quote])"
                v-model="quote.equipment_length"
                name="equipment_length"
                :label="$t('Equip. Length')"
                :url="url"
                class="col-span-1" />

            <div class="col-span-2 flex items-end justify-center">
                <AppDisplayField
                    :id="getId('quote-*-weight-value', [quote])"
                    label="Total Weight"
                    :value="quote.total_load_weight" />

                <AppDisplayField
                    :id="getId('quote-*-weight-units', [quote])"
                    label=""
                    :value="quote.total_load_weight_description" />
            </div>

            <AppInput
                :id="getId('quote-*-total-pallets', [quote])"
                v-model="quote.total_pallets"
                name="total_pallets"
                :url="url"
                class="col-span-2" />

            <AppInput
                :id="getId('quote-*-total-pieces', [quote])"
                v-model="quote.total_pieces"
                name="total_pieces"
                :url="url"
                class="col-span-2" />
        </div>

        <div class="grid grid-cols-12 gap-x-2 gap-y-4 pt-3">
            <AppInput
                id="quote-details-customer-po"
                v-model="quote.customer_po"
                permission="update_load_details"
                name="customer_po"
                label="Customer PO"
                :url="url"
                class="col-span-3" />
        </div>

        <hr class="my-4 border-iel-gray" />

        <div class="grid grid-cols-2 gap-2">
            <div
                :id="getId('quote-*-details-references', [quote])"
                class="w-full">
                <AppHeading
                    :id="getId('quote-*-references-header', [quote])"
                    size="lg">
                    {{ $t('References') }}
                </AppHeading>

                <table
                    v-if="form.references.length"
                    :id="getId('quote-*-details-references-table', [quote])"
                    class="NX-mobile-stacked w-full">
                    <thead>
                        <tr>
                            <th>{{ $t('Type') }}</th>
                            <th>{{ $t('Number') }}</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(reference, index) in form.references"
                            :key="index">
                            <td>
                                <div class="flex items-center gap-2">
                                    <AppSelect
                                        :id="getId('quote-*-reference-*-description', [quote, { id: index }])"
                                        v-model="reference.description"
                                        :name="`quote-${quote.id}-reference-description-${index}`"
                                        label=""
                                        class="w-full"
                                        :options="{
                                            BOL: 'BOL',
                                            'Delivery Number': 'Delivery Number',
                                            PRO: 'PRO',
                                            SAP: 'SAP',
                                            'Customer Reference': 'Customer Reference',
                                            'Freight Agreement Number': 'Freight Agreement Number',
                                            'Purchase Order Number': 'Purchase Order Number',
                                            'Release Number': 'Release Number',
                                            Other: 'Other'
                                        }" />

                                    <AppInput
                                        v-if="reference.description === 'Other'"
                                        :id="getId('quote-*-reference-*-other-description', [quote, { id: index }])"
                                        v-model="reference.other"
                                        :name="`quote-${quote.id}-other-reference-description-${index}`"
                                        placeholder="Type" />
                                </div>
                            </td>

                            <td>
                                <AppInput
                                    :id="getId('quote-*-reference-*-number', [quote, { id: index }])"
                                    v-model="reference.number"
                                    :name="`quote-${quote.id}-reference-number-${index}`"
                                    label=""
                                    placeholder="Number" />
                            </td>

                            <td>
                                <AppButton
                                    :id="getId('quote-*-reference-*-remove-button', [quote, { id: index }])"
                                    variant="outline"
                                    icon="far fa-trash-can"
                                    type="button"
                                    @click="removeReference(index)" />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p
                    v-else
                    class="text-sm">
                    {{ $t('This quote has no references.') }}
                </p>

                <AppButton
                    :id="getId('quote-*-reference-add-button', [quote])"
                    icon="far fa-circle-plus"
                    class="mt-2"
                    @click="addReference">
                    {{ $t('Add Reference') }}
                </AppButton>
            </div>

            <AppTextarea
                :id="getId('quote-*-general-notes', [quote])"
                v-model="quote.internal_general_notes"
                name="internal_general_notes"
                label="General Notes"
                input-class="resize-none"
                rows="8"
                :url="generalNotesUrl" />
        </div>
    </AppCard>
</template>

<script setup>
import { useLoadDetails } from '@/Composables/useLoadDetails';

const { quote } = defineProps({
    quote: {
        type: Object,
        required: true,
        default: () => {}
    }
});

const {
    url,
    generalNotesUrl,
    equipmentTypes,
    valueRanges,
    transportationModes,
    commodityTypes,
    commodityValues,
    form,
    temperatureValue,
    temperatureUnitValue,
    tripMilesError,
    removeReference,
    addReference,
    tempDisabled,
    getId
} = useLoadDetails(quote, {
    entityType: 'quote',
    usePermissions: true,
    useConfirmation: true
});
</script>
