<template>
    <Head title="Settings" />

    <header class="bg-white shadow">
        <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h2 class="text-xl font-semibold leading-tight text-iel-dark-gray">Settings</h2>
        </div>
    </header>

    <div class="py-12">
        <div class="mx-auto max-w-7xl space-y-6 sm:px-6 lg:px-8">
            <div class="bg-white p-4 shadow sm:rounded-lg sm:p-8">
                <SettingsProfileInfoForm
                    :must-verify-email="mustVerifyEmail"
                    :status="status"
                    class="max-w-xl" />
            </div>

            <div class="bg-white p-4 shadow sm:rounded-lg sm:p-8">
                <SettingsProfileTimezoneForm
                    :users="users"
                    class="max-w-xl" />
            </div>

            <div class="bg-white p-4 shadow sm:rounded-lg sm:p-8">
                <SettingsProfilePasswordForm class="max-w-xl" />
            </div>
        </div>
    </div>
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import { Head } from '@inertiajs/vue3';

defineOptions({ layout: AuthenticatedLayout });

defineProps({
    mustVerifyEmail: Boolean,
    status: String,
    users: Array,
    roles: Array
});
</script>
