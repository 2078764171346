<template>
    <div class="flex items-center gap-x-2">
        <button
            v-for="option in layoutOptions"
            :id="`quotes-${option.layout}-layout-toggle`"
            :key="option.layout"
            class="flex items-center justify-center rounded p-1"
            :class="generalboard.layout === option.layout ? 'bg-iel-light-gray' : 'hover:bg-iel-silver'"
            :aria-label="`view ${option.layout} layout`"
            @click="setLayout(option.layout)">
            <AppIcon
                :name="option.icon"
                :class="option.iconClass" />
        </button>
    </div>
</template>

<script setup>
import { generalboard } from '@/stores';

const emit = defineEmits(['change']);

const layoutOptions = [
    {
        layout: 'list',
        icon: 'far fa-bars',
        iconClass: 'h-5 w-5'
    },
    {
        layout: 'card',
        icon: 'far fa-grid',
        iconClass: 'h-5 w-5'
    }
];

function setLayout(layout) {
    generalboard.value.layout = layout;
    emit('change', layout);
}
</script>
