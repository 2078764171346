<template>
    <LoadStopsList
        :load="load"
        :display-columns="['type', 'location', 'time', 'appt', 'po', 'miles', 'mode', 'phone']"
        :has-delete="false"
        :is-sortable="false" />
</template>

<script setup>
const { load } = defineProps({
    load: Object
});
</script>
