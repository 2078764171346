<template>
    <AppHeader
        id="loadboard-header"
        :title="$t('Loadboard')"
        :icon-component="IconLoadboard">
        <template #search>
            <AppSearchInput
                id="loadboard-search"
                v-model="q"
                placeholder="Name, #, Location, PO"
                class="w-52" />
        </template>

        <template #middle>
            <LoadHeaderMultiselects />
        </template>

        <template #actions>
            <LoadFilterDropdown
                class="3xl:hidden max-w-48"
                :filters="filters"
                :link-params="{ only: ['loads', 'filter'] }"
                :root-url="url" />
            <AppFilterPills
                id="loadboard-filters"
                class="3xl:flex hidden gap-x-2 border-r border-iel-gray pr-3"
                :filters="filters"
                :link-params="{ only: ['loads', 'filter'] }"
                :root-url="url" />
            <div>
                <AppButton
                    id="add-load-button"
                    icon="far fa-circle-plus"
                    permission="create_new_load"
                    @click="openLoadCreateModal">
                    {{ $t('New Load') }}
                </AppButton>
            </div>
            <LoadLayoutToggle />
        </template>
    </AppHeader>
</template>

<script setup>
import { allFilters } from '@/Composables/useFilters.js';
import IconLoadboard from '/resources/img/icon-loadboard.svg';

import { loadboard } from '@/stores.js';

const { open: openLoadCreateModal } = useModal('LoadCreateModal');
const { filters } = allFilters();
const { q } = useQueryParam({ only: ['loads', 'filter'] });

const url = computed(() => (loadboard.value.layout === 'list' ? `/loads/list` : `/loads`));
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
