<template>
    <div class="px-1 py-2">
        <div class="group flex space-x-1">
            <AppButton
                :disabled="!canAccept || !canReject"
                @click.stop="acceptTender"
                :id="getId('tender-*-accept-bid', [tender])"
                name="accept_bid"
                icon="far fa-circle-check"
                size="md"
                class="tooltip">
                <span class="tooltiptext">{{ $t('Accept') }}</span>
            </AppButton>
            <AppButton
                :disabled="!canAccept || !canReject"
                @click.stop="rejectTender"
                :id="getId('tender-*-reject-bid', [tender])"
                name="reject_bid"
                icon="far fa-circle-xmark"
                size="md"
                class="tooltip">
                <span class="tooltiptext">{{ $t('Reject') }}</span>
            </AppButton>
        </div>
    </div>
</template>

<script setup>
import { getId } from '@/helpers';
import Swal from 'sweetalert2';
import { router } from '@inertiajs/vue3';
const { open: rejectTenderModal } = useModal('RejectTenderModal');

const { tender, options } = defineProps({
    tender: {
        type: Object,
        required: true,
        default: () => ({
            bids: []
        })
    },
    options: {
        type: Object,
        required: true,
        default: () => ({
            rejection_reasons: []
        })
    }
});

function acceptTender() {
    router.post(
        route('provisionalLoads.tender.accept', [tender.id, tender.tender_id]),
        {},
        {
            preserveScroll: true,
            onSuccess: page => {
                Swal.fire({
                    title: 'Tender Accepted',
                    text: page.props.response.message
                });
            },
            onError(e) {
                const error = e.message || JSON.parse(e.error)?.errors[0]?.detail;
                return Swal.fire({
                    title: 'Unable to Accept Tender',
                    text: error || 'Failed',
                    icon: 'error'
                });
            }
        }
    );
}

function rejectTender() {
    rejectTenderModal({
        tender_ids: [tender.tender_id],
        rejection_reasons: options.rejection_reasons
    });
}

const canAccept = computed(() => {
    return tender.edi_tender.accepted_at === null;
});

const canReject = computed(() => {
    return tender.edi_tender.accepted_at === null;
});
</script>
