<template>
    <AppCard
        :id="getId('load-*-bill-advances', [load])"
        title="Advances"
        theme="blue"
        content-class="p-6">
        <div v-if="comchecks.length > 0">
            <AppTablePill
                theme="gray"
                :columns="[
                    { name: 'formatted_sent_to', label: 'Sent To' },
                    { name: 'name', label: 'Name' },
                    { name: 'contact_info', label: 'Contact Info' },
                    { name: 'reason', label: 'Reason' },
                    { name: 'amount', label: 'Amount' },
                    { name: 'fee', label: 'Fee' },
                    { name: 'icon-receipt-required', label: 'Receipt Req\'d' },
                    { name: 'icon-charge-carrier', label: 'Charge Carrier' },
                    { name: 'express_check_number', label: 'Check #' },
                    { name: 'status', label: 'Status' },
                    { name: 'actions', label: '' }
                ]"
                :data="comchecks"
                :parent-id="load.id">
                <template #name-cell="comcheck">
                    <span>{{ comcheck.row.first_name }} {{ comcheck.row.last_name }}</span>
                </template>
                <template #amount-cell="comcheck">
                    <span>${{ comcheck.row.amount }}</span>
                </template>
                <template #icon-receipt-required-cell="comcheck">
                    <div class="flex justify-center">
                        <AppIcon
                            :id="`load-${load.id}-receipt-required-icon`"
                            :name="comcheck.row.receipt_required ? 'far fa-circle-check' : 'fal fa-circle-xmark'"
                            :class="comcheck.row.receipt_required ? 'text-iel-green' : 'text-iel-red'"
                            class="h-5 w-5"
                            solid />
                    </div>
                </template>
                <template #icon-charge-carrier-cell="comcheck">
                    <div class="flex justify-center">
                        <AppIcon
                            :id="`load-${load.id}-charge-carrier-icon`"
                            :name="comcheck.row.charge_carrier ? 'far fa-circle-check' : 'fal fa-circle-xmark'"
                            :class="comcheck.row.charge_carrier ? 'text-iel-green' : 'text-iel-red'"
                            class="h-5 w-5"
                            solid />
                    </div>
                </template>
                <template #actions-cell="comcheck">
                    <div class="flex w-full justify-end gap-4">
                        <AppButton
                            :id="`load-card-${load.id}-actions-edit-comcheck-button`"
                            type="button"
                            title="Edit"
                            icon="fal fa-pen"
                            permission="update_advances"
                            :disabled="isButtonDisabled"
                            @click="editComcheck(comcheck.row)" />
                        <AppButton
                            :id="`load-card-${load.id}-actions-void-comcheck-button`"
                            type="button"
                            title="Void"
                            icon="fal fa-circle-xmark"
                            permission="update_advances"
                            :disabled="isButtonDisabled"
                            @click="voidComcheck(comcheck.row.id)" />
                        <AppButton
                            :id="`load-card-${load.id}-actions-check-advance-status-comcheck-button`"
                            type="button"
                            title="Check Advance Status"
                            icon="far fa-circle-check"
                            permission="update_advances"
                            :disabled="load.is_canceled_or_tonu"
                            @click="checkStatus(comcheck.row)" />
                        <AppButton
                            :id="`load-card-${load.id}-actions-transfer-comcheck-button`"
                            type="button"
                            title="Transfer"
                            icon="far fa-sync"
                            permission="update_advances"
                            :disabled="isButtonDisabled"
                            @click="transferComcheck(comcheck.row.id)" />
                    </div>
                </template>
            </AppTablePill>

            <div class="flex justify-end gap-6">
                <div>
                    <strong>Total Fee Amount:</strong>
                    {{ formatMoney(totals.fee) }}
                </div>
                <div>
                    <strong>Total Advance Amount:</strong>
                    {{ formatMoney(totals.advance) }}
                </div>
            </div>
        </div>
        <div v-else>No comchecks found.</div>
    </AppCard>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { getId, formatMoney } from '@/helpers';
import { map, pick, mapValues, sumBy } from 'lodash';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const isButtonDisabled = computed(() => load.is_canceled_or_tonu || Boolean(load.route_completed));

function voidComcheck(advanceId) {
    if (confirm('Are you sure you want to void this Comcheck?')) {
        router.delete(route('comcheck.cancel', { advance: advanceId }), {
            preserveScroll: true,
            onError(e) {
                alert(e.error_message);
            }
        });
    }
}

function editComcheck(advance) {
    useModal('LoadComcheckModal').open({
        title: 'Edit Comcheck',
        mode: 'edit',
        load,
        advance
    });
}

function transferComcheck(advance) {
    useModal('LoadComcheckTransferModal').open({
        title: 'Transfer Comcheck',
        advance
    });
}

function formatSendTo(value) {
    const mappings = {
        third_party: 'Third Party',
        driver: 'Driver',
        dispatcher: 'Dispatcher'
    };
    return mappings[value] || value;
}

const comchecks = computed(() =>
    map(load.advances, advance => ({
        ...pick(advance, [
            'sent_to',
            'id',
            'carrier_id',
            'load_id',
            'first_name',
            'last_name',
            'contact_info',
            'sent_via',
            'reason',
            'amount',
            'fee',
            'receipt_required',
            'charge_carrier',
            'express_check_number',
            'waive_fee',
            'status'
        ]),
        formatted_sent_to: formatSendTo(advance.sent_to),
        plainFee: parseFloat(advance.fee.replace('$', '')),
        plainAmount: parseFloat(advance.amount.replace('$', ''))
    }))
);

const totals = computed(() => {
    return mapValues({ fee: 'plainFee', advance: 'plainAmount' }, function (value) {
        return sumBy(comchecks.value, value);
    });
});

async function checkStatus(advance) {
    router.visit(route('comcheck.inquiry', { advance: advance.id }, {}), {
        preserveScroll: true,
        onError(e) {
            alert(e.error_message);
        }
    });
}
</script>
