<template>
    <div
        v-if="step"
        :id="getId('load-*-phases', [load])"
        class="grid">
        <!-- Phases -->
        <div class="flex overflow-hidden bg-iel-light-gray">
            <button
                v-for="phase in phases"
                :id="`load-card-${load.id}-${phase.name}-phase-tab`"
                :key="`load-card-${load.id}-${phase.name}-phase-tab`"
                class="chevron relative -ml-2 flex w-32 cursor-pointer flex-col items-center gap-1 py-3 transition-colors"
                :class="
                    phase.name === step.phase
                        ? 'bg-white text-iel-blue'
                        : {
                              'bg-iel-blue text-white': phase.status === 'complete',
                              'bg-iel-gray/50 text-iel-blue': phase.status === 'pending',
                              'bg-iel-light-gray text-iel-blue': phase.status === 'incomplete'
                          }
                "
                @click="selectStep(phase.name)">
                <AppIcon
                    :name="phaseIcons[phase.name]"
                    class="size-6" />
                <span class="text-sm font-medium">
                    {{ phase.label }}
                </span>
                <div
                    v-show="phase.name !== 'complete' && step.phase === phase.name"
                    class="chevron-line absolute inset-0 bg-iel-blue" />
            </button>
        </div>

        <!-- Taskbar -->
        <LoadLegacyTaskbar
            v-if="forceCheckboxTaskbar"
            v-bind="{ load, selectedTab: step.phase }" />
        <LoadTaskbar
            v-else-if="step.phase !== 'summary'"
            class="border-b border-iel-light-gray px-4"
            v-bind="{ load, tasks, triggersTabChange: true }" />
    </div>
</template>

<script setup>
import { getId } from '@/helpers';
import { get, last, groupBy } from 'lodash-es';

const { load, showSummary, forceCheckboxTaskbar, readStepFromStore } = defineProps({
    load: Object,
    showSummary: Boolean,
    forceCheckboxTaskbar: Boolean,
    readStepFromStore: Boolean
});

const phaseIcons = {
    summary: 'fas fa-square-list',
    setup: 'fal fa-file',
    plan: 'fal fa-clipboard-list',
    ship: 'fal fa-truck',
    bill: 'fal fa-circle-dollar'
};

const step = readStepFromStore ? useLoadStepsStore(load.id).step : ref({ phase: showSummary ? 'summary' : load.phase });

const phases = computed(() => {
    const list = load.phases.filter(phase => phase.name !== 'complete');
    if (showSummary) list.unshift({ name: 'summary', label: 'Summary', status: 'complete' });
    return list;
});

const tasksByPhase = computed(() => groupBy(load.tasks, 'phase'));

const tasks = computed(() => {
    let tasks = tasksByPhase.value[step.value.phase];

    // The "Setup" phase has special tasks
    if (step.value.phase === 'ship') {
        // This task is added to the "Ship" phase and indicates
        // if all stops have been departed (in other words, done)
        const stopStatusTask = {
            id: Number.MAX_SAFE_INTEGER,
            phase: 'ship',
            name: 'stop_status',
            title: 'Stop Status',
            order: 2,
            is_complete: load.stops.every(stop => stop.departed_at),
            completed_at: get(last(load.stops), 'completed_at'),
            callback: null
        };

        // Filter out tasks with a stop ID (arrived, completed, departed)
        // and insert the "Stop Status" task on their place
        tasks = tasksByPhase.value.ship.filter(task => !task.stop_id).toSpliced(1, 0, stopStatusTask);
    }

    return tasks;
});

function selectStep(key) {
    if (!readStepFromStore) {
        step.value.phase = key;
        return;
    }

    const cachedKey = localStorage.getItem(`load_${load.id}_step_${key}`);
    useLoadStepsStore(load.id).setStep(cachedKey ? cachedKey : key);
}
</script>

<style scoped>
.chevron {
    --offset: theme('spacing.3');
    --shape: polygon(
        calc(100% - var(--offset)) 0%,
        100% 50%,
        calc(100% - var(--offset)) 100%,
        0% 100%,
        var(--offset) 50%,
        0% 0%
    );
    clip-path: var(--shape);
    shape-outside: var(--shape);
}

.chevron-line {
    --offset: theme('spacing.3');
    --width: 3px;
    --shape: polygon(
        calc(100% + var(--offset)) 0%,
        calc(100% - var(--offset)) 100%,
        calc(100% - var(--offset) - var(--width)) 100%,
        calc(100% + var(--offset) - var(--width)) 0%
    );
    clip-path: var(--shape);
    shape-outside: var(--shape);
}

.chevron:first-child {
    /* No left cutout on the far left */
    --shape: polygon(calc(100% - var(--offset)) 0%, 100% 50%, calc(100% - var(--offset)) 100%, 0% 100%, 0% 0%);
}
</style>
