<template>
    <AppCard
        :id="getId('load-*-accessorial-charges', [load])"
        title="Accessorial Charges"
        theme="blue"
        content-class="p-4">
        <LoadAccessorials :load="load" />
        <hr class="my-4 border-iel-gray" />

        <div class="grid grid-cols-2 gap-6">
            <LoadFinancesBox
                :id="getId('load-*-customer-bill-total', [load])"
                label="Accessorial Customer Bill Total"
                text-size="text-lg"
                :value="!load.can.view_load_details ? '-' : data.customerAccessorials" />

            <LoadFinancesBox
                :id="getId('load-*-carrier-pay-total', [load])"
                label="Accessorial Carrier Pay Total"
                text-size="text-lg"
                :value="data.carrierAccessorials" />
        </div>
    </AppCard>

    <LoadFinancesBox
        text-size="text-lg"
        label="Total Carrier Pay"
        :value="data.carrierTotalPay" />
</template>

<script setup>
import { mapValues } from 'lodash-es';
import { formatMoney } from '@/helpers';
import { getId } from '@/helpers';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const loadRef = toRef(() => load);
const loadStats = useLoadStats(loadRef);

const data = computed(() =>
    mapValues(loadStats, (stat, key) => {
        return key === 'margin' ? stat.value.toFixed(2) + '%' : formatMoney(stat.value);
    })
);
</script>
