<template>
    <div
        id="global-search"
        class="xl:w-72">
        <Combobox v-model="selected">
            <div class="relative">
                <div class="relative w-full">
                    <AppIcon
                        name="far fa-magnifying-glass"
                        class="pointer-events-none absolute inset-y-0 left-2 h-full w-5 text-iel-light-gray" />
                    <ComboboxInput
                        :display-value="() => query"
                        class="block w-full rounded-full border-iel-gray/40 py-1 pl-8"
                        :placeholder="$t('Search...')"
                        @change="query = $event.target.value" />
                </div>
                <TransitionRoot
                    leave="transition ease-in duration-75"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                    @after-leave="results = {}">
                    <div
                        v-if="query !== '' && noResults"
                        class="absolute z-50 mt-1 w-full select-none rounded-lg bg-white px-2 py-4 text-center text-base text-iel-dark-gray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {{ $t('Nothing found') }}
                    </div>
                    <ComboboxOptions
                        v-else-if="!noResults"
                        class="absolute z-50 mt-1 max-h-80 w-full overflow-y-auto rounded-lg bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        tabindex="0">
                        <template
                            v-for="(items, resource) in results"
                            :key="resource">
                            <li v-if="items.length">
                                <h2 class="bg-iel-silver px-2 py-1 text-sm font-semibold capitalize text-iel-dark-gray">
                                    {{ labels[resource] }}
                                </h2>
                                <ul class="text-sm text-iel-dark-gray">
                                    <ComboboxOption
                                        v-for="item in items"
                                        :key="item.id"
                                        v-slot="{ active }"
                                        as="template"
                                        :value="item">
                                        <li
                                            :class="[
                                                'cursor-pointer select-none px-2 py-1',
                                                { 'bg-iel-lightest-blue': active }
                                            ]">
                                            <span
                                                v-if="item.name"
                                                class="block truncate">
                                                {{ item.name }}
                                            </span>
                                            <div
                                                v-if="item.po_number"
                                                class="flex justify-between truncate">
                                                <span>IEL PO</span>
                                                <strong class="ml-2">#{{ item.po_number }}</strong>
                                            </div>
                                            <div
                                                v-if="item.customer_po"
                                                class="flex justify-between truncate">
                                                <span>Customer PO</span>
                                                <strong class="ml-2">#{{ item.customer_po }}</strong>
                                            </div>
                                            <div
                                                v-if="resource === 'quotes'"
                                                class="flex justify-between truncate">
                                                <span>Quote #</span>
                                                <strong class="ml-2">Q{{ item.id }}</strong>
                                            </div>
                                        </li>
                                    </ComboboxOption>
                                </ul>
                            </li>
                        </template>
                    </ComboboxOptions>
                </TransitionRoot>
            </div>
        </Combobox>
    </div>
</template>

<script setup>
import axios from 'axios';

import { router } from '@inertiajs/vue3';
import { debounce } from 'lodash-es';
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption, TransitionRoot } from '@headlessui/vue';

const query = ref('');
const selected = ref(null);
const results = ref({});

const labels = {
    loads: 'Loads',
    quotes: 'Quotes',
    customers: 'Customers',
    'customers.groups': 'Customer Groups',
    carriers: 'Carriers'
};

watch(
    query,
    debounce(async q => {
        if (q && q.length > 1) {
            const data = (await axios.post(route('search'), { q })).data;
            for (const resource in data) {
                // Add the resource name (e.g. 'loads') to each item, to make generating links to them easier
                data[resource] = data[resource].map(item => ({ ...item, resource }));
            }
            results.value = data;
        } else {
            results.value = {};
        }
    }, 500)
);

watch(selected, item => {
    router.visit(route(`${item.resource}.show`, item.id));
    query.value = '';
});

const noResults = computed(() => Object.values(results.value).every(items => !items.length));
</script>
