<template>
    <div class="flex flex-col gap-8">
        <div class="flex gap-8">
            <div>{{ $t('Stop') }} {{ stop.order }}</div>
            <AppRadioGroup
                name="type"
                label=""
                :scope="stop.id"
                :model-value="stop.type"
                disabled
                inline
                :options="[
                    { value: 'pickup', label: 'Pickup' },
                    { value: 'dropoff', label: 'Dropoff' }
                ]"
                class="col-span-2 my-auto" />
        </div>
        <div class="grid grid-cols-3 gap-8">
            <div>
                <div class="flex flex-col gap-4">
                    <div>
                        <AppDisplayField
                            :id="getId('tender-*-stop-*-location', [tender, stop])"
                            name="location_name"
                            :value="stop.location.name"
                            :label="$t('Stop Location')" />
                        <Link
                            :href="route('locations.show', stop.location.id)"
                            class="group mt-1 inline-flex items-center gap-2 text-sm underline transition hover:text-iel-gray/90">
                            {{ $t('View') }} {{ stop.location.name }}
                            <AppIcon
                                name="far fa-arrow-right"
                                class="h-3 w-3 transition group-hover:translate-x-2" />
                        </Link>
                    </div>
                    <AppDisplayField
                        :label="$t('Stop Address')"
                        :value="fullAddress"
                        class="col-span-4"
                        :error="!stop.location.address.validated_at && 'Invalid address'" />
                </div>
                <div class="mt-8">
                    <AppHeading>{{ $t('References') }}</AppHeading>
                    <div
                        v-for="(reference, i) in form.references"
                        :id="`tender-${tender.id}-stop-${stop.id}-references`"
                        :key="i"
                        class="flex items-end gap-2">
                        <div class="flex-1">
                            <AppDisplayField
                                :value="reference.number"
                                name="reference_number"
                                :label="$t('Number')" />
                        </div>
                        <div class="flex-1">
                            <AppDisplayField
                                :id="`tender-${tender.id}-stop-${stop.id}-reference-description-${i}`"
                                :value="reference.description"
                                name="reference_description"
                                :label="$t('Description')" />
                        </div>
                        <div
                            v-if="reference.description === 'Other'"
                            class="flex-1">
                            <AppDisplayField
                                :id="`tender-${tender.id}-stop-${stop.id}-other-reference-description-${i}`"
                                :value="reference.other"
                                name="reference_description_other"
                                :label="$t('Other')" />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="grid grid-cols-2 gap-4">
                    <div class="flex items-end gap-2">
                        <AppDisplayField
                            :id="`tender-${tender.id}-stop-${stop.id}-weight`"
                            :value="stop.weight"
                            name="weight"
                            :label="$t('Weight')"
                            class="w-1/3" />
                        <AppDisplayField
                            :id="`tender-${tender.id}-stop-${stop.id}-weight-unit`"
                            :value="stop.weight_unit"
                            name="weight_unit"
                            :label="$t('Unit')"
                            class="w-2/3" />
                    </div>
                    <div class="flex items-end gap-2">
                        <AppDisplayField
                            :id="`tender-${tender.id}-stop-${stop.id}-volume`"
                            :value="stop.volume"
                            name="volume"
                            :label="$t('Volume')"
                            class="w-1/3" />
                        <AppDisplayField
                            :id="`tender-${tender.id}-stop-${stop.id}-volume-unit`"
                            :value="stop.volume_unit"
                            name="volume_unit"
                            :label="$t('Unit')"
                            class="w-2/3" />
                    </div>
                    <div class="flex items-end gap-2">
                        <AppDisplayField
                            :id="`tender-${tender.id}-stop-${stop.id}-pieces`"
                            :value="stop.pieces"
                            name="pieces"
                            :label="$t('Weight')"
                            class="w-1/3" />
                        <AppDisplayField
                            :id="`tender-${tender.id}-stop-${stop.id}-pieces-unit`"
                            :value="stop.pieces_unit"
                            name="pieces_unit"
                            :label="$t('Unit')"
                            class="w-2/3" />
                    </div>
                    <div>
                        <AppDisplayField
                            :id="`tender-${tender.id}-stop-${stop.id}-pallets`"
                            :value="stop.pallets"
                            name="pallets"
                            :label="$t('Pallets')" />
                    </div>
                    <div>
                        <AppDisplayField
                            :id="`tender-${tender.id}-stop-${stop.id}-pallets`"
                            :value="stop.appointment_number"
                            name="appointment_number"
                            :label="$t('Appointment #')" />
                    </div>
                    <div>
                        <AppDisplayField
                            :label="$t('Next Stop (Miles)')"
                            :value="stop.miles_to_next_stop?.toLocaleString()"
                            :error="milesToNextStopError"
                            class="col-span-2" />
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-2">
                <AppHeading>
                    {{ $t('Carrier Responsible For:') }}
                </AppHeading>
                <AppRadioGroup
                    name="is_pallet_exchange"
                    :label="$t('Pallet Exchange')"
                    :scope="stop.id"
                    :model-value="stop.is_pallet_exchange"
                    disabled
                    :options="[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                    ]"
                    inline />
                <AppRadioGroup
                    name="has_unloading_charges"
                    :label="$t('Unloading Charges')"
                    :scope="stop.id"
                    :model-value="Number(stop.has_unloading_charges)"
                    disabled
                    :options="[
                        { value: 1, label: 'Yes' },
                        { value: 0, label: 'No' }
                    ]"
                    inline />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { getId } from '@/helpers';

const { tender, stop } = defineProps({
    tender: Object,
    stop: Object
});

const milesToNextStopError = computed(() => {
    if (stop.miles_to_next_stop) return null;
    const { address } = stop.location;
    const stops = tender.stops;
    if (stops[stops.length - 1].id === stop.id) {
        return address.validated_at ? null : 'We are unable to calculate because this stop has an invalid address.';
    } else {
        return `We are unable to calculate because ${
            address.validated_at ? 'one of the other stops' : 'this stop'
        } has an invalid address.`;
    }
});

const fullAddress = computed(() => {
    const { address } = stop.location;
    const address2 = address.address_2 ? `, ${address.address_2}` : '';
    return `${address.address_1}${address2}, ${address.city}, ${address.region} ${address.postal_code}`;
});

const form = useForm({
    references: stop.references?.length > 0 ? stop.references : [{ number: null, description: null, other: null }]
});

provide('form', form);
</script>
