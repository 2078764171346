<template>
    <AppHeading size="md">{{ $t('Documents') }}</AppHeading>

    <table
        :id="getId('customer-edi-*-document-types-list', [customer])"
        class="w-full whitespace-nowrap text-left">
        <thead>
            <tr>
                <th class="pb-4">{{ $t('Type') }}</th>
                <th class="pb-4">{{ $t('Option') }}</th>
                <th class="pb-4"></th>
            </tr>
        </thead>
        <tbody>
            <template
                v-for="(value, key) in dropdownDocumentOptions"
                :key="key">
                <tr class="border-t border-iel-gray">
                    <td class="py-2">{{ startCase(key) }}</td>
                    <td class="py-2">
                        <AppSelect
                            :model-value="value"
                            :options="options"
                            :name="key"
                            label=""
                            permission="update_edi_details"
                            class="w-full capitalize"
                            @update:model-value="newValue => updateDocumentType(key, newValue)" />
                    </td>
                    <td
                        v-if="hasDropdown(key, value)"
                        class="relative py-2">
                        <button
                            permission="update_edi_details"
                            @click.prevent="toggleDropdown(key)">
                            <AppIcon
                                name="far fa-caret-up"
                                class="relative -right-4 h-5 w-5 transition"
                                :class="{ 'rotate-180 transition': openDropdowns[key] }" />
                        </button>
                    </td>
                    <td v-else></td>
                </tr>

                <tr v-if="hasDropdown(key, value) && openDropdowns[key]">
                    <td
                        colspan="3"
                        class="py-2 pl-8">
                        <div class="space-y-2">
                            <div v-if="key === '990_load_tender_response'">
                                <AppCheckbox
                                    name="990_auto_accept_change"
                                    :label="$t('990 Auto Accept Change')"
                                    class="py-2"
                                    permission="update_edi_details"
                                    :model-value="allDocumentTypes['990_auto_accept_change']"
                                    @update:model-value="
                                        checked => updateCheckboxValue('990_auto_accept_change', checked)
                                    " />
                            </div>

                            <div v-if="key === '214_load_status_update'">
                                <AppCheckbox
                                    name="214_auto_in_transit"
                                    :label="$t('214 Auto In Transit')"
                                    class="py-2"
                                    permission="update_edi_details"
                                    :model-value="allDocumentTypes['214_auto_in_transit']"
                                    @update:model-value="
                                        checked => updateCheckboxValue('214_auto_in_transit', checked)
                                    " />

                                <AppCheckbox
                                    name="214_appointment_status"
                                    :label="$t('214 Appointment Status')"
                                    class="py-2"
                                    permission="update_edi_details"
                                    :model-value="allDocumentTypes['214_appointment_status']"
                                    @update:model-value="
                                        checked => updateCheckboxValue('214_appointment_status', checked)
                                    " />
                            </div>

                            <div v-if="key === '210_invoice'">
                                <AppCheckbox
                                    name="210_send_docs"
                                    :label="$t('210 Invoice')"
                                    class="py-2"
                                    permission="update_edi_details"
                                    :model-value="allDocumentTypes['210_send_docs']"
                                    @update:model-value="checked => updateCheckboxValue('210_send_docs', checked)" />
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script setup>
import { startCase } from 'lodash';
import { getId } from '@/helpers';
import { router } from '@inertiajs/vue3';

const { customer, options } = defineProps({
    customer: {
        type: Object,
        required: true
    },
    options: {
        type: Object,
        required: true
    }
});

const dropdownDocumentTypes = ['990_load_tender_response', '214_load_status_update', '210_invoice'];
const dropdownCheckboxMap = {
    '990_load_tender_response': ['990_auto_accept_change'],
    '214_load_status_update': ['214_auto_in_transit', '214_appointment_status'],
    '210_invoice': ['210_send_docs']
};
const allDocumentTypes = ref({ ...customer.edi_document_types });

const openDropdowns = reactive(
    Object.entries(customer.edi_document_types)
        .filter(([key, value]) => dropdownDocumentTypes.includes(key) && value !== 'disabled')
        .reduce((acc, [key]) => {
            acc[key] = true;
            return acc;
        }, {})
);

const dropdownDocumentOptions = computed(() =>
    // The empty spot with comma [, value] intentionally skips the first item (key)
    // Don't remove the comma - it's required syntax for proper destructuring
    Object.fromEntries(Object.entries(allDocumentTypes.value).filter(([, value]) => typeof value !== 'boolean'))
);

const hasDropdown = (key, value) => dropdownDocumentTypes.includes(key) && value !== 'disabled';
const toggleDropdown = key => {
    openDropdowns[key] = !openDropdowns[key];
};

const saveChanges = () => {
    const documentTypesToSave = { ...allDocumentTypes.value };

    router.patch(
        route('customers.update', customer),
        { edi_document_types: documentTypesToSave },
        {
            preserveScroll: true,
            preserveState: true
        }
    );
};

const updateDocumentType = (key, newValue) => {
    allDocumentTypes.value[key] = newValue;

    openDropdowns[key] = newValue !== 'disabled' && hasDropdown(key, newValue);

    if (newValue === 'disabled' && dropdownCheckboxMap[key]) {
        dropdownCheckboxMap[key].forEach(checkboxKey => {
            allDocumentTypes.value[checkboxKey] = false;
        });
    }

    saveChanges();
};

const updateCheckboxValue = (key, checked) => {
    allDocumentTypes.value[key] = checked === true;
    saveChanges();
};
</script>
