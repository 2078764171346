<template>
    <AppDropdown
        :id="getId('load-*-action-menu', [load])"
        align="bottom-right"
        :check-macropoint="true"
        :load="load">
        <template #trigger>
            <button
                :id="getId('load-*-action-menu-trigger', [load])"
                class="flex"
                type="button"
                aria-label="dropdown menu to select load action options">
                <AppIcon
                    name="far fa-ellipsis-vertical"
                    class="cursor-pointer text-white" />
            </button>
        </template>
        <template #content>
            <LoadActionMenuContent
                :load="load"
                :options="options" />
        </template>
    </AppDropdown>
</template>

<script setup>
import { getId } from '@/helpers';

const { load, options } = defineProps({
    load: {
        type: Object,
        required: true
    },
    options: {
        type: Object,
        default: () => ({})
    }
});
</script>
