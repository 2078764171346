<template>
    <a
        :href="route('loads.show', load.id)"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
        target="_blank">
        {{ $t('Open In New Tab') }}
    </a>
    <button
        :id="`load-card-${load.id}-actions-create-checkcall-button`"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
        :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
        type="button"
        :disabled="selectionDisabled"
        @click="createCheckcall">
        {{ $t('Create Checkcall') }}
    </button>
    <button
        :id="`load-card-${load.id}-actions-create-note-button`"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
        type="button"
        @click="createNote">
        {{ $t('Create Note') }}
    </button>
    <button
        :id="`load-card-${load.id}-actions-create-task-button`"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
        :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
        type="button"
        :disabled="selectionDisabled"
        @click="createTask">
        {{ $t('Create Task') }}
    </button>
    <LoadMacropointActions :load="load" />
    <button
        :id="`load-card-${load.id}-actions-send-dispatch-sheet-button`"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
        :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
        type="button"
        :disabled="selectionDisabled"
        @click="sendDispatchSheet">
        {{ $t('Send Dispatch Sheet') }}
    </button>
    <button
        :id="`load-card-${load.id}-actions-create-flag-button`"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
        :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
        type="button"
        :disabled="selectionDisabled"
        @click="createFlag">
        {{ $t('Create Flag') }}
    </button>
    <button
        v-if="!load.is_tonu && !load.is_canceled"
        :id="`load-card-${load.id}-actions-create-tonu-button`"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
        :class="{
            'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled || !can('cancel_or_tonu_load')
        }"
        variant="invisible"
        type="button"
        permission="cancel_or_tonu_load"
        :disabled="selectionDisabled || !can('cancel_or_tonu_load')"
        @click="createTonu">
        {{ $t('Cancel/TONU') }}
    </button>
    <button
        v-if="load.is_tonu && !load.is_canceled"
        :id="`load-card-${load.id}-actions-edit-tonu-button`"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
        variant="invisible"
        type="button"
        :disabled="!can('cancel_or_tonu_load')"
        @click="editTonu">
        {{ $t('Edit TONU') }}
    </button>
    <button
        v-if="load.is_tonu || load.is_canceled"
        :id="`load-card-${load.id}-actions-uncancel-untonu-button`"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
        variant="invisible"
        type="button"
        @click="reverseCancelOrTonu">
        {{ $t('Reverse Cancel/TONU') }}
    </button>
    <button
        v-if="load.carrier"
        :id="`load-card-${load.id}-actions-issue-comcheck-button`"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
        :class="{
            'cursor-not-allowed bg-iel-light-gray text-iel-gray':
                selectionDisabled || !can('issue_comcheck') || load.route_completed
        }"
        variant="invisible"
        type="button"
        :disabled="selectionDisabled || !can('issue_comcheck') || load.route_completed"
        @click="issueComcheck">
        {{ $t('Issue Comcheck') }}
    </button>
    <button
        :id="`load-card-${load.id}-actions-copy-load-button`"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
        type="button"
        @click="copyLoad">
        {{ $t('Copy Load') }}
    </button>
</template>

<script setup>
import { router, useForm } from '@inertiajs/vue3';
import LoadMacropointActions from '@/Pages/Loads/Partials/LoadMacropointActions.vue';

const { load, options } = defineProps({
    load: {
        type: Object,
        required: true
    },
    options: {
        type: Object,
        default: () => ({})
    }
});

const { open: openLoadComcheckModal } = useModal('LoadComcheckModal');
const { open: openLoadCheckcallModal } = useModal('LoadCheckcallModal');
const { open: openLoadTaskModal } = useModal('LoadTaskModal');
const { open: openLoadDispatchSheetSendModal } = useModal('LoadDispatchSheetSendModal');
const { open: openLoadFlagCreateModal } = useModal('LoadFlagCreateModal');
const { open: openLoadTonuModal } = useModal('LoadTonuModal');
const { open: openLoadCopyModal } = useModal('LoadCopyModal');

const { can } = useAuth();

function createCheckcall() {
    openLoadCheckcallModal({
        mode: 'create',
        load: load,
        defaults: { event: 'En Route' }
    });
}

function createNote() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'load',
        models: { load: load },
        primarySelectable: true
    });
}

function createTask() {
    openLoadTaskModal({
        mode: 'create',
        load: load
    });
}

function createFlag() {
    openLoadFlagCreateModal({
        load: load,
        options: options
    });
}

function sendDispatchSheet() {
    openLoadDispatchSheetSendModal({
        mode: 'create',
        load: load
    });
}

function issueComcheck() {
    openLoadComcheckModal({
        mode: 'create',
        load: load
    });
}

function createTonu() {
    openLoadTonuModal({
        mode: 'create',
        load: load
    });
}

function editTonu() {
    openLoadTonuModal({
        mode: 'edit',
        load: load,
        title: 'Edit TONU'
    });
}

function copyLoad() {
    openLoadCopyModal({
        load: load
    });
}

const form = useForm({
    is_canceled: false,
    is_tonu: false
});

provide('form', form);

function reverseCancelOrTonu() {
    form.delete(route('loads.cancel.destroy', load), {
        preserveState: false,
        preserveScroll: true,
        onSuccess: () => {
            router.reload({ only: ['load'] });
        }
    });
}

const selectionDisabled = computed(() => {
    if (load.is_tonu || load.is_canceled) {
        return true;
    }
});
</script>
