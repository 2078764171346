<template>
    <div class="my-4 flex justify-between">
        <AppHeading
            :id="getId('load-*-billing-grand-total-heading', [load])"
            size="xl">
            {{ $t('Total') }}
        </AppHeading>
        <AppHeading
            :id="getId('load-*-billing-grand-total-amount', [load])"
            size="xl">
            {{ load.grand_customer_total }}
        </AppHeading>
    </div>
</template>

<script setup>
import { getId } from '@/helpers.js';
import AppHeading from '@/Components/AppHeading.vue';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});
</script>
