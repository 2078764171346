<template>
    <div :class="customerRequests ? 'grid grid-cols-3 gap-2' : 'grid grid-cols-1 gap-2'">
        <CustomerHeader
            :request="request"
            :class="customerRequests ? 'col-span-3 -mx-2 -mt-2' : 'col-span-2 -mx-2 -mt-2'"
            @success="$emit('success')" />
        <div
            :class="
                request.type === 'Customer Activation' ||
                request.type === 'Customer Insurance' ||
                request.type === 'Customer Contract'
                    ? 'col-span-3 grid grid-cols-3 gap-2 divide-x'
                    : 'col-span-2 grid grid-cols-3 gap-2 divide-x'
            ">
            <div>
                <CustomerSection
                    :request="request"
                    @success="$emit('success')" />
            </div>
            <div
                v-if="customerRequests"
                class="pl-2">
                <RequestsDocs
                    :request="request"
                    :permissions="{
                        upload: 'upload_customer_activation_request_docs',
                        edit: 'update_customer_activation_request_docs',
                        delete: 'delete_customer_activation_request_docs'
                    }"
                    @success="$emit('success')" />
            </div>
            <div :class="customerRequests ? 'pl-2' : 'col-span-2 pl-2'">
                <RequestNotes
                    :request="request"
                    :permissions="{ create: createNotePermission }"
                    @success="$emit('success')" />
            </div>
        </div>
        <div
            v-if="request.type === 'Credit Increase'"
            class="col-span-2 pl-2">
            <CustomerCreditSection
                :key="request.completed_at"
                :request="request"
                @success="$emit('success')" />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    request: Object
});

const createNotePermission = computed(() => {
    switch (props.request.type) {
        case 'Customer Activation':
            return 'create_customer_activation_request_note';
        case 'Credit Increase':
            return 'create_customer_credit_request_note';
        case 'Customer Contract':
            return 'create_customer_contract_request_note';
        case 'Customer Insurance':
            return 'create_customer_insurance_request_note';
        default:
            return '';
    }
});

const customerRequests = computed(() => {
    return ['Customer Activation', 'Customer Contract', 'Customer Insurance'];
});

defineEmits(['success']);
</script>
