<template>
    <Head title="Flagged Loads" />

    <div class="flex min-h-screen">
        <!-- Sidebar -->
        <AppSidebar
            v-bind="{ isOpen: isMobileMenuOpen }"
            @close="isMobileMenuOpen = false" />

        <!-- Main -->
        <div class="flex max-h-screen flex-1 flex-col gap-2 overflow-hidden bg-iel-silver">
            <AppTopbar @open-mobile-menu="isMobileMenuOpen = true" />
            <AppHeader
                title="Flagged Loads"
                :icon-component="IconFlagged"
                class="mx-2">
                <template #search>
                    <AppSearchInput
                        id="flagged-loads-list-search"
                        v-model="q"
                        placeholder="PO#, Customer/Carrier" />
                </template>
                <template #actions>
                    <AppFilterPills
                        id="flagged-loads-list-filters"
                        class="lg:ml-auto"
                        :filters="{
                            default: { label: 'All' },
                            me: { label: 'Assigned to Me' },
                            risks: { label: 'All Risks', icon: 'fas fa-shield-exclamation' },
                            claims: { label: 'Claims', icon: 'fas fa-gavel' },
                            incidents: { label: 'Carrier Relations', icon: 'fas fa-handshake' }
                        }"
                        :link-params="{ only: ['flags'] }"
                        :reset-pagination="true" />
                </template>
            </AppHeader>
            <slot />
            <AppFeedback />
        </div>
    </div>

    <AppModalsCollection />
</template>

<script setup>
import { isMobileMenuOpen } from '@/stores';
import IconFlagged from '/resources/img/icon-flagged.svg';

const { q } = useQueryParam({ only: ['flags', 'filter'] });
</script>
