<template>
    <div
        class="relative w-full overflow-hidden"
        :class="classes?.container">
        <AppHeading
            v-if="title"
            class="w-full rounded-t-lg bg-iel-blue p-4 text-white"
            size="lg">
            {{ title }}
        </AppHeading>
        <div class="overflow-x-auto">
            <table
                class="relative w-full min-w-max bg-white"
                :class="classes?.table">
                <thead
                    class="z-10 text-sm"
                    :class="[{ 'sticky top-0 bg-iel-silver': stickyHeader }, headerClass]">
                    <tr>
                        <th
                            v-for="column in columns"
                            :key="column.name"
                            class="p-2 text-left first:pl-4 last:pr-4"
                            :class="titleClass">
                            <button
                                v-if="column.sortable"
                                type="button"
                                class="group inline-flex"
                                @click="sort(column.name)">
                                {{ column.label }}
                                <span
                                    class="ml-2 flex-none rounded bg-white text-iel-darkest-gray group-hover:bg-iel-dark-gray group-focus:bg-iel-dark-gray"
                                    :class="{ 'invisible group-focus:visible': !isActive(column.name) }">
                                    <svg
                                        class="h-5 w-5"
                                        :class="{
                                            'invisible flex-none rounded text-iel-gray group-hover:visible group-focus:visible':
                                                !isActive(column.name)
                                        }"
                                        viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            v-show="showArrow(column.name, 'desc')"
                                            fill-rule="evenodd"
                                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                            clip-rule="evenodd"></path>
                                        <path
                                            v-show="showArrow(column.name, 'asc')"
                                            fill-rule="evenodd"
                                            d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </span>
                            </button>
                            <span v-else>{{ column.label }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="!data.length">
                        <td
                            :colspan="columns.length"
                            class="rounded-lg bg-white/75 px-4 py-2 italic text-iel-gray">
                            {{ $t('No data') }}
                        </td>
                    </tr>
                    <tr
                        v-for="(row, index) in data"
                        v-else
                        :key="row.id"
                        class="rounded-lg border-b border-iel-light-gray last:border-b-0"
                        :class="[classes?.row, index < data.length - 1 ? 'mb-2' : '']"
                        @click="$emit('row-click', row)">
                        <td
                            v-for="column in columns"
                            :id="`row-${row.id}-${column.name}-${row[column.name]}`"
                            :key="column.name"
                            class="bg-white p-3 text-sm first:pl-4 last:pr-4 md:text-base">
                            <slot
                                :name="`${column.name}-cell`"
                                :row="row">
                                {{ row[column.name] ?? '' }}
                            </slot>
                        </td>
                        <td
                            v-if="$slots.actions"
                            :id="`row-${row.id}-actions`"
                            class="bg-white p-2 text-sm first:pl-4 last:pr-4 md:text-base">
                            <slot
                                name="actions"
                                v-bind="{ ...row }" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <slot name="multiSelect" />
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';

const { columns, data, dataKey, classes, stickyHeader, theme } = defineProps({
    columns: {
        type: Array,
        required: true
    },
    data: {
        type: Array,
        required: true
    },
    dataKey: {
        type: String,
        required: false
    },
    classes: {
        type: Object,
        default: () => ({
            row: '',
            table: '',
            container: ''
        })
    },
    stickyHeader: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        required: false
    },
    theme: {
        type: String,
        default: 'light_gray',
        validator: value => ['blue', 'gray', 'light_gray'].includes(value)
    }
});

defineEmits(['row-click']);

const params = new URLSearchParams(location.search);

const headerClass = computed(
    () =>
        ({
            blue: 'bg-iel-blue',
            gray: 'bg-iel-dark-gray',
            light_gray: 'border border-iel-mercury'
        })[theme]
);

const titleClass = computed(
    () =>
        ({
            blue: 'text-white',
            gray: 'text-white',
            light_gray: 'text-iel-dark-gray'
        })[theme]
);

function isActive(column) {
    return params.get('orderBy') === column;
}

function showArrow(column, direction) {
    if (isActive(column)) {
        return params.get('orderByDir') === direction;
    } else {
        return direction === 'desc';
    }
}

function sort(column) {
    if (isActive(column) && params.get('orderByDir') === 'asc') {
        params.delete('orderBy');
        params.delete('orderByDir');
    } else if (isActive(column)) {
        params.set('orderByDir', 'asc');
    } else {
        params.set('orderBy', column);
        params.set('orderByDir', 'desc');
    }

    const url = `${window.location.pathname}?${params.toString()}`;

    if (!dataKey) {
        window.location = url;
    }

    router.visit(url, { only: [dataKey] });
}
</script>
