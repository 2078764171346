<template>
    <div class="grid grid-cols-12 items-center">
        <AppHeading
            size="sm"
            class="col-span-4">
            {{ $t('Bitfreighter ID') }}
        </AppHeading>

        <form
            :id="getId('customer-*-edi-vendor-id-form', [customer])"
            class="col-span-8"
            @submit.prevent>
            <div class="relative">
                <input
                    type="text"
                    :id="getId('customer-*-edi-vendor-id', [customer])"
                    v-model="form.entity_id"
                    class="block w-full rounded border-iel-gray/40 px-2 py-1 placeholder:text-iel-gray sm:text-sm"
                    :placeholder="$t('Enter Bitfreighter ID')"
                    @blur="handleBlur" />
                <AppRequestStatus
                    :processing="form.processing"
                    :recently-successful="form.recentlySuccessful" />
            </div>
            <AppErrorMessage
                name="customer-edi-vendor-id"
                :error="error" />
        </form>
    </div>
</template>

<script setup>
import { getId } from '@/helpers';
import { router, useForm } from '@inertiajs/vue3';

const { customer } = defineProps({
    customer: {
        type: Object,
        required: true
    }
});
const ediIntegration = customer.integrations.find(
    integration => integration.vendor === 'Bitfreighter' && integration.type === 'edi'
);

const form = useForm({
    entity_id: ediIntegration?.entity_id || ''
});

const error = ref(null);

// Handle blur event
function handleBlur() {
    error.value = null;
    form.recentlySuccessful = false;
    if (form.isDirty) {
        form.processing = true;
        if (!form.entity_id) {
            if (ediIntegration) {
                router.delete(route('integrations.destroy', [ediIntegration]), {
                    preserveScroll: true,
                    onSuccess: () => {
                        form.recentlySuccessful = true;
                        form.processing = false;
                    }
                });
            }
        } else {
            router.put(
                route('customers.integrations.update', [customer]),
                {
                    vendor: 'Bitfreighter',
                    type: 'edi',
                    entity_id: form.entity_id
                },
                {
                    onSuccess: () => {
                        form.recentlySuccessful = true;
                        form.processing = false;
                    },
                    onError: e => {
                        error.value = e.entity_id;
                        form.processing = false;
                    },
                    preserveScroll: true
                }
            );
        }
        form.defaults();
    }
}
</script>
