<template>
    <div class="grid grid-cols-12 items-center">
        <AppHeading
            size="sm"
            class="col-span-4">
            {{ $t('EDI Setup Request Date') }}
        </AppHeading>

        <form
            :id="getId('customer-*-edi-vendor-id-form', [customer])"
            class="col-span-8"
            @submit.prevent="submit">
            <AppDate
                :id="getId('customer-*-edi-setup-request-date', [customer])"
                v-model="form.edi_request_submitted_at"
                name="edi_request_submitted_at"
                label=""
                class="w-full"
                permission="update_edi_details"
                :url="route('customers.update', [customer])" />
        </form>
    </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { getId } from '@/helpers';

const { customer } = defineProps({
    customer: {
        type: Object,
        required: true
    }
});

const form = useForm({
    edi_request_submitted_at: customer.edi_request_submitted_at
});

provide('form', form);
</script>
