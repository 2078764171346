<template>
    <div>
        <div class="flex justify-end pr-1">
            <button
                v-if="regular"
                :id="`load-card-${load.id}-actions-create-note-button-secondary`"
                class="mb-2 inline-block rounded bg-iel-blue px-2 py-0.5 text-left text-xs font-semibold leading-5 text-iel-light-gray transition hover:bg-iel-light-gray hover:text-iel-blue focus:bg-iel-light-gray focus:text-iel-blue focus:outline-none"
                type="button"
                @click="createNote">
                <AppIcon
                    name="fal fa-pen"
                    class="mr-1 text-sm" />
                {{ $t('Create Note') }}
            </button>
        </div>
        <ul
            v-if="load.notes.length > 0"
            role="list"
            class="px-0.5">
            <li
                v-if="primaryNote"
                :id="`note-${primaryNote.id}`"
                :key="primaryNoteKey"
                class="mb-2.5">
                <AppMessage
                    :user="primaryNote?.user?.name"
                    :title="primaryNote.title"
                    :body="primaryNote.content"
                    :created-at="primaryNote.created_at"
                    :icon="primaryNote.icon">
                    <AppButton
                        variant="outline"
                        icon="fas fa-star"
                        @click="setPrimaryNote(primaryNote)" />
                </AppMessage>
            </li>
            <li
                v-for="note in secondaryNotes"
                :id="`note-${note.id}`"
                :key="note.id"
                class="mb-2.5">
                <AppMessage
                    :user="note?.user?.name"
                    :title="note.title"
                    :body="note.content"
                    :created-at="note.created_at"
                    :icon="note.icon">
                    <AppButton
                        variant="outline"
                        icon="far fa-star"
                        @click="setPrimaryNote(note)" />
                </AppMessage>
            </li>
        </ul>
        <div
            v-else
            class="mb-4 text-center">
            {{ $t('No notes found on this load.') }}
        </div>
    </div>
</template>

<script setup>
import { loadboard } from '@/stores';
import { router } from '@inertiajs/vue3';
import axios from 'axios';

const { load } = defineProps({
    load: Object
});

const primaryNoteKey = ref(0);
const regular = computed(() => loadboard.value.layout === 'regular');

function createNote() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'load',
        models: { load: load },
        primarySelectable: true
    });
}

const noteTypes = {
    claim: { title: 'Claim', icon: 'fas fa-gavel' },
    carrier_relations: { title: 'Carrier Relations', icon: 'fas fa-handshake' },
    task_snooze: { icon: 'fas fa-alarm-snooze' }
};

const notes = computed(() =>
    load.notes.map(note => {
        const noteType = noteTypes[note.type] ?? {};

        return {
            ...note,
            icon: note.type ? noteType.icon : null,
            title: note.title ? note.title : noteType.title
        };
    })
);

const primaryNote = computed(() => (notes.value.length ? notes.value.find(n => n.is_primary) || null : null));

const secondaryNotes = computed(() => (notes.value.length ? notes.value.filter(n => !n.is_primary) : null));

async function setPrimaryNote(note) {
    await axios
        .put(
            route('notes.update', note.id),
            {
                content: note.content,
                is_primary: !note.is_primary
            },
            {
                headers: {
                    'X-Inertia': false
                }
            }
        )
        .then(() => {
            refreshNotes();
        });
}

function refreshNotes() {
    router.reload({
        preserveScroll: true,
        onSuccess: () => {
            primaryNoteKey.value++;
        }
    });
}
</script>
