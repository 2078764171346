<template>
    <p
        v-if="error"
        :id="`${name}-error`"
        class="text-sm text-iel-red"
        data-test="error">
        {{ error }}
    </p>
</template>

<script setup>
defineProps({
    error: [String, Boolean],
    name: String
});
</script>
