<template>
    <AppModal
        id="tonu-modal"
        :show="isOpen"
        :title="context.title ?? 'Cancel or TONU'"
        class="max-w-md"
        @close="handleClose"
        @closed="handleModalClose">
        <div
            v-if="newLoadMessage"
            class="grid gap-2">
            <div class="grid gap-2">
                <p class="text-md font-bold">
                    {{ newLoadMessage }}
                </p>
            </div>
            <div class="flex justify-end">
                <AppButton @click="handleClose">
                    {{ $t('Close') }}
                </AppButton>
            </div>
        </div>

        <form
            v-else
            class="contents"
            @submit.prevent="onConfirm">
            <AppSelect
                id="tonu-reason"
                v-model="form.tonu_reason"
                label="Reason"
                name="tonu-reason"
                :error="form.errors.tonu_reason"
                required
                :options="tonuCancelReasons" />
            <p
                v-if="!meetsBillingRequirements && form.customer_bill_amount > 0"
                class="text-sm text-iel-red">
                Please upload TONU approval paperwork ASAP to invoice the customer.
            </p>
            <p
                v-if="!form.customer_bill_amount > 0 && !form.carrier_pay_amount > 0 && context.mode === 'edit'"
                class="text-sm text-iel-red">
                If cancelling load without a dollar amount, please reverse this TONU first, then cancel load.
            </p>
            <AppInput
                id="customer-invoice-amount"
                v-model="form.customer_bill_amount"
                label="Customer Invoice Amount"
                name="tonu-invoice-amount"
                type="number"
                :min="0"
                step=".01"
                :placeholder="'0.00'"
                :error="form.errors.customer_bill_amount"
                @input="checkAmounts" />

            <div>
                <AppInput
                    id="carrier-pay-amount"
                    v-model="form.carrier_pay_amount"
                    label="Carrier Pay Amount"
                    name="carrier-pay-amount"
                    type="number"
                    :min="0"
                    step=".01"
                    :placeholder="'0.00'"
                    :error="form.errors.carrier_pay_amount"
                    :disabled="!context.load?.carrier"
                    @input="checkAmounts" />
                <p
                    v-if="!context.load?.carrier"
                    class="mt-1 text-sm text-iel-red">
                    There is no carrier assigned to this load.
                </p>
            </div>
            <AppInput
                v-if="form.carrier_pay_amount > 0"
                id="tonu-ratecon-email"
                v-model="form.tonu_ratecon_email"
                label="Email for RateCon"
                name="tonu-ratecon-email"
                :error="form.errors.tonu_ratecon_email" />
            <p
                v-if="meetsBillingRequirements && form.customer_bill_amount > 0"
                class="text-iel-dark-green">
                Please note, completing this action will invoice the customer immediately.
            </p>
            <AppCheckbox
                v-if="(form.customer_bill_amount > 0 || form.carrier_pay_amount > 0) && context.mode !== 'edit'"
                id="is-copy"
                v-model="form.is_copy"
                class="pt-1"
                label="Copy Load?"
                name="is-copy" />
            <div
                v-if="form.tonu_reason"
                class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="handleClose">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    v-if="context.mode === 'edit'"
                    id="save-tonu"
                    type="submit">
                    {{ $t('Submit TONU Edit') }}
                </AppButton>
                <AppButton
                    v-else
                    id="save-tonu"
                    type="submit">
                    {{
                        form.tonu_reason && !form.customer_bill_amount && !form.carrier_pay_amount
                            ? $t('Submit Load Cancel')
                            : $t('Submit TONU')
                    }}
                </AppButton>
            </div>
        </form>
        <AppErrorMessage
            v-if="form.errors.update"
            :error="form.errors.update"
            name="tonu-form-errors" />
    </AppModal>
</template>

<script setup>
import { usePage, useForm } from '@inertiajs/vue3';
import { tonuCancelReasons } from '@/data.js';
import { find } from 'lodash-es';
import { router } from '@inertiajs/vue3';
import { useModal } from '@/Composables/useModal';

const page = usePage();

const { isOpen, close, onClosed, context } = useModal('LoadTonuModal');

const form = useForm({
    cancel_reason: null,
    is_canceled: false,
    is_tonu: false,
    tonu_reason: null,
    customer_bill_amount: null,
    is_copy: false,
    carrier_pay_amount: null,
    tonu_ratecon_email: null
});

provide('form', form);

watch(isOpen, () => {
    form.reset();
    form.clearErrors();

    form.cancel_reason = null;
    form.is_canceled = false;
    form.is_tonu = false;
    form.tonu_reason = null;
    form.customer_bill_amount = null;
    form.is_copy = false;
    form.carrier_pay_amount = null;
    form.tonu_ratecon_email = null;

    if (context.value?.mode === 'edit') {
        form.tonu_reason = context.value?.load?.tonu_reason;
        form.customer_bill_amount =
            context.value?.load?.total_customer_billing_amount > 0
                ? context.value.load.total_customer_billing_amount
                : null;
        form.carrier_pay_amount =
            context.value?.load?.total_pay_carrier_amount > 0 ? context.value.load.total_pay_carrier_amount : null;
        form.tonu_ratecon_email = form.carrier_pay_amount > 0 ? context.value?.load?.tonu_ratecon_email : null;
    }
});

let newLoadMessage = ref(null);

function onConfirm() {
    if (form.customer_bill_amount > 0 || form.carrier_pay_amount > 0) {
        form.is_tonu = true;
    } else {
        form.is_canceled = true;
        form.cancel_reason = form.tonu_reason;
    }

    if (!form.carrier_pay_amount) {
        form.tonu_ratecon_email = null;
    }

    function sendRequest(method, url) {
        form[method](url, {
            preserveState: false,
            preserveScroll: true,
            onSuccess() {
                if (page.props.response?.message) {
                    newLoadMessage.value = page.props.response.message;
                    return;
                }
                close();
                router.reload({ only: ['load'] });
            }
        });
    }

    const method = context.value?.mode === 'edit' ? 'patch' : 'post';
    const url =
        context.value?.mode === 'edit'
            ? route('loads.cancel.update', context.value.load)
            : route('loads.cancel.store', context.value.load);

    sendRequest(method, url);
}

function handleClose() {
    close();
}

function handleModalClose() {
    newLoadMessage.value = null;
    onClosed();
}

function checkAmounts() {
    if (!form.customer_bill_amount > 0 && !form.carrier_pay_amount > 0) {
        form.is_copy = false;
        form.is_tonu = false;
    }
}

const meetsBillingRequirements = computed(
    () =>
        !context.value?.load?.customer?.required_documents?.includes('TONU Approval') ||
        find(context.value?.load?.documents, key => key?.labels?.includes('TONU Approval'))
);
</script>
