<template>
    <Head title="Feedback" />
    <AppHeader
        title="Feedback"
        :icon-component="IconFeedback"></AppHeader>
    <slot />
    <AppFeedback />
</template>

<script setup>
import IconFeedback from '/resources/img/icon-feedback.svg';

defineProps({
    feedback: Object
});
</script>
