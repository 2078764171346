<template>
    <div>
        <button
            :id="getId('load-list-*-action-menu-trigger', [load])"
            ref="triggerRef"
            class="flex size-14 items-center justify-center"
            type="button"
            aria-label="dropdown menu to select load action options"
            @click.stop="handleToggle">
            <AppIcon
                name="far fa-ellipsis"
                class="cursor-pointer text-iel-blue" />
        </button>

        <Teleport
            v-if="isOpen"
            to="body">
            <div
                :id="getId('load-list-*-action-menu', [load])"
                ref="dropdownRef"
                class="absolute z-50 min-w-56 rounded border border-iel-gray bg-white py-1 shadow-lg"
                :style="dropdownPosition">
                <LoadActionMenuContent
                    :load="load"
                    :options="options" />
            </div>
        </Teleport>
    </div>
</template>

<script setup>
import axios from 'axios';
import { getId } from '@/helpers';
import useTeleport from '@/Composables/useTeleport';

const { load, options } = defineProps({
    load: Object,
    options: Object
});

const { isOpen, triggerRef, dropdownRef, dropdownPosition, toggle } = useTeleport();

function handleToggle() {
    toggle();

    if (isOpen.value === true) {
        axios.get(route('loads.checkMacropoint', load)).then(function (response) {
            load.can.create_macropoint_order = response.data;
        });
    }
}
</script>
