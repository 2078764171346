<template>
    <TransitionRoot
        appear
        as="template"
        :show="show"
        @after-leave="$emit('closed')">
        <Dialog
            class="relative z-50"
            :open="show">
            <TransitionChild
                as="template"
                enter="duration-100 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-75 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0">
                <div
                    class="fixed inset-0 bg-black/30"
                    aria-hidden="true"
                    tabindex="-1" />
            </TransitionChild>
            <div class="fixed inset-0 w-screen overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4">
                    <TransitionChild
                        as="template"
                        enter="duration-100 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-75 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <DialogPanel
                            :id="`${$attrs.id}-panel`"
                            :class="`flex w-full flex-col gap-2 rounded-md bg-white px-4 py-3 shadow-lg ${$attrs.class}`">
                            <DialogTitle
                                v-if="title"
                                :id="`${$attrs.id}-panel-title`"
                                class="flex items-center gap-2">
                                <AppIcon
                                    v-if="icon"
                                    :name="icon"
                                    class="h-4" />
                                <span class="text-lg font-bold text-iel-blue">
                                    {{ title }}
                                </span>
                            </DialogTitle>
                            <DialogDescription
                                v-if="description"
                                :id="`${$attrs.id}-panel-description`">
                                {{ description }}
                            </DialogDescription>
                            <slot />
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle, DialogDescription } from '@headlessui/vue';

defineProps({
    show: Boolean,
    title: String,
    description: String,
    icon: String,
    autofocus: Object
});

defineEmits(['closed']);
</script>
