<template>
    <div class="flex gap-1">
        <div
            v-if="user"
            class="flex w-1/4 flex-col items-center justify-center">
            <AppAvatar
                v-bind="{ user }"
                :class="sizes[size]" />

            <p class="text-center text-xs font-semibold text-iel-blue">@{{ user }}</p>
        </div>

        <div class="w-full">
            <div class="rounded-t-md bg-iel-blue p-1 text-white md:flex md:items-center md:justify-between xl:px-2">
                <div class="flex items-center gap-2">
                    <AppIcon
                        v-if="icon"
                        :name="icon" />

                    <div
                        v-if="isText"
                        class="flex gap-1 text-xs">
                        <p>To: {{ textRecipient }}</p>

                        <p>{{ message.to }}</p>
                    </div>

                    <p
                        v-else
                        class="text-xs">
                        {{ $t(title ?? '') }}
                    </p>
                </div>

                <time
                    class="text-xs"
                    :datetime="createdAt">
                    {{ formatDate(createdAt) }}
                </time>
            </div>

            <div
                class="flex max-h-20 w-auto items-center justify-between overflow-y-auto rounded-b-md border border-iel-blue/30 bg-white p-2.5 text-sm">
                <p>{{ $t(body) }}</p>

                <slot />
            </div>
        </div>
    </div>
</template>

<script setup>
import { formatDate } from '@/helpers.js';

const sizes = {
    sm: 'h-6 w-6',
    md: 'h-8 w-8',
    lg: 'h-10 w-10'
};

const props = defineProps({
    user: String,
    title: String,
    body: String,
    createdAt: String,
    icon: String,
    size: {
        type: String,
        default: 'sm'
    },
    isText: Boolean,
    message: Object,
    load: Object,
    activeTab: String
});

const textRecipient = computed(() => {
    if (props.activeTab === 'Dispatcher') {
        return props.load?.dispatcher?.name;
    }
    if (props.activeTab === 'Driver') {
        return props.load?.driver?.name;
    }
    if (props.activeTab === 'Driver 2') {
        return props.load?.driver2?.name;
    }
    return null;
});

const { user, title, body, createdAt } = computed(() => {
    const { isText, message, user, title, body, createdAt } = props;

    return {
        user: isText ? message?.user?.name : user,
        title: isText ? '' : title,
        body: isText ? message?.body : body,
        createdAt: createdAt
    };
}).value;
</script>
