<template>
    <div class="grid grid-cols-4 items-center gap-1 border bg-iel-lightest-gray px-3 py-2 text-iel-blue">
        <div v-if="!request.completed_at && request.assignee_id === page.props.auth.user.id">
            <AppButton
                id="complete-request-button"
                icon="far fa-circle-plus"
                :disabled="form.processing"
                :permission="permission"
                @click="completeRequest">
                {{ $t('Complete Request') }}
            </AppButton>
        </div>
        <div v-if="request.type === 'Carrier Activation'">
            <form id="toggle-active-inactive-form">
                <AppToggle
                    v-model="form.is_active"
                    :label="['Active', 'Inactive']"
                    class="font-bold uppercase text-iel-blue"
                    permission="toggle_carrier"
                    @click="toggleIsActive" />
            </form>
        </div>
        <div>
            <span class="font-bold">PO #:</span>
            <a
                v-if="request.details.po_number"
                class="cursor-pointer items-center gap-2"
                variant="invisible"
                size="invisible"
                :href="route('loads.showByPO', request.details.po_number)"
                target="_blank">
                <strong>{{ request.details.po_number }}</strong>
            </a>
            <span v-else>--</span>
        </div>
        <div>
            <span class="mr-1 font-bold">{{ $t('Broker') }}:</span>
            <span>{{ request.broker.name }}</span>
        </div>
        <div>
            <span class="mr-1 font-bold">{{ $t('Manager') }}:</span>
            <span v-if="request.broker.manager_name">{{ request.broker.manager_name }}</span>
            <span v-else>--</span>
        </div>
        <div class="col-span-1">
            <span class="mr-1 font-bold">{{ $t('Location') }}:</span>
            <span v-if="request.broker.office_location">{{ request.broker.office_location }}</span>
            <span v-else>--</span>
        </div>
        <div
            v-if="request.details.status"
            class="col-span-1">
            <span class="mr-1 font-bold">{{ $t('Status') }}:</span>
            <span>{{ request.details.status }}</span>
        </div>
    </div>
</template>

<script setup>
import { useForm, usePage, router } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const page = usePage();

const props = defineProps({
    request: Object
});

const form = useForm({
    is_active: props.request.entity.is_active
});

provide('form', form);

const emit = defineEmits(['success']);

const permission = computed(() => {
    const permissions = {
        'Carrier Activation': 'update_carrier_activation_request',
        'Insurance Update': 'update_carrier_insurance_request',
        'Advance Terms Change': 'update_carrier_advance_terms_request'
    };

    return permissions[props.request.type];
});

function toggleIsActive(event) {
    if (!confirm(t("Are you sure you want to change this carrier's status?"))) event.preventDefault();
}

function completeRequest() {
    if (confirm(t('Are you sure you want to mark this request as completed?'))) {
        router.patch(
            route('tickets.update', props.request.id),
            {
                completed_at: new Date()
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    emit('success');
                }
            }
        );
    }
}
</script>
