<template>
    <div class="grid grid-cols-3 gap-2">
        <CarrierHeader
            :request="request"
            class="col-span-3 -mx-2 -mt-2"
            @success="$emit('success')" />
        <div class="col-span-3 grid grid-cols-3 gap-2 divide-x">
            <div>
                <CarrierSection :request="request" />
            </div>
            <div class="pl-2">
                <RequestsDocs
                    :request="request"
                    :permissions="{
                        upload: 'upload_carrier_documents',
                        edit: 'update_carrier_documents',
                        delete: 'delete_carrier_documents'
                    }"
                    @success="$emit('success')" />
            </div>
            <div
                v-if="request.entity.insurance"
                class="pl-2">
                <RequestsInsuranceSection :request="request" />
            </div>
            <div
                v-else
                class="pl-2">
                <AppHeading
                    size="sm"
                    class="pb-2 text-iel-gray">
                    {{ $t('Insurance') }}
                </AppHeading>
                <span class="p-4 text-center">There is no insurance on this Carrier.</span>
            </div>
        </div>
        <div class="col-span-2 pt-2">
            <RequestNotes
                :request="request"
                :permissions="{ create: createNotePermission }"
                @success="$emit('success')" />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    request: Object
});

const createNotePermission = computed(() => {
    switch (props.request.type) {
        case 'Advance Terms Change':
            return 'create_carrier_advance_terms_request_note';
    }
});

defineEmits(['success']);
</script>
