<template>
    <div v-if="!references || !references.length">-</div>
    <div
        v-else
        class="flex items-center gap-2">
        <span>
            {{ references[0].description }}
            {{ references[0].number }}
        </span>
        <AppDropdown
            v-if="references.length > 1"
            align="bottom-left">
            <template #trigger>
                <button class="whitespace-nowrap rounded-lg bg-iel-blue px-2 font-bold text-white">
                    + {{ references.length - 1 }}
                </button>
            </template>
            <template #content>
                <div class="p-4">
                    <div
                        v-for="reference in references.slice(1)"
                        :key="reference.id">
                        {{ reference.description }} {{ reference.number }}
                    </div>
                </div>
            </template>
        </AppDropdown>
    </div>
</template>

<script setup>
defineProps({
    references: Array
});
</script>
