<template>
    <AppModal
        :show="isOpen"
        :title="context.title ?? 'Create Partner Vendor'"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="save">
            <AppInput
                v-model="form.name"
                name="name"
                label="Partner Vendor Name"
                :placeholder="$t('Enter the partner vendor name')" />
            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="close(null)">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Save') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CustomerPartnerVendorModal');

const form = useForm({
    name: null
});

provide('form', form);

watch(isOpen, () => {
    form.reset();
    form.clearErrors();
    form.name = context.value.query;
});

function save() {
    form.post(route('customers.ediPartnerVendors.store', context.value.customer), {
        preserveScroll: true,
        onSuccess: () => {
            close(form.name);
        }
    });
}
</script>
