<template>
    <div
        :id="getId('load-*-subheader', [load])"
        class="flex flex-row items-center gap-2 bg-iel-dark-gray p-3 text-sm text-white lg:gap-6">
        <!-- Load Creator doesn't have a Phone # ATM -->
        <AppPhoneLink
            v-if="load.created_by"
            :id="getId('load-*-call-load-contact', [load])"
            :tooltip-text="true"
            :inline="true"
            :number="load.dispatcher?.phone_number"
            :name="load.created_by?.name"
            title="Load Contact">
            <template #text>
                <div class="pl-1">
                    <div class="flex gap-2">
                        <p class="font-bold">Load Owner:</p>
                        <p>{{ load.created_by?.name }}</p>
                    </div>
                    <div class="flex font-bold">Manager: -</div>
                    <div class="flex font-bold">Location: -</div>
                    <div class="flex gap-2">
                        <p class="font-bold">Phone:</p>
                        <p>{{ tooltipPhone() }}</p>
                    </div>
                    <div class="flex gap-2">
                        <p class="font-bold">Email:</p>
                        <p>{{ tooltipEmail() }}</p>
                    </div>
                </div>
            </template>
        </AppPhoneLink>
        <span
            v-if="load.stops.length != 0"
            class="h-6 border-l-2 border-iel-blue px-2"></span>
        <LoadCardProgressBar
            v-if="load.stops.length != 0"
            :load="load"
            class="w-1/4" />
        <div
            v-if="load.trackings != 0"
            :id="getId('load-*-current-location', [load])"
            class="flex flex-row font-bold">
            Current Location:
            <p class="pl-1 font-normal">{{ currentLocation }}</p>
        </div>
        <div
            v-if="load.trackings != 0"
            class="group flex gap-1 font-bold">
            ETA
            <div class="flex flex-row">
                <AppIcon
                    name="far fa-location-dot"
                    class="self-center pl-1" />
                <p
                    :id="getId('load-*-miles-to-next-stop', [load])"
                    class="pl-1 font-normal">
                    {{ milesToNextStop }} Miles
                </p>
            </div>
            <div class="flex flex-row pl-1">
                <AppIcon
                    name="far fa-clock"
                    class="self-center pl-1" />
                <p
                    :id="getId('load-*-latest-eta', [load])"
                    class="pl-1 font-normal">
                    {{ load?.latest_eta || 'N/A' }}
                </p>
            </div>
        </div>
        <div
            v-if="load.trackings != 0"
            class="flex flex-row">
            <p class="font-bold">Last Updated:</p>
            <p
                :id="getId('load-*-last-update', [load])"
                class="pl-1 font-normal">
                {{ lastUpdate }}
            </p>
        </div>
    </div>
</template>

<script setup>
import { getId } from '@/helpers';

const { load } = defineProps({
    load: Object
});

const currentLocation = computed(() => {
    return load.trackings.length === 0 ? '-' : load.trackings.at(0).location;
});

const milesToNextStop = computed(() => {
    return load.trackings.length === 0 ? '-' : load.trackings.at(0).miles_to_next_stop;
});

const lastUpdate = computed(() => {
    return load.trackings.length === 0 ? '-' : load.trackings.at(0).event_happened_at_delta;
});

function tooltipPhone() {
    return load.customer?.team_contact_phone ? load.customer?.team_contact_phone : '-';
}

function tooltipEmail() {
    return load.customer?.team_contact_email ? load.customer?.team_contact_email : '-';
}
</script>
