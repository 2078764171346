<template>
    <div class="flex">
        <!-- Sidebar -->
        <AppSidebar
            v-bind="{ isOpen: isMobileMenuOpen }"
            @close="isMobileMenuOpen = false" />

        <!-- Main -->
        <div class="min-h-screen flex-1 bg-iel-silver">
            <div class="w-full bg-white xl:relative">
                <AppTopbar @open-mobile-menu="isMobileMenuOpen = true" />
            </div>
            <main class="grid items-start gap-2 p-1">
                <slot />
                <AppFeedback />
            </main>
        </div>
    </div>

    <!-- Modals -->
    <AppModalsCollection />
</template>

<script setup>
import { isMobileMenuOpen } from '@/stores';
</script>
