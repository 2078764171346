<template>
    <AppHeader
        id="quotes-header"
        :title="$t('Quotes')"
        :icon-component="IconQuotes">
        <template #search>
            <AppSearchInput
                id="quotes-search"
                v-model="q"
                :placeholder="$t('Search quotes...')"
                class="w-52" />
        </template>

        <template #actions>
            <div>
                <AppButton
                    id="create-quote-button"
                    icon="far fa-circle-plus"
                    @click="openQuoteCreateModal">
                    {{ $t('Quote') }}
                </AppButton>
            </div>
            <AppLayoutToggle />
        </template>
    </AppHeader>
</template>

<script setup>
import IconQuotes from '/resources/img/icon-quotes.svg';

const { open: openQuoteCreateModal } = useModal('QuoteCreateModal');
const { q } = useQueryParam({ only: ['quotes'] });
</script>
