<template>
    <div
        :id="getId('load-*-summary-finances', [load])"
        class="-mb-4 -mr-4 -mt-4 w-96 shrink-0 rounded-md bg-iel-blue p-4">
        <div class="flex h-full flex-col gap-8 rounded-md bg-white p-4">
            <div class="flex gap-4">
                <LoadFinancesBox
                    :id="getId('load-*-summary-finances-profit', [load])"
                    label="Profit"
                    :value="!load.can.view_load_details ? '-' : data.profit" />
                <LoadFinancesBox
                    :id="getId('load-*-summary-finances-margin', [load])"
                    label="Margin"
                    :value="!load.can.view_load_details ? '-' : data.margin" />
            </div>
            <div class="flex flex-col gap-4">
                <div class="flex items-center justify-between">
                    <button
                        class="cursor-pointer text-xl font-bold underline"
                        @click="goToSetupCarrierPay">
                        Carrier Pay
                    </button>
                    <button
                        class="rounded-full border border-iel-gray p-2"
                        title="Send Carrier RateCon"
                        @click="goToPlanSendCarrierRateCon">
                        <AppIcon
                            name="far fa-paper-plane"
                            class="block size-5" />
                    </button>
                </div>
                <div class="grid grid-cols-3 gap-4">
                    <LoadFinancesBox
                        :id="getId('load-*-summary-finances-carrier-pay', [load])"
                        label="Pay"
                        :value="data.carrierPay" />
                    <LoadFinancesBox
                        :id="getId('load-*-summary-finances-carrier-min-pay', [load])"
                        label="Min. Pay Amount"
                        :value="data.carrierMinPay" />
                    <LoadFinancesBox
                        :id="getId('load-*-summary-finances-carrier-max-pay', [load])"
                        label="Max. Pay Amount"
                        :value="data.carrierMaxPay" />
                </div>
                <div class="grid grid-cols-3 gap-4">
                    <LoadFinancesBox
                        :id="getId('load-*-summary-finances-carrier-accessorials', [load])"
                        label="Accessorials"
                        :value="data.carrierAccessorials" />
                    <LoadFinancesBox
                        :id="getId('load-*-summary-finances-carrier-advances', [load])"
                        label="Advances"
                        :value="data.carrierAdvances" />
                    <LoadFinancesBox
                        :id="getId('load-*-summary-finances-carrier-third-party', [load])"
                        label="3rd Party"
                        :value="data.carrierAdvancesThirdParty" />
                </div>
                <LoadFinancesBox
                    :id="getId('load-*-summary-finances-carrier-total-pay', [load])"
                    label="Total Carrier Pay"
                    :value="data.carrierTotalPay" />
            </div>

            <div class="flex flex-col gap-4">
                <div class="text-xl font-bold">Customer Billing</div>
                <div class="grid grid-cols-2 gap-4">
                    <LoadFinancesBox
                        :id="getId('load-*-summary-finances-customer-billing', [load])"
                        label="Billing"
                        :value="!load.can.view_load_details ? '-' : data.customerBilling" />
                    <LoadFinancesBox
                        :id="getId('load-*-summary-finances-customer-accessorials', [load])"
                        label="Accessorials"
                        :value="!load.can.view_load_details ? '-' : data.customerAccessorials" />
                </div>
                <LoadFinancesBox
                    :id="getId('load-*-summary-finances-customer-billing-total', [load])"
                    label="Total Customer Billing"
                    :value="!load.can.view_load_details ? '-' : data.customerBillingTotal" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { mapValues } from 'lodash-es';
import { formatMoney, getId } from '@/helpers';

const { load } = defineProps({
    load: Object
});

const loadStats = useLoadStats(load);

const data = computed(() =>
    mapValues(loadStats, (stat, key) => {
        return key === 'margin' ? stat.value.toFixed(2) + '%' : formatMoney(stat.value);
    })
);

function goToPlanSendCarrierRateCon() {
    useLoadStepsStore(load.id).setStep('plan.send_carrier_rate_con');
}

function goToSetupCarrierPay() {
    useLoadStepsStore(load.id).setStep('setup');
    setTimeout(() => {
        const element = document.getElementById('load-detail-carrier-pay-rates');
        element.scrollIntoView({ behavior: 'smooth' });
    });
}
</script>
