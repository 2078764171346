<template>
    <table class="NX-mobile-stacked">
        <thead>
            <tr v-if="request.entity_type === 'carrier'">
                <th class="border-b-2">{{ $t('Document Name') }}</th>
                <th class="w-1/12 border-b-2">{{ $t('Labels') }}</th>
            </tr>
        </thead>
        <tbody>
            <slot></slot>
        </tbody>
    </table>
</template>

<script setup>
defineProps({
    request: Object
});
</script>
