<template>
    <div class="grid grid-cols-2 gap-1">
        <template v-if="user.is_manager || can('view_all_customers')">
            <AppAutocomplete
                v-model="employee"
                name="load-owner"
                label=""
                :initial-options="
                    employee
                        ? [
                              {
                                  value: employee,
                                  display: page.props.options.employee_name
                              }
                          ]
                        : []
                "
                :options-url="route('employees.autocomplete')"
                :placeholder="$t('Search for Load Owner...')"
                class="text-clip"
                @clear="handleClear" />
        </template>

        <template v-if="isVisible">
            <multiselect
                v-model="selectedCustomers"
                :options="customerOptions"
                :placeholder="$t('Choose Customers')"
                track-by="id"
                label="name"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :taggable="false"
                :open-direction="'bottom'"
                select-label="Enter to select"
                deselect-label="Enter to Remove"
                aria-multiselectable="true">
                <template #selection="{ values, isOpen }">
                    <span
                        v-if="values.length"
                        v-show="!isOpen"
                        class="multiselect__single">
                        {{ values.length }} options selected
                    </span>
                </template>
                <template #caret>{{}}</template>
            </multiselect>
        </template>
    </div>
</template>

<script setup>
import { router, usePage } from '@inertiajs/vue3';
import { watch } from 'vue';
import multiselect from 'vue-multiselect';

const page = usePage();
const { can, user } = useAuth();

const isVisible = computed(() => {
    return (
        route().current('loads.index') ||
        (route().current('loads.list') && (can('view_all_customers') || user.is_manager))
    );
});

const employee = ref(null);

watch(employee, newVal => {
    if (newVal) {
        router.visit(route(route().current(), { ...route().params, employee: newVal }), { only: ['loads', 'filters'] });
    }
});

const customerOptions = computed(() => {
    if (route().current('loads.index') || route().current('loads.list')) {
        return Object.keys(page.props.options.customers).map(id => ({
            id,
            name: page.props.options.customers[id]
        }));
    } else {
        return [];
    }
});

const selectedCustomers = ref([]);

watch(selectedCustomers, newVal => {
    if (newVal.length === 0) {
        router.visit(route('loads.index', route().params));
    }

    const queryParams = { ...route().params, customers: newVal.map(customer => customer.id).join(',') };
    router.visit(route(route().current(), queryParams), { only: ['loads', 'filters'] });
});

onMounted(() => {
    const params = new URLSearchParams(window.location.search);
    const selectedEmployee = params.get('employee');
    if (selectedEmployee) {
        employee.value = selectedEmployee;
    }

    const customers = params.get('customers');
    if (customers) {
        selectedCustomers.value = customers.split(',').map(id => ({
            id,
            name: page.props.options.customers[id]
        }));
    }
});

function handleClear() {
    let queryParams = route().params;
    delete queryParams.employee;
    router.visit(route(route().current(), queryParams), { only: ['loads', 'filters'] });
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.multiselect {
    @apply min-h-0 text-nowrap;
}
.multiselect__tags {
    @apply max-h-[29px];
}
.multiselect__placeholder,
.multiselect__input {
    @apply my-0;
}
.multiselect__content-wrapper {
    @apply border border-iel-dark-gray;
}
</style>
