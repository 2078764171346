<template>
    <div>
        <AppTablePill
            :columns="[
                { name: 'user_name', label: $t('Name') },
                { name: 'page', label: $t('Feedback Page') },
                { name: 'content', label: $t('Content') },
                { name: 'created_at', label: $t('Received at') },
                { name: 'type', label: $t('Type') }
            ]"
            :classes="{ row: 'align-top' }"
            :data="feedbacks.data">
            <template #name-cell="{ row }">
                <div class="flex items-center gap-2">
                    <div class="flex flex-1 gap-2 text-iel-blue">
                        <strong>{{ row.user_name }}</strong>
                    </div>
                </div>
            </template>
            <template #page-cell="{ row }">
                <a
                    :href="row.page"
                    class="flex items-center gap-2 underline">
                    {{ row.page }}
                </a>
            </template>
            <template #content-cell="{ row }">
                <div class="w-80">
                    {{ row.content }}
                </div>
            </template>
            <template #created_at-cell="{ row }">
                <div>{{ formatDate(row.created_at) }}</div>
            </template>
            <template #type-cell="{ row }">
                <div class="flex items-center gap-2 capitalize">
                    <div class="flex flex-1 gap-2 text-iel-blue">
                        {{ row.type }}
                    </div>
                </div>
            </template>
        </AppTablePill>
        <AppFeedback />
        <AppPagination :meta="feedbacks.meta" />
    </div>
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import { formatDate } from '@/helpers.js';
import FeedbackLayout from '@/Layouts/FeedbackLayout.vue';

defineOptions({
    layout: [AuthenticatedLayout, FeedbackLayout]
});

defineProps({
    feedbacks: Array
});
</script>
