<template>
    <AppCard
        title="Accounting"
        theme="light_gray"
        content-class="p-2 grid w-full gap-2 md:grid-cols-2">
        <AppDisplayField
            :id="`customer-group-${customerGroup.id}-group-credit-limit`"
            label="Group Credit Limit"
            :value="customerGroup.group_credit_limit" />
        <AppDisplayField
            :id="`customer-group-${customerGroup.id}-unbilled-ar`"
            label="Unbilled AR"
            :value="customerGroup.group_unbilled_ar" />
        <AppDisplayField
            :id="`customer-group-${customerGroup.id}-billed-ar`"
            label="Billed AR"
            :value="customerGroup.group_billed_ar" />
    </AppCard>
</template>

<script setup>
defineProps({
    customerGroup: Object
});
</script>
