<template>
    <AppModal
        :id="context.modalId"
        :show="isOpen"
        class="max-w-2xl"
        @close="close"
        @closed="onClosed">
        <div class="flex items-center justify-between">
            <h1 class="text-xl font-bold text-iel-blue">
                {{ context.title }}
            </h1>
        </div>
        <form @submit.prevent="submit">
            <!-- EDI Support Contact -->
            <div class="pb-2">
                <AppHeading
                    :id="getId('customer-*-edi-support-contact', [context.customer])"
                    size="md"
                    class="mb-2 text-iel-gray">
                    {{ $t('EDI Support Contact') }}
                </AppHeading>
                <div class="gap-2">
                    <div class="w-full">
                        <table class="NX-mobile-stacked w-full">
                            <thead>
                                <tr>
                                    <th>{{ $t('Name') }}</th>
                                    <th>{{ $t('Phone Number') }}</th>
                                    <th>{{ $t('Email') }}</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(contact, index) in form.contacts"
                                    :key="index">
                                    <td>
                                        <AppInput
                                            :id="getId('customer-*-edi-support-contact-name', [context.customer])"
                                            v-model="contact.name"
                                            name="edi_support_contact_name"
                                            label=""
                                            required />
                                    </td>
                                    <td>
                                        <AppInput
                                            :id="
                                                getId('customer-*-edi-support-contact-phone-number', [context.customer])
                                            "
                                            v-model="contact.phone"
                                            name="edi_support_contact_phone_number"
                                            label=""
                                            required />
                                    </td>
                                    <td>
                                        <AppInput
                                            :id="getId('customer-*-edi-support-contact-email', [context.customer])"
                                            v-model="contact.email"
                                            name="edi_support_contact_email"
                                            :error="errors[`contacts.${index}.email`]"
                                            label=""
                                            required />
                                    </td>
                                    <td>
                                        <AppButton
                                            v-if="form.contacts.length > 1"
                                            variant="outline"
                                            icon="far fa-trash-can"
                                            type="button"
                                            @click="removeContact(index)" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <AppButton
                            :id="getId('customer-*-edi-add-contact', [context.customer])"
                            icon="far fa-circle-plus"
                            class="mt-2"
                            @click="addContact">
                            {{ $t('Contact') }}
                        </AppButton>
                    </div>
                </div>
                <p class="mt-2 text-sm text-iel-gray">
                    *{{ $t('At least one Customer Support Contact is required to Submit an EDI Setup Request.') }}
                </p>
            </div>

            <hr class="my-2 border-iel-gray" />

            <!-- Details -->
            <div class="mt-4 pb-2">
                <AppHeading
                    :id="getId('customer-*-edi-details', [context.customer])"
                    size="md"
                    class="mb-2 text-iel-gray">
                    {{ $t('Details') }}
                </AppHeading>
                <div class="space-y-3">
                    <AppInput
                        :id="getId('customer-*-edi-tender-notification-email', [context.customer])"
                        v-model="form.tender_notification_email"
                        :label="$t('Tender Notifications Email')"
                        :error="form.errors.tender_notification_email"
                        required />
                    <AppCheckbox
                        :id="getId('customer-*-edi-multiple-customers', [context.customer])"
                        v-model="form.multiple_customers"
                        :label="$t('Multiple Customers')"
                        class="pb-2" />
                    <hr class="my-2 border-iel-light-gray" />
                    <AppTextarea
                        :id="getId('customer-*-edi-additional-notes', [context.customer])"
                        v-model="form.notes"
                        :label="$t('Additional Notes')"
                        class="pt-2" />
                </div>
            </div>

            <hr class="my-2 border-iel-gray" />

            <!-- Integration Options -->
            <div class="mt-4">
                <AppHeading
                    :id="getId('customer-*-edi-integration-options', [context.customer])"
                    size="md"
                    class="mb-2 text-iel-gray">
                    {{ $t('Integration Options') }}
                </AppHeading>
                <div>
                    <div
                        required
                        class="space-y-1">
                        <AppLabel
                            :label="$t('Select All That Apply')"
                            required />
                        <AppCheckbox
                            v-model="form.spot_bids"
                            name="spot_bids"
                            :label="$t('Receive/Respond to Spot Bids (EDI 204/990)')" />
                        <AppCheckbox
                            v-model="form.tenders"
                            name="tenders"
                            :label="$t('Accept/Reject Tenders (EDI 204/990)')" />
                        <AppCheckbox
                            v-model="form.send_214_appointment_status"
                            name="send_214_appointment_status"
                            :label="$t('Send Appointment Status (Arrived/Departed Stop) (EDI 214)')" />
                        <AppCheckbox
                            v-model="form.send_214_shipment_status"
                            name="send_214_shipment_status"
                            :label="$t('Send Shipment Status (Arrived at Pickup, Drop, etc.) (EDI 214)')" />
                        <AppCheckbox
                            v-model="form.send_210_invoice_to_customer"
                            name="send_210_invoice_to_customer"
                            :label="$t('Send Invoice to Customer System (EDI 210)')" />
                        <AppCheckbox
                            v-model="form.update_customer_paperwork"
                            name="update_customer_paperwork"
                            :label="$t('Update Paperwork into Customer System (POD, BOL, etc.)')" />
                    </div>
                </div>
            </div>

            <div class="flex items-end justify-end pt-4">
                <AppButton
                    variant="outline"
                    class="ml-4 mr-4 justify-end gap-4 p-2"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    id="save-customer-edi-setup-request"
                    class="justify-end gap-4 p-2"
                    type="submit">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { getId } from '@/helpers';
import { Toast } from '@/Composables/useSwal.js';

const { isOpen, close, onClosed, context } = useModal('CustomerEDISetupRequestModal');

const errors = ref({});

let customerId;

const form = useForm({
    type: 'EDI Setup Request',
    contacts: {
        name: null,
        phone: null,
        email: null
    },
    tender_notification_email: null,
    multiple_customers: false,
    notes: null,
    spot_bids: false,
    tenders: false,
    send_214_appointment_status: false,
    send_214_shipment_status: false,
    send_210_invoice_to_customer: false,
    update_customer_paperwork: false
});

watch(isOpen, () => {
    form.reset();
    form.clearErrors();
    errors.value = {};

    customerId = context.value.customer.id;
    form.contacts = [{ name: null, phone: null, email: null }];
});

function removeContact(index) {
    form.contacts.splice(index, 1);
}

function addContact() {
    form.contacts.push({
        name: null,
        phone: null,
        email: null
    });
}

function submit() {
    errors.value = {};

    form.post(
        route('customers.ediSetupRequest', {
            customer: customerId
        }),
        {
            preserveScroll: true,
            onSuccess() {
                Toast.fire({
                    title: 'Success',
                    icon: 'success'
                });
                close();
            },
            onError(e) {
                errors.value = e;
            }
        }
    );
}
</script>
