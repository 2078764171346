<template>
    <tr>
        <td>
            <span
                class="text-sm font-bold"
                data-test="uploaded-filename">
                {{ pendingFile.name }}
            </span>
        </td>
        <td>
            <span
                class="text-sm"
                data-test="uploaded-filesize">
                {{ Math.round(pendingFile.size / 1000) + ' KB' }}
            </span>
        </td>
        <td>
            <AppSelectTags
                id="select-label-load-docs"
                v-model="form.labels"
                :placeholder="$t('Select Labels')"
                name="labels"
                :options="loadDocumentTypes"
                class="w-full"
                :taggable="false"
                open-direction="top"
                required />
        </td>
        <td>
            <AppSelectTags
                id="select-label-load-docs"
                v-model="stops"
                :placeholder="$t('Select Stop')"
                name="stops"
                :options="stopsOptions"
                class="w-full"
                :taggable="false"
                open-direction="top"
                required />
        </td>
        <td>
            <div class="my-2 flex space-x-2 sm:my-0">
                <AppButton
                    :id="`documents-list-${pendingFile.name}-actions-upload-document-button`"
                    icon="fal fa-file-arrow-up"
                    :disabled="uploadButtonDisabled"
                    @click="store">
                    <span class="sr-only">Upload {{ pendingFile.name }}</span>
                </AppButton>
                <AppButton
                    :id="`documents-list-${pendingFile.name}-actions-remove-document-button`"
                    variant="outline"
                    icon="fal fa-trash-can"
                    @click="remove">
                    <span class="sr-only">Remove {{ pendingFile.name }}</span>
                </AppButton>
            </div>
        </td>
    </tr>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { loadDocumentTypes } from '@/data.js';

const { load, pendingFile } = defineProps({
    load: Object,
    pendingFile: Object
});

const emit = defineEmits(['remove']);

const form = useForm({
    document: pendingFile,
    labels: [],
    stops: []
});

const stops = ref([]);
const uploadButtonDisabled = ref(false);

provide('form', form);

const stopsOptions = computed(() => {
    return (load.stops || []).map((stop, index) => {
        return {
            label: `Stop # ${index + 1} (${stop.location.name})`,
            value: stop.id
        };
    });
});

function store() {
    uploadButtonDisabled.value = true;
    form.stops = stops.value.map(stop => stop.value);
    form.post(route('loads.documents.store', [load, pendingFile]), {
        preserveScroll: true,
        onSuccess() {
            remove();
        },
        onFinish() {
            uploadButtonDisabled.value = false;
        },
        onError() {
            uploadButtonDisabled.value = false;
        }
    });
}

function remove() {
    emit('remove');
}
</script>
