<template>
    <AppCard
        v-if="load.can.view_load_details"
        :id="getId('load-*-billing-details', [load])"
        title="Billing Details"
        theme="blue"
        content-class="p-4">
        <template #nav>
            <AppButton
                :id="getId('load-*-customers-add-button', [load])"
                variant="outline"
                icon="fal fa-circle-plus"
                @click="addCustomer">
                {{ $t('Customer') }}
            </AppButton>
        </template>
        <AppTabs>
            <AppTab
                v-for="customer in load.customers"
                :id="getId('load-*-customer-*-tab', [load, customer])"
                :key="getId('load-*-customer-*-tab', [load, customer])"
                :title="$t(customer.name)"
                :active="customer.is_primary">
                <LoadCustomerBillingNumbers
                    :load="load"
                    :customer="customer"
                    class="mt-4" />
                <hr class="my-4 border-iel-gray" />

                <LoadCustomerRates
                    :load="load"
                    :customer="customer" />
                <hr class="my-4 border-iel-gray" />

                <AppTextarea
                    :id="getId('load-*-customer-*-billing-notes', [load, customer])"
                    v-model="customer.billing_notes"
                    permission="update_load_billing_details"
                    name="billing_notes"
                    label="Internal Billing Notes"
                    input-class="resize-none"
                    rows="6"
                    :url="route('loads.customers.update', [load, customer])"
                    :disabled="load.is_canceled_or_tonu || !load.can.view_load_details" />

                <AppButton
                    :id="getId('load-*-customers-*-remove-button', [load, customer])"
                    variant="outline"
                    icon="fal fa-trash-can"
                    class="ml-auto mt-4"
                    :disabled="load.is_canceled_or_tonu || !load.can.view_load_details || customer.is_primary"
                    @click="removeCustomer(customer)">
                    {{ $t('Customer') }}
                </AppButton>
            </AppTab>
        </AppTabs>
    </AppCard>
</template>

<script setup>
import { getId } from '@/helpers';
import { router } from '@inertiajs/vue3';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

function addCustomer() {
    useModal('LoadCustomerAddModal').open({
        load: load
    });
}

function removeCustomer(customer) {
    if (confirm('Are you sure you want to remove this customer from the load?')) {
        router.delete(route('loads.customers.destroy', [load.id, customer.id]), {
            preserveScroll: true,
            onError(e) {
                alert(e.error_message);
            }
        });
    }
}
</script>
