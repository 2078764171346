<template>
    <AppDropdown align="top-right">
        <template #trigger>
            <button
                :id="`carrier-${carrier.id}-actions-trigger`"
                ref="triggerRef"
                class="flex px-1"
                type="button"
                aria-label="dropdown menu to select load action options"
                @click.stop="handleToggle">
                <AppIcon
                    name="far fa-ellipsis"
                    class="cursor-pointer text-iel-blue" />
            </button>
        </template>
        <template #content>
            <Teleport
                v-if="isOpen"
                to="body">
                <div
                    ref="dropdownRef"
                    class="absolute z-50 min-w-56 rounded border border-iel-gray bg-white py-1 shadow-lg"
                    :style="dropdownPosition">
                    <button
                        id="update-insurance-button"
                        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                        :class="{
                            'tooltip group cursor-not-allowed bg-iel-light-gray text-iel-gray':
                                !activeCarrier || insuranceDisabled
                        }"
                        type="button"
                        :disabled="!activeCarrier || insuranceDisabled"
                        @click="openInsuranceModal(carrier)">
                        {{ $t('Update Insurance') }}
                        <span
                            v-if="insuranceDisabled"
                            class="invisible absolute -top-0.5 right-56 z-10 mr-1 w-36 text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                            {{ $t('An open request for this type already exists.') }}
                        </span>
                        <span
                            v-if="!activeCarrier"
                            class="invisible absolute right-56 z-10 mr-1 w-36 text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                            {{ $t('Carrier is inactive.') }}
                        </span>
                    </button>
                    <button
                        id="activate-carrier-button"
                        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                        :class="{
                            'tooltip group cursor-not-allowed bg-iel-light-gray text-iel-gray':
                                activeCarrier || openActivationRequest
                        }"
                        type="button"
                        :disabled="activeCarrier || openActivationRequest"
                        @click="openActivationModal(carrier)">
                        {{ $t('Activate') }}
                        <span
                            v-if="activeCarrier"
                            class="invisible absolute -top-0.5 right-56 z-10 mr-1 w-36 text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                            {{ $t('Carrier is already active.') }}
                        </span>
                        <span
                            v-if="openActivationRequest"
                            class="invisible absolute -top-0.5 right-56 z-10 mr-1 w-36 text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                            {{ $t('An open request for this type already exists.') }}
                        </span>
                    </button>
                </div>
            </Teleport>
        </template>
    </AppDropdown>
</template>

<script setup>
import useTeleport from '@/Composables/useTeleport';

const props = defineProps({
    carrier: Object
});

const { open: CarrierTicketModal } = useModal('CarrierTicketModal');

const { isOpen, triggerRef, dropdownRef, dropdownPosition, toggle } = useTeleport();
function handleToggle() {
    toggle();
}

const openInsuranceModal = row => {
    CarrierTicketModal({
        modalId: 'carrier-update-insurance-modal',
        title: 'Request Carrier Insurance Update',
        ticketType: 'Insurance Update',
        carrier: row
    });
};

const openActivationModal = row => {
    CarrierTicketModal({
        modalId: 'carrier-activate-modal',
        title: 'Request Carrier Activation',
        ticketType: 'Carrier Activation',
        carrier: row
    });
};

const activeCarrier = computed(() => {
    return props.carrier.is_active;
});

const insuranceDisabled = computed(() => {
    return props.carrier.is_active && (props.carrier.needs_insurance_update || props.carrier.has_open_ticket);
});

const openActivationRequest = computed(() => {
    return !props.carrier.is_active && props.carrier.has_open_ticket;
});
</script>
