<template>
    <div>hello i am tender # {{ tender.id }}</div>
    <TenderDetails :tender="tender" />
    <TenderStops :tender="tender" />
</template>

<script setup>
const { tender } = defineProps({
    tender: {
        type: Object,
        required: true,
        default: () => ({})
    }
});
</script>
