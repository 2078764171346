<template>
    <QuoteHeader />
    <header
        :id="getId('load-*-header', [quote])"
        class="flex items-center justify-between rounded-t-xl bg-iel-blue px-4 py-3">
        <div
            :id="getId('load-*-details-header', [quote])"
            class="flex items-center gap-8 text-sm text-white">
            <div class="flex items-center gap-3 font-bold">
                <Link
                    :id="getId('load-*-back-button', [quote])"
                    :href="route('quotes.index')"
                    aria-label="Return to all quotes">
                    <AppIcon
                        name="far fa-arrow-left"
                        class="text-white" />
                </Link>
                <span>#{{ quote.po_number }}</span>
                <Link
                    v-if="quote.customer?.name"
                    :id="getId('quote-*-customer-link', [quote])"
                    :href="route('customers.show', quote.customer.id)"
                    class="underline">
                    {{ quote.customer?.name }}
                </Link>
                <span>|</span>
                <div
                    v-if="quote.user?.name"
                    :id="getId('quote-*-user', [quote])">
                    {{ quote.user?.name }}
                </div>
            </div>
        </div>
    </header>

    <QuoteDetails :quote="quote" />

    <QuoteStops :quote="quote" />

    <QuoteBids :quote="quote" />

    <QuoteNotes :quote="quote" />
</template>

<script setup>
import { getId } from '@/helpers';

const { quote } = defineProps({
    quote: {
        type: Object,
        required: true,
        default: () => ({
            tasks: {}
        })
    }
});
</script>
