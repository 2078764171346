<script setup>
defineProps({
    size: {
        type: String,
        default: 'sm'
    },
    withBg: {
        type: Boolean,
        default: false
    }
});

const classes = {
    sm: 'text-sm font-bold text-iel-dark-gray',
    md: 'text-md font-bold text-iel-dark-gray',
    lg: 'text-lg font-medium text-iel-dark-gray',
    xl: 'text-xl font-bold text-iel-dark-gray',
    '2xl': 'text-2xl font-bold text-iel-dark-gray',
    '3xl': 'text-3xl font-bold text-iel-blue'
};
</script>

<template>
    <div :class="[classes[size], { 'bg-iel-light-gray p-2': withBg }]">
        <slot />
    </div>
</template>
