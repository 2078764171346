<template>
    <div class="flex items-center gap-4">
        <AppCard
            title="3rd Party"
            theme="blue"
            content-class="gap-4 p-4">
            <table
                v-if="load.thirdPartyTransactions.length"
                :id="getId('load-*-third-party-table', [load])"
                class="NX-mobile-stacked w-full">
                <thead>
                    <tr>
                        <th>{{ $t('Quantity') }}</th>
                        <th>{{ $t('Amount') }}</th>
                        <th>{{ $t('Type') }}</th>
                        <th>{{ $t('Total') }}</th>
                        <th>{{ $t('Note') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <LoadBillThirdPartyTransactions
                        v-for="thirdPartyTransaction in load.thirdPartyTransactions"
                        :key="thirdPartyTransaction.id"
                        permission="update_third_party_charges"
                        :load="load"
                        :transaction="thirdPartyTransaction" />
                </tbody>
            </table>
            <div class="flex items-center justify-between pt-3">
                <AppButton
                    :id="getId('load-*-add-third-party-item-button', [load])"
                    type="button"
                    icon="far fa-circle-plus"
                    :disabled="isDisabled"
                    permission="update_third_party_charges"
                    @click="addThirdPartyRate">
                    {{ $t('Rate') }}
                </AppButton>
                <div class="font-bold">
                    <p>Total: {{ formatMoney(total) }}</p>
                </div>
            </div>
        </AppCard>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { getId, formatMoney } from '@/helpers';
import { useTasks } from '@/Composables/useTasks';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const loading = ref(false);

function addThirdPartyRate() {
    router.post(
        route('loads.billing.thirdParty.store', load.id),
        {},
        {
            preserveScroll: true,
            only: ['load'],
            onBefore() {
                loading.value = true;
            },
            onSuccess() {
                loading.value = false;
            }
        }
    );
}

const total = computed(() => load.thirdPartyTransactions.reduce((sum, item) => sum + Number(item.total), 0));

const isDisabled = computed(() => {
    return (
        loading.value ||
        load.is_canceled_or_tonu ||
        !load.can.view_load_details ||
        useTasks(load).hasCompletedTasks(['pay_carrier', 'collect_payment'])
    );
});
</script>
