<template>
    <AppTablePill
        id="quotes-table"
        theme="blue"
        :columns="[
            { name: 'id', label: 'Quote #', sortable: true },
            { name: 'customer', label: 'Customer' },
            { name: 'references', label: 'Reference #' },
            { name: 'number_of_stops', label: '# of Stops' },
            { name: 'weight', label: 'Weight' },
            { name: 'trip_miles', label: 'Miles' },
            { name: 'equipment', label: 'Equipment' },
            { name: 'lane', label: 'Lane' },
            { name: 'date_time', label: 'Date/Time' },
            { name: 'actions', label: 'Status' }
        ]"
        :data-key="'quotes'"
        :data="quotes"
        :classes="{ row: 'cursor-pointer' }"
        @row-click="openQuote">
        <template #customer-cell="{ row }">
            <Link
                :href="route('quotes.show', row.id)"
                class="font-bold text-iel-blue underline">
                {{ row.customer?.name }}
            </Link>
        </template>

        <template #references-cell="{ row }">
            <div
                v-if="row.references"
                class="space-y-1">
                <p
                    v-for="ref in row.references || []"
                    :key="ref.number"
                    class="text-sm">
                    {{ ref.number }}
                </p>
            </div>
        </template>

        <template #number_of_stops-cell="{ row }">
            <p>{{ row.stops.length }}</p>
        </template>

        <template #weight-cell="{ row }">
            <div
                v-if="row.stops"
                class="text-sm">
                {{ row.total_load_weight }} {{ row.total_load_weight_description }}
            </div>
        </template>

        <template #trip_miles-cell="{ row }">
            <p v-if="row.trip_miles">{{ row.trip_miles }} mi</p>
        </template>
        <template #lane-cell="{ row }">
            <div
                v-if="formatLane(row)"
                class="space-y-1">
                <p class="text-sm">{{ formatLane(row).origin }}</p>
                <p class="text-sm">{{ formatLane(row).destination }}</p>
            </div>
        </template>

        <template #date_time-cell="{ row }">
            <div
                v-if="formatLane(row)"
                class="space-y-1">
                <p class="text-sm">{{ formatLane(row).startDate }}</p>
                <p class="text-sm">{{ formatLane(row).endDate }}</p>
            </div>
        </template>

        <template #equipment-cell="{ row }">
            <p class="text-sm">{{ row.equipment_length }} {{ row.required_equipment_type }}</p>
        </template>

        <template #actions-cell="{ row }">
            <QuoteTasks :quote="row" />
        </template>
    </AppTablePill>
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import QuotesLayout from '@/Layouts/QuotesLayout.vue';
import { formatLane } from '@/helpers';
import { router } from '@inertiajs/vue3';

defineOptions({
    layout: [AuthenticatedLayout, QuotesLayout]
});

const { quotes } = defineProps({
    quotes: {
        type: Array,
        required: true,
        default: () => []
    }
});

function openQuote(row) {
    router.visit(route('quotes.show', row.id));
}
</script>
