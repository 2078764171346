<template>
    <div class="tabs flex gap-2">
        <div
            v-for="tab in tabs"
            :key="tab"
            class="tab cursor-pointer rounded rounded-b-none px-2 py-1 text-white"
            :class="activeTab === tab ? 'bg-iel-dark-gray' : 'bg-iel-gray/90'"
            @click="setActiveTab(tab)">
            {{ tab }}
        </div>
    </div>
    <div class="grid gap-2 bg-white p-4">
        <QuoteStopDetails
            v-if="activeTab === 'Details'"
            :stop="stop"
            :quote="quote" />
        <QuoteStopSchedule
            v-else-if="activeTab === 'Schedule'"
            :stop="stop"
            :quote="quote" />
        <QuoteStopLineItems
            v-else-if="activeTab === 'Line Items'"
            :stop="stop"
            :quote="quote" />
    </div>
</template>

<script setup>
const { stop, quote } = defineProps({
    stop: Object,
    quote: Object
});

const activeTab = ref('Details');

const tabs = ['Details', 'Schedule', 'Line Items'];

function setActiveTab(tab) {
    activeTab.value = tab;
}
</script>
