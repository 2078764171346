<template>
    <div>
        <div
            v-if="formIsOpen"
            class="rounded-lg border border-iel-gray p-3">
            <CustomerEdiConnectionForm
                :customer="customer"
                :connection-modes="connectionModes"
                :connection-reasons="connectionReasons"
                :connection-types="connectionTypes"
                :hashing-algorithms="hashingAlgorithms"
                :encryption-algorithms="encryptionAlgorithms"
                :partner-vendors="partnerVendors"
                @close="formIsOpen = false" />
        </div>
        <div v-else>
            <CustomerEdiConnectionList
                :customer="customer"
                :connection-modes="connectionModes"
                :connection-reasons="connectionReasons"
                :connection-types="connectionTypes"
                :hashing-algorithms="hashingAlgorithms"
                :encryption-algorithms="encryptionAlgorithms"
                :partner-vendors="partnerVendors"
                @add-connection="formIsOpen = true" />
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';

const {
    customer,
    connectionModes,
    connectionReasons,
    connectionTypes,
    hashingAlgorithms,
    encryptionAlgorithms,
    partnerVendors
} = defineProps({
    customer: Object,
    connectionModes: Object,
    connectionReasons: Array,
    connectionTypes: Object,
    hashingAlgorithms: Object,
    encryptionAlgorithms: Object,
    partnerVendors: Array
});

const formIsOpen = ref(false);
</script>
