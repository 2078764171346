<template>
    <div :class="[cardClass, overflowClass, borderClasses]">
        <header
            class="rounded-t-lg"
            :class="[headerClass, flexClass, headerHeight]">
            <div class="flex items-center gap-2">
                <slot name="title">
                    <AppIcon
                        v-if="icon"
                        :name="icon"
                        class="-mb-0.5 mr-2 text-lg"
                        :class="titleClass" />
                    <div
                        class="px-4 py-2 text-lg font-semibold"
                        :class="titleClass">
                        {{ title }}
                    </div>
                    <slot name="titleMeta" />
                </slot>
            </div>
            <div>
                <slot name="nav" />
            </div>
        </header>
        <div
            :class="contentClass"
            class="rounded-b-lg border border-t-0 border-iel-mercury bg-white"
            @click="$emit('clickInside')">
            <slot />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const { title, icon, stacked, index, theme, borderClass, contentClass, cardClass, overflowClass } = defineProps({
    title: String,
    icon: String,
    stacked: Boolean,
    index: Boolean,
    theme: {
        type: String,
        default: 'gray',
        validator: value => ['blue', 'gray', 'light_gray', 'white', 'warning', 'past_due'].includes(value)
    },
    borderClass: {
        type: Boolean,
        default: false
    },
    contentClass: [String, Array],
    cardClass: [String, Array],
    overflowClass: {
        type: String,
        default: 'overflow-hidden'
    }
});

defineEmits(['clickInside']);

const headerClass = computed(
    () =>
        ({
            blue: 'bg-iel-blue',
            gray: 'bg-iel-dark-gray',
            light_gray: 'bg-iel-gray/20',
            white: 'border border-iel-mercury',
            warning: 'bg-iel-dark-yellow',
            past_due: 'bg-iel-red'
        })[theme]
);

const titleClass = computed(
    () =>
        ({
            blue: 'text-white',
            gray: 'text-white',
            light_gray: 'text-iel-dark-gray',
            white: 'text-iel-dark-gray',
            warning: 'text-white',
            past_due: 'text-white'
        })[theme]
);

const borderClasses = computed(() =>
    borderClass ? 'border-x-4 border-b-4 rounded-xl border-iel-blue relative w-full' : 'relative w-full rounded-xl'
);

const flexClass = computed(() =>
    stacked
        ? 'flex flex-col items-center justify-between gap-2 md:flex-row'
        : 'flex items-start md:items-center justify-between'
);

const headerHeight = computed(() => (index ? 'p-2' : 'p-0.5 px-2'));
</script>
