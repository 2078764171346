<template>
    <div class="overflow-hidden">
        <ul
            id="load-list"
            class="grid grid-cols-[repeat(auto-fill,minmax(22rem,1fr))] gap-2 overflow-hidden">
            <LoadCard
                v-for="load in loads.data"
                :key="load.id"
                :load="load"
                :options="options" />
        </ul>

        <AppPagination
            v-if="loadboard.layout !== 'list'"
            :meta="loads.meta" />
    </div>
</template>

<script setup>
import { loadboard } from '@/stores';
import LoadCard from '@/Pages/Loads/Partials/LoadCard.vue';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import LoadLayout from '@/Layouts/LoadLayout.vue';

defineOptions({
    layout: [AuthenticatedLayout, LoadLayout]
});

const { loads, options } = defineProps({
    loads: {
        type: Object,
        required: true,
        default: () => {}
    },
    options: {
        type: Object,
        required: true,
        default: () => {}
    }
});
</script>
