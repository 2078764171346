<template>
    <AppModal
        id="create-flag-modal"
        :show="isOpen"
        title="Create Flag"
        class="max-w-md"
        icon="fas fa-flag"
        @close="close"
        @closed="onClosed">
        <AppSelect
            v-model="flagType"
            name="flag_type"
            label="Type"
            required
            class="mb-3"
            :options="{
                claim: 'Claim',
                carrier_incident: 'Carrier Relations',
                all_risk:
                    context.load.all_risk && context.load.all_risk?.resolved_at !== null
                        ? 'Resubmit All Risk Insurance'
                        : 'All Risk Insurance'
            }" />
        <LoadClaimCreate
            v-if="flagType === 'claim' && context.load.claim === null"
            :load="context.load"
            @cancel="cancel"
            @success="close" />
        <span v-else-if="flagType === 'claim' && context.load.claim !== null">
            Sorry, there is already an open claim associated with this load.
        </span>
        <LoadCarrierIncidentCreate
            v-if="flagType === 'carrier_incident' && context.load.carrier_incident === null"
            :load="context.load"
            @cancel="cancel"
            @success="close" />
        <span v-else-if="flagType === 'carrier_incident' && context.load.carrier_incident !== null">
            Sorry, there is already an open carrier incident associated with this load.
        </span>
        <LoadAllRiskCreate
            v-if="
                flagType === 'all_risk' &&
                (context.load.all_risk === null || context.load.all_risk?.resolved_at !== null)
            "
            :load="context.load"
            :options="context.options"
            @cancel="cancel"
            @success="close" />
        <span v-else-if="flagType === 'all_risk' && context.load.all_risk !== null">
            Sorry, there is already an open All Risk Insurance flag associated with this load.
        </span>
    </AppModal>
</template>

<script setup>
const { isOpen, close, onClosed, context } = useModal('LoadFlagCreateModal');

const flagType = ref(null);

function cancel() {
    flagType.value = null;
    close();
}
</script>
