<template>
    <div
        v-if="meta && meta.total"
        class="items-center pt-4 lg:flex"
        :class="[variants[variant]]">
        <nav
            aria-label="Pagination"
            class="flex w-fit divide-x divide-iel-light-gray self-stretch overflow-hidden rounded-md border border-iel-gray/40 bg-white text-sm shadow-sm">
            <Link
                v-for="page in meta.links.filter(link => link.url)"
                :key="page.url"
                :href="page.url"
                class="flex items-center justify-center px-4 py-2"
                :class="page.active ? 'bg-iel-lightest-gray' : 'hover:bg-iel-silver'"
                aria-label="pagination link">
                <template v-if="page.label.includes('Previous')">
                    <AppIcon
                        name="far fa-chevron-left"
                        solid
                        class="-mx-1.5 h-4 w-4" />
                </template>
                <template v-else-if="page.label.includes('Next')">
                    <AppIcon
                        name="far fa-chevron-right"
                        solid
                        class="-mx-1.5 h-4 w-4" />
                </template>
                <template v-else>
                    {{ page.label }}
                </template>
            </Link>
        </nav>
        <AppPaginationLimit v-bind="{ meta }" />
    </div>
</template>

<script setup>
defineProps({
    meta: Object,
    variant: {
        type: String,
        default: 'evenly',
        validator: value => ['evenly', 'beginning'].includes(value)
    }
});

const variants = {
    evenly: 'justify-between',
    beginning: 'justify-start space-x-6'
};
</script>
