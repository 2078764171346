<template>
    <div>
        <div class="mb-10 grid grid-cols-6 gap-5">
            <AppSelect
                v-model="form.protocol"
                name="protocol"
                class="col-span-2"
                :label="$t('New Connection Protocol')"
                :options="connectionTypes"
                permission="update_edi_details"
                required />

            <div class="col-span-2">
                <AppAutocomplete
                    v-model="form.partner_vendor_id"
                    name="partner_vendor_id"
                    label="Partner Vendor"
                    :initial-value="partnerVendorInitialValue"
                    :options-url="route('customers.ediPartnerVendors.autocomplete', props.customer)"
                    :placeholder="$t('Select Partner Vendor...')"
                    permission="update_edi_details"
                    allow-adding
                    @add="query => addPartnerVendor(query)" />
            </div>

            <div class="flex items-center">
                <AppToggle
                    v-model="form.is_active"
                    permission="update_edi_details"
                    class="col-span-2 font-bold uppercase text-white" />
                <span
                    class="cursor-pointer"
                    @click="form.is_active = !form.is_active">
                    Active
                </span>
            </div>
        </div>

        <div class="mt-3 grid grid-cols-4 items-center gap-3">
            <span class="col-span-2">Connection Reason</span>
            <AppSelectTags
                id="edit-connection-reasons"
                v-model="form.connection_reasons"
                class="col-span-2"
                name="connection_reasons"
                label=""
                placeholder="Tenders, Tracking, Invoice..."
                permission="update_edi_details"
                :options="reasons.map(reason => reason.value)" />
        </div>

        <hr class="mt-3" />

        <div v-if="form.protocol === 'AS2'">
            <div class="mt-3 grid grid-cols-4 items-center gap-3">
                <span class="col-span-2">AS2 ID</span>
                <AppInput
                    v-model="form.credentials.as2_id"
                    class="col-span-2"
                    placeholder="Enter AS2 ID"
                    name="credentials.as2_id"
                    permission="update_edi_details"
                    label="" />
            </div>
            <div class="mt-3 grid grid-cols-4 items-center gap-3">
                <span class="col-span-2">AS2 URL</span>
                <AppInput
                    v-model="form.credentials.as2_url"
                    class="col-span-2"
                    placeholder="Enter AS2 URL"
                    name="credentials.as2_url"
                    permission="update_edi_details"
                    label="" />
            </div>
            <div class="mt-3 grid grid-cols-4 items-center gap-3">
                <span class="col-span-2">Hashing Algorithm</span>
                <AppSelect
                    v-model="form.credentials.hashing_algorithm"
                    class="col-span-2"
                    name="credentials.hashing_algorithm"
                    label=""
                    empty-option="- Select Hashing Algorithm -"
                    permission="update_edi_details"
                    :options="hashingAlgorithms" />
            </div>
            <div class="mt-3 grid grid-cols-4 items-center gap-3">
                <span class="col-span-2">Encryption Algorithm</span>
                <AppSelect
                    v-model="form.credentials.encryption_algorithm"
                    class="col-span-2"
                    name="credentials.encryption_algorithm"
                    label=""
                    empty-option="- Select Encryption Algorithm -"
                    permission="update_edi_details"
                    :options="encryptionAlgorithms" />
            </div>
            <div class="mt-3 grid grid-cols-4 items-center gap-3">
                <span class="col-span-2">Sync/Async</span>
                <AppSelect
                    v-model="form.credentials.async"
                    class="col-span-2"
                    name="credentials.async"
                    label=""
                    empty-option="- Select Mode -"
                    permission="update_edi_details"
                    :options="connectionModes" />
            </div>
        </div>

        <div v-if="['SFTP', 'FTP'].includes(form.protocol)">
            <div class="mt-3 grid grid-cols-4 items-center gap-3">
                <span class="col-span-2">Host</span>
                <AppInput
                    v-model="form.credentials.host"
                    class="border-1 col-span-2"
                    name="credentials.host"
                    label=""
                    permission="update_edi_details" />
            </div>
            <div class="mt-3 grid grid-cols-4 items-center gap-3">
                <span class="col-span-2">Port</span>
                <AppInput
                    v-model="form.credentials.port"
                    class="border-1 col-span-2"
                    name="credentials.port"
                    label=""
                    permission="update_edi_details" />
            </div>
            <div class="mt-3 grid grid-cols-4 items-center gap-3">
                <span class="col-span-2">Get Directory</span>
                <AppInput
                    v-model="form.credentials.get_directory"
                    class="border-1 col-span-2"
                    name="credentials.get_directory"
                    label=""
                    permission="update_edi_details" />
            </div>
            <div class="mt-3 grid grid-cols-4 items-center gap-3">
                <span class="col-span-2">Send Directory</span>
                <AppInput
                    v-model="form.credentials.send_directory"
                    class="border-1 col-span-2"
                    name="credentials.send_directory"
                    label=""
                    permission="update_edi_details" />
            </div>
        </div>

        <div
            class="flex gap-2 pt-3"
            :class="{
                'justify-end': mode === 'create',
                'justify-between': mode === 'edit'
            }">
            <div>
                <AppButton
                    v-if="mode === 'edit'"
                    variant="outline"
                    class="mr-auto border-iel-dark-red text-iel-dark-red"
                    :icon="mode === 'create' ? 'far fa-circle-plus' : ''"
                    permission="update_edi_details"
                    @click="deleteConnection">
                    Delete
                </AppButton>
            </div>
            <div>
                <div class="flex justify-end gap-2">
                    <AppButton
                        :icon="mode === 'create' ? 'far fa-circle-plus' : ''"
                        permission="update_edi_details"
                        @click="handleConnection">
                        {{ mode === 'create' ? 'Add' : 'Save' }}
                    </AppButton>
                    <AppButton
                        variant="outline"
                        permission="update_edi_details"
                        @click="cancel">
                        Cancel
                    </AppButton>
                </div>
                <p
                    v-if="mode === 'edit'"
                    class="mt-2 text-xs">
                    <span class="font-bold">Last Updated</span>
                    {{ formatDate(initialValue.updated_at) }}
                </p>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useForm, router } from '@inertiajs/vue3';
import { formatDate } from '@/helpers.js';
import { Toast } from '@/Composables/useSwal';

const AS2 = {
    as2_id: '',
    as2_url: '',
    hashing_algorithm: null,
    encryption_algorithm: null,
    async: null
};

const FTP = {
    host: '',
    port: '',
    get_directory: '',
    send_directory: ''
};

const props = defineProps({
    mode: {
        type: String,
        default: 'create'
    },
    customer: Object,
    connectionModes: Object,
    connectionReasons: Array,
    connectionTypes: Object,
    hashingAlgorithms: Object,
    encryptionAlgorithms: Object,
    partnerVendors: Array,
    initialValue: Object
});

const emit = defineEmits(['close', 'deleted']);

const partnerVendorInitialValue = ref('');

const form = useForm(() => {
    const baseForm = {
        protocol: 'AS2',
        partner_vendor_id: null,
        is_active: false,
        connection_reasons: [],
        credentials: { ...AS2 }
    };

    if (props.initialValue && props.mode === 'edit') {
        partnerVendorInitialValue.value = props.initialValue.partner_vendor?.name || '';
        return { ...props.initialValue };
    }

    return baseForm;
});

provide('form', form);

watch(
    () => form.protocol,
    newValue => {
        switch (newValue) {
            case 'AS2':
                form.credentials = { ...AS2 };
                break;
            case 'FTP':
                form.credentials = { ...FTP };
                break;
            case 'SFTP':
                form.credentials = { ...FTP };
                break;
            default:
                form.credentials = { ...AS2 };
                break;
        }
    }
);

const reasons = computed(() =>
    props.connectionReasons.map(reason => ({
        value: reason,
        label: reason
    }))
);

function handleConnection() {
    if (props.mode === 'create') {
        return createConnection();
    }
    updateConnection();
}

function createConnection() {
    form.post(route('customers.ediConnections.store', [props.customer]), {
        preserveScroll: true,
        onSuccess() {
            emit('close');
        }
    });
}

function updateConnection() {
    form.post(route('customers.ediConnections.update', [props.customer, props.initialValue]), {
        preserveScroll: true,
        onSuccess() {
            emit('close');
        }
    });
}

function deleteConnection() {
    if (confirm('Are you sure you want to delete this connection?')) {
        router.delete(route('customers.ediConnections.delete', [props.customer, props.initialValue]), {
            preserveScroll: true,
            onSuccess() {
                console.log('Delete successful, about to emit deleted event');

                console.log('Deleted event emitted');
            },
            onError(e) {
                console.log(e);
                Toast.fire({
                    title: e.error_message,
                    icon: 'error'
                });
            }
        });
    }
}

function cancel() {
    form.reset();
    form.clearErrors();
    emit('close');
}

function addPartnerVendor(query) {
    useModal('CustomerPartnerVendorModal').open({
        query,
        customer: props.customer,
        onClose: value => {
            if (value) {
                partnerVendorInitialValue.value = value;
                nextTick(() => {
                    const partner = props.partnerVendors.find(vendor => vendor.name === value);
                    form.partner_vendor_id = partner?.id;
                });
            }
        }
    });
}

watch(
    () => form.partner_vendor_id,
    newValue => {
        if (!newValue) {
            partnerVendorInitialValue.value = '';
        }
    }
);
</script>
