<template>
    <tr>
        <td>
            <AppSelect
                :id="getId('load-*-*-*-location', idParams)"
                :scope="transaction.id"
                :model-value="transaction.location_id"
                name="location_id"
                required
                placeholder="Location"
                :options="locations"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <AppSelect
                :id="getId('load-*-*-*-type', idParams)"
                :scope="transaction.id"
                :model-value="transaction.line_type"
                name="line_type"
                required
                placeholder="Type"
                :options="transaction.bill_type !== 'accessorial' ? rateTypes : accessorialRatesOptions"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <AppInput
                :id="getId('load-*-*-*-quantity', idParams)"
                :scope="transaction.id"
                :model-value="transaction.quantity"
                name="quantity"
                placeholder="Quantity"
                type="number"
                :min="0"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <AppInput
                :id="getId('load-*-*-*-amount', idParams)"
                :scope="transaction.id"
                :model-value="dashAccessorialTotal ? null : transaction.amount"
                name="amount"
                placeholder="Amount"
                type="number"
                :min="0"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <p class="whitespace-nowrap text-sm">{{ dashAccessorialTotal ? '-' : formatMoney(transaction.total) }}</p>
        </td>

        <td
            v-if="transaction.bill_type === 'accessorial'"
            class="md:w-20">
            <AppCheckbox
                :id="getId('load-*-*-*-bill', idParams)"
                :scope="transaction.id"
                :model-value="transaction.bill_to_customer"
                :name="'bill_to_customer'"
                label=""
                :stacked="true"
                :url="url"
                :disabled="invoiceReady"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td
            v-if="transaction.bill_type === 'accessorial'"
            class="md:w-20">
            <AppCheckbox
                :id="getId('load-*-*-*-pay-carrier', idParams)"
                :scope="transaction.id"
                :model-value="transaction.pay_carrier"
                :name="'pay_carrier'"
                label=""
                :stacked="true"
                :url="url"
                :disabled="paidCarrier"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td
            v-if="transaction.bill_type === 'accessorial'"
            class="md:w-20">
            <AppCheckbox
                :id="getId('load-*-*-*-receipt required', idParams)"
                :scope="transaction.id"
                :model-value="transaction.receipt_required"
                :name="'receipt_required'"
                label=""
                :stacked="true"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <AppInput
                :id="getId('load-*-*-*-note', idParams)"
                :scope="transaction.id"
                :model-value="transaction.note"
                name="note"
                placeholder="Note"
                type="text"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>

        <td>
            <AppButton
                :id="getId('load-*-*-*-remove-button', idParams)"
                variant="outline"
                icon="far fa-trash-can"
                type="button"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                class="ml-auto"
                @click="removeRate(transaction.id)" />
        </td>
    </tr>
</template>

<script setup>
import { router, usePage } from '@inertiajs/vue3';
import { fromPairs, values, zipObject } from 'lodash-es';
import { accessorialCharges } from '@/data.js';
import { formatMoney, getId } from '@/helpers';

const { load, transaction, permission, disableContext, overrideUrl } = defineProps({
    load: {
        type: Object,
        required: true
    },
    customer: {
        type: Object,
        default: () => {}
    },
    transaction: {
        type: Object,
        default: () => {}
    },
    permission: String,
    disableContext: Boolean,
    overrideUrl: {
        type: String,
        default: null
    }
});

const url = overrideUrl ?? route('loads.billing.update', { load, transaction });

const ratecon = useRateCon(toRef(() => load));
const confirmation = ratecon.confirmChange;

const dashAccessorialTotal = computed(
    () => !load.can.view_load_details && transaction.bill_to_customer && transaction.pay_carrier
);

const page = usePage();
const rateTypes = zipObject(values(page.props.options.rate_types), values(page.props.options.rate_types));

const accessorialRatesOptions = zipObject(accessorialCharges, accessorialCharges);

const locations = computed(() => fromPairs(load.stops.map(stop => [stop.location.id, stop.location.name])));

function removeRate(transaction) {
    router.delete(route('loads.billing.destroy', [load, transaction]), { preserveScroll: true, only: ['load'] });
}

const { can } = useAuth();
const unauthorized = computed(() => permission && !can(permission));
const isDisabled = computed(() => load.is_canceled_or_tonu || unauthorized.value || disableContext);
const invoiceReady = computed(
    () => load.is_canceled_or_tonu || unauthorized.value || load.invoiceReady || !load.can.view_load_details
);
const paidCarrier = computed(
    () => load.is_canceled_or_tonu || unauthorized.value || load.paidCarrier || !load.can.view_load_details
);

const idParams = [{ id: load.id }, { id: transaction.bill_type }, { id: transaction.id }];
</script>
