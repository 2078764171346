import { computed, provide } from 'vue';
import { useForm, usePage } from '@inertiajs/vue3';
import { debounce, values, zipObject } from 'lodash-es';
import { watchDebounced } from '@vueuse/core';
import { getId } from '@/helpers';

export function useLoadDetails(entity, options = {}) {
    const { entityType = 'load', usePermissions = true, useConfirmation = false, confirmChange = null } = options;

    const page = usePage();

    const equipmentTypes = computed(() =>
        zipObject(values(page.props.options.equipment_types), values(page.props.options.equipment_types))
    );

    const valueRanges = computed(() =>
        zipObject(values(page.props.options.value_ranges), values(page.props.options.value_ranges))
    );

    const transportationModes = computed(() =>
        zipObject(values(page.props.options.transportation_modes), values(page.props.options.transportation_modes))
    );

    const commodityTypes = computed(() => page.props.options.commodity_types);

    const commodityValues = computed(() =>
        zipObject(values(page.props.options.commodity_values), values(page.props.options.commodity_values))
    );

    const baseUrl = computed(() => {
        const routeName = entityType === 'load' ? 'loads' : 'quotes';
        return route(`${routeName}.update`, entity);
    });

    const generalNotesUrl = computed(() => {
        if (entityType === 'load') {
            return route('loads.general-notes.update', entity);
        } else {
            return route('provisionalLoads.general-notes.update', entity);
        }
    });

    const temperatureValue = computed(() => (entity.commodity_type === 'dry' ? null : entity.temperature));
    const temperatureUnitValue = computed(() => (entity.commodity_type === 'dry' ? null : entity.temperature_units));

    const tripMilesError = computed(() => {
        if (entity.trip_miles || entity.stops.length < 2) return null;
        return 'We are unable to calculate because one of the stops is invalid.';
    });

    const form = useForm({
        references: entity.references || []
    });

    provide('form', form);

    watchDebounced(
        form.references,
        () => {
            const updateRoute =
                entityType === 'load' ? route('loads.references.update', entity) : route('quotes.update', entity);

            form.patch(updateRoute, {
                preserveScroll: true
            });
        },
        { debounce: 1000 }
    );

    function removeReference(index) {
        form.references.splice(index, 1);
    }

    const addReference = debounce(() => {
        form.references.push({
            number: null,
            description: null,
            other: null
        });
    }, 1000);

    const tempDisabled = computed(() => {
        if (entityType === 'load') {
            return entity.is_canceled_or_tonu || entity.commodity_type === 'dry';
        } else {
            return entity.commodity_type === 'dry';
        }
    });

    const rateTypes = computed(() => {
        if (entityType === 'load') {
            return zipObject(values(page.props.options.load_rate_types), values(page.props.options.load_rate_types));
        }
        return {};
    });

    const isDisabled = computed(() => {
        if (entityType === 'load') {
            return entity.is_canceled_or_tonu || !entity.can.view_load_details;
        }
        return false;
    });

    const confirmation = useConfirmation ? confirmChange : null;

    return {
        // URLs
        url: baseUrl,
        generalNotesUrl,

        // Options
        equipmentTypes,
        valueRanges,
        transportationModes,
        commodityTypes,
        commodityValues,
        rateTypes,

        // Form and computed values
        form,
        temperatureValue,
        temperatureUnitValue,
        tripMilesError,

        // Reference management
        removeReference,
        addReference,

        // Load-specific properties
        tempDisabled,
        isDisabled,

        // Helper functions
        getId,

        // Permissions helper
        usePermissions,

        confirmation
    };
}
