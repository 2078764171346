<template>
    <section
        :id="getId('load-*-overview', [load])"
        class="flex items-center gap-4">
        <AppCard
            v-for="box in boxes"
            :key="box.title"
            :title="box.title"
            theme="blue"
            content-class="p-4 flex gap-4 text-sm whitespace-nowrap">
            <div
                v-for="cell in box.cells"
                :id="getId('load-*-overview-*-cell', [load, { id: kebabCase(cell.label) }])"
                :key="cell.label"
                class="flex-1">
                <div>
                    <strong>{{ cell.label }}</strong>
                </div>
                <div class="capitalize">{{ cell.value }}</div>
            </div>
        </AppCard>
    </section>
</template>

<script setup>
import { getId } from '@/helpers';
import { kebabCase } from 'lodash-es';

const { load } = defineProps({
    load: Object
});

const boxes = computed(() => {
    const data = [
        {
            title: 'Billing Details',
            cells: [
                { label: 'Total Billing Lines', value: '$' + load.total_customer_billing_amount },
                { label: 'Accessorials', value: '$' + load.total_accessorial_charges_amount },
                { label: 'Total Bill', value: '$' + load.total_invoice_amount }
            ]
        },
        {
            title: 'Load Details',
            cells: [
                {
                    label: 'Equipment',
                    value: load.required_equipment_type
                        ? [load.equipment_length + "'", load.required_equipment_type].join(' ')
                        : '-'
                },
                {
                    label: 'Temp',
                    value: load.temperature ? [load.temperature, load.temperature_units?.toUpperCase()].join(' ') : '-'
                },
                { label: 'Total Trip Miles', value: load.trip_miles ?? '-' },
                { label: 'Commodity Type', value: load.commodity_type ?? '-' },
                { label: 'Total Load Weight', value: load.total_load_weight ?? '-' }
            ]
        }
    ];

    if (!load.can.view_load_details) {
        data.shift(); // removes first element (billing details)
    }

    return data;
});
</script>
