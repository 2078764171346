<template>
    <header class="flex flex-col gap-3 rounded-md bg-white px-4 py-3 shadow md:flex-row md:items-center">
        <div class="flex items-center gap-3 text-iel-blue lg:mr-4">
            <Component
                :is="iconComponent"
                class="h-5 shrink-0 md:h-7" />
            <h2 class="text-xl font-bold text-iel-blue lg:text-3xl">
                {{ title }}
            </h2>
        </div>
        <div class="md:mr-auto">
            <slot name="search" />
        </div>
        <div class="md:mr-auto">
            <slot name="middle" />
        </div>
        <slot name="actions" />
    </header>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        required: true
    },
    iconComponent: {
        type: Object,
        required: true
    }
});
</script>
