import { ref, nextTick, onMounted, onUnmounted } from 'vue';

export default function useTeleport({ closeEventName = 'close-all-dropdowns' } = {}) {
    const isOpen = ref(false);
    const triggerRef = ref(null);
    const dropdownRef = ref(null);
    const dropdownPosition = ref({ top: '0px', left: '0px', position: 'fixed' });

    const updatePosition = () => {
        const trigger = triggerRef.value;
        const dropdown = dropdownRef.value;
        if (!trigger || !dropdown) return;

        const rect = trigger.getBoundingClientRect();
        const dropdownHeight = dropdown.offsetHeight;
        const viewportHeight = window.innerHeight;

        const wouldOverflowBottom = rect.bottom + dropdownHeight > viewportHeight;

        const topPosition = wouldOverflowBottom ? `${rect.top - dropdownHeight}px` : `${rect.bottom}px`;

        dropdownPosition.value = {
            top: topPosition,
            left: `${rect.right - dropdown.offsetWidth}px`,
            position: 'fixed'
        };
    };

    const close = () => {
        isOpen.value = false;
    };

    const handleKeydown = event => {
        if (event.key === 'Escape' && isOpen.value) {
            event.preventDefault();
            event.stopPropagation();
            close();
        }
    };

    const handleEvents = action => {
        document[`${action}EventListener`]('click', handleClickOutside);
        document[`${action}EventListener`]('keydown', handleKeydown);
        window[`${action}EventListener`]('scroll', updatePosition);
        window[`${action}EventListener`]('resize', updatePosition);
    };

    const handleClickOutside = event => {
        if (isOpen.value && !dropdownRef.value?.contains(event.target) && !triggerRef.value?.contains(event.target)) {
            close();
        }
    };

    const toggle = async () => {
        if (isOpen.value) {
            close();
            handleEvents('remove');
        } else {
            window.dispatchEvent(new CustomEvent(closeEventName));
            isOpen.value = true;
            await nextTick();
            updatePosition();
            handleEvents('add');
        }
    };

    onMounted(() => window.addEventListener(closeEventName, close));
    onUnmounted(() => {
        window.removeEventListener(closeEventName, close);
        if (isOpen.value) handleEvents('remove');
    });

    return { isOpen, triggerRef, dropdownRef, dropdownPosition, toggle, close };
}
