<template>
    <div
        ref="dropdown"
        class="relative"
        aria-haspopup="true"
        :aria-expanded="open"
        role="button"
        @click="toggleDropdown">
        <slot name="trigger" />
        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0">
            <div
                v-show="open"
                :class="[
                    'absolute z-50 rounded-md border border-iel-light-gray shadow-lg',
                    computedAlignment,
                    contentWidth
                ]"
                role="menu"
                :inert="!open"
                @click="handleContentClick">
                <div class="w-full rounded-md bg-white ring-1 ring-black ring-opacity-5">
                    <slot name="content" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import axios from 'axios';

const alignments = {
    'top-right': 'origin-top-right right-0 bottom-8',
    'bottom-right': 'origin-bottom-right top-8 right-0',
    'top-left': 'origin-top-left left-0 bottom-8',
    'bottom-left': 'origin-bottom-left top-8 left-0'
};

const { align, preventCloseOnContentClick, contentWidth, load, checkMacropoint } = defineProps({
    align: { type: String, default: 'top-right' },
    preventCloseOnContentClick: { type: Boolean, default: false },
    contentWidth: { type: String, default: 'w-60', required: false },
    load: {
        type: Object,
        required: false
    },
    checkMacropoint: {
        type: Boolean,
        default: false
    }
});

const computedAlignment = computed(() => alignments[align] || alignments['top-right']);

const open = ref(false);
const dropdown = ref(null);

const toggleDropdown = e => {
    e.stopPropagation();

    if (!open.value) {
        window.dispatchEvent(new CustomEvent('close-all-dropdowns', { detail: dropdown.value }));
    }

    if (checkMacropoint) {
        axios.get(route('loads.checkMacropoint', load)).then(function (response) {
            load.can.create_macropoint_order = response.data;
        });
    }

    open.value = !open.value;
};

const handleContentClick = e => {
    if (preventCloseOnContentClick) {
        e.stopPropagation();
    }
};

const handleOutsideClick = e => {
    if (open.value && !dropdown.value.contains(e.target)) {
        open.value = false;
    }
};

const closeDropdown = e => {
    const clickedDropdown = e.detail;
    if (clickedDropdown !== dropdown.value) {
        open.value = false;
    }
};

const handleKeydown = e => {
    if (open.value && e.key === 'Escape') {
        open.value = false;
    }
};

onMounted(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('keydown', handleKeydown);

    window.addEventListener('close-all-dropdowns', closeDropdown);

    router.on('navigate', () => {
        open.value = false;
    });
});

onUnmounted(() => {
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('keydown', handleKeydown);

    window.removeEventListener('close-all-dropdowns', closeDropdown);
});
</script>
