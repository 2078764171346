<template>
    <div id="quotes-list">
        <Component
            :is="generalboard.layout === 'list' ? QuoteList : QuoteCards"
            :quotes="quotes.data" />
    </div>

    <AppPagination :meta="quotes.meta" />
</template>

<script setup>
import { generalboard } from '@/stores';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import QuotesLayout from '@/Layouts/QuotesLayout.vue';
import QuoteCards from '@/Pages/Quotes/Partials/QuoteCards.vue';
import QuoteList from '@/Pages/Quotes/Partials/QuoteList.vue';

defineOptions({
    layout: [AuthenticatedLayout, QuotesLayout]
});

const { quotes } = defineProps({
    quotes: {
        type: Object,
        required: true,
        default: () => {}
    }
});
</script>
