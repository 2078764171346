<template>
    <tr @keyup.esc="toggleEdit">
        <td>
            <div class="my-2 sm:my-0">
                <div class="flex items-start gap-x-2">
                    <AppInput
                        v-if="editing"
                        v-model="form.file_name"
                        name="file_name"
                        label="Name"
                        required
                        class="w-full" />
                    <p
                        v-else
                        class="border border-transparent py-1 text-sm font-semibold leading-6 text-iel-darkest-gray"></p>
                </div>
                <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-iel-dark-gray">
                    <div>
                        <p class="whitespace-nowrap">
                            <time :datetime="document.updated_at">{{ formatDate(document.updated_at) }}</time>
                        </p>
                        <p class="whitespace-nowrap">
                            <span>By: {{ document.submitted_by }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </td>

        <td>{{ document.size }}</td>

        <td class="lg:w-1/4">
            <div class="my-2 flex flex-col items-start gap-1 sm:my-0">
                <AppSelectTags
                    v-if="editing"
                    id="select-labels-load-docs"
                    v-model="form.labels"
                    :placeholder="$t('Select Labels')"
                    name="labels"
                    :options="loadDocumentTypes"
                    :taggable="false"
                    class="w-full"
                    required />

                <ul
                    v-else
                    :id="`load-card-document-labels-for-${document.file_name}-${document.id}`"
                    class="flex flex-wrap gap-1">
                    <li
                        v-for="label in document.labels"
                        :key="label"
                        class="mt-0.5 flex place-content-center whitespace-nowrap rounded-md px-1.5 py-0.5 text-sm font-bold ring-1 ring-inset"
                        :class="{
                            'bg-iel-lightest-green text-iel-dark-green ring-iel-green/20':
                                label !== 'Last Saved RateCon',

                            'bg-iel-light-red text-iel-dark-red ring-iel-red/20': label === 'Last Saved RateCon'
                        }">
                        {{ label }}
                    </li>
                </ul>
            </div>
        </td>

        <td class="lg:w-1/4">
            <div class="my-2 flex flex-col items-start gap-1 sm:my-0">
                <AppSelectTags
                    v-if="editing"
                    id="select-stops-load-docs"
                    v-model="form.stops"
                    :placeholder="$t('Select stops')"
                    name="stops"
                    :options="stopsOptions"
                    class="w-full"
                    :taggable="false"
                    required />

                <template v-else>
                    <div
                        v-for="stop in stopsOptions.filter(stop => (document.stops || []).includes(stop.value))"
                        :key="stop">
                        <span
                            class="rounded-md bg-iel-lightest-green px-1.5 py-0.5 text-sm font-bold text-iel-dark-green ring-1 ring-inset ring-iel-green/20">
                            {{ stop.label }}
                        </span>
                    </div>
                </template>
            </div>
        </td>

        <td>
            <div
                id="load-details-docs-action-list"
                class="my-2 flex gap-x-2 sm:my-0">
                <AppButton
                    v-if="!editing"
                    :id="`document-list-${document.id}-actions-edit`"
                    variant="outline"
                    icon="fal fa-pen"
                    permission="update_load_documents"
                    :disabled="!load.can.view_load_details"
                    @click="toggleEdit">
                    <span class="sr-only">Edit {{ document.file_name }}</span>
                </AppButton>

                <AppButton
                    v-else
                    :id="`document-list-${document.id}-actions-editing`"
                    variant="outline"
                    icon="fal fa-floppy-disk"
                    permission="update_load_documents"
                    @click="saveEdit">
                    <span class="sr-only">Save Edit {{ document.file_name }}</span>
                </AppButton>

                <AppButton
                    :id="`documents-list-${document.id}-actions-view-document-button`"
                    variant="outline"
                    icon="fal fa-eye"
                    @click="open">
                    <span class="sr-only">View {{ document.file_name }}</span>
                </AppButton>

                <AppButton
                    :id="`document-list-${document.id}-actions-download`"
                    :href="route('loads.documents.download', [load, document])"
                    :download="`${document.file_name}`"
                    variant="outline"
                    icon="fal fa-file-arrow-down">
                    <span class="sr-only">Download {{ document.file_name }}</span>
                </AppButton>

                <AppButton
                    :id="`document-list-${document.id}-actions-delete`"
                    variant="outline"
                    class="border-iel-darkest-red text-iel-darkest-red"
                    icon="fal fa-trash-can"
                    permission="delete_load_documents"
                    :disabled="document.loading || !load.can.view_load_details"
                    @click="confirmDelete(document)">
                    <span class="sr-only">Delete {{ document.file_name }}</span>
                </AppButton>

                <AppIcon
                    v-if="isLoading"
                    name="fal fa-arrows-rotate"
                    class="m-2 h-4 w-4 animate-spin text-iel-blue" />
            </div>
        </td>
    </tr>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useForm, router } from '@inertiajs/vue3';
import { loadDocumentTypes } from '@/data.js';
import { formatDate } from '@/helpers.js';

const { load, document } = defineProps({
    load: Object,
    document: Object
});

const url = route('loads.documents.update', [load, document]);

const editing = ref(false);

const isLoading = ref(false);

const stopsOptions = computed(() => {
    return (load.stops || []).map((stop, index) => {
        return {
            label: `Stop # ${index + 1} (${stop.location.name})`,
            value: stop.id
        };
    });
});

const form = useForm({
    file_name: document.file_name,
    labels: [...document.labels],
    stops: stopsOptions.value.filter(stop => (document.stops || []).includes(stop.value))
});

function toggleEdit() {
    editing.value = true;
}

function saveEdit() {
    if (editing.value) {
        isLoading.value = true;
        router.patch(
            url,
            {
                file_name: form.file_name,
                labels: form.labels,
                stops: form.stops.map(stop => stop.value)
            },
            {
                preserveScroll: true,
                onSuccess() {
                    editing.value = false;
                    isLoading.value = false;
                }
            }
        );
    }
}

function open() {
    usePopup().open(
        route('loads.documents.show', {
            load: load,
            media: document,
            embed: true
        })
    );
}

function confirmDelete() {
    if (confirm(`Are you sure you would like to delete "${document.file_name}"?`)) {
        document.loading = true;

        router.delete(route('loads.documents.destroy', [load, document]), {
            preserveScroll: true
        });
    }
}
</script>
