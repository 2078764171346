<template>
    <div class="flex flex-col">
        <nav
            class="small-scrollbar flex h-full items-center gap-3 text-nowrap"
            :class="light === true ? 'rounded-t-md bg-iel-blue px-4' : ''">
            <button
                v-for="(tab, index) in tabs"
                :key="index"
                type="button"
                class="flex h-full cursor-pointer items-center border-b-4 px-1 font-semibold"
                :class="[
                    activeTab === tab.title
                        ? light
                            ? 'border-iel-lightest-gray text-iel-lightest-gray'
                            : 'border-iel-blue text-iel-blue'
                        : light
                          ? 'border-transparent text-iel-lightest-gray'
                          : 'border-transparent text-iel-gray',
                    light ? 'text-md leading-[3]' : 'text-base'
                ]"
                data-test="button"
                @click="activateTab(tab)">
                {{ tab.title }}
                <AppBadge
                    v-if="tab.badge"
                    class="absolute -right-5 -top-1">
                    {{ tab.badge }}
                </AppBadge>
            </button>
        </nav>
        <div
            class="grow overflow-y-auto"
            tabindex="0">
            <slot />
        </div>
    </div>
</template>

<script setup>
defineProps({
    activeClass: String,
    light: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['activatedTab']);

const activeTab = ref('');
const tabs = ref([]);

provide('tabs', tabs);
provide('activeTab', activeTab);

function activateTab(tab) {
    activeTab.value = tab.title;
    emit('activatedTab', tab);
}

defineExpose({ activateTab, tabs });
</script>
