export function useVinValidation() {
    function validateVin(vinNumber) {
        if (!vinNumber || vinNumber.trim() === '') {
            return '';
        }

        if (vinNumber.length !== 17) {
            return `${vinNumber.length < 17 ? 'Too Short' : 'Too Long'}: VIN must be exactly 17 characters.`;
        }

        const format = /[iolIQO`,.~!@#$%^&*()_+=<>?/;:'"{}|\\[\]`\\-]/g;

        const excludedChars = vinNumber.match(format);
        if (excludedChars) {
            return `Invalid characters found: "${excludedChars.join('" , "')}"`;
        }

        return '';
    }

    return { validateVin };
}
