<template>
    <div class="relative flex items-center gap-3">
        <p
            class="text-iel-light-gray"
            v-text="load.is_tonu ? 'TONU' : ''"></p>
        <div
            v-if="pastDueCount > 0 || warningCount > 0"
            class="group">
            <AppWarningIcon
                size="md"
                class="mr-1" />
            <span
                class="invisible absolute right-2 z-10 w-36 overflow-hidden text-pretty rounded-md bg-iel-blue p-1 text-center text-white group-hover:visible">
                {{ pastDueOrWarningTooltipText }}
            </span>
        </div>

        <div v-if="load.all_risk && !load.all_risk?.resolved_at">
            <AppIcon
                name="fas fa-shield-exclamation"
                class="text-iel-blue" />
        </div>

        <div v-if="load.all_risk?.resolution == 'approved'">
            <AppIcon
                name="fas fa-shield-check"
                class="text-iel-blue" />
        </div>
        <div v-if="load.all_risk?.resolution == 'denied'">
            <AppIcon
                name="fas fa-shield-xmark"
                class="text-iel-blue" />
        </div>
        <AppIcon
            v-if="load.claim"
            name="fas fa-gavel"
            class="text-iel-blue" />
        <AppIcon
            v-if="load.carrier_incident"
            name="fas fa-handshake"
            class="text-iel-blue" />
    </div>
</template>

<script setup>
const { load } = defineProps({
    load: {
        type: Object,
        required: true,
        default: () => {}
    }
});

const now = new Date();

const warningCount = load.tasks.filter(
    task =>
        task.due_at &&
        !task.completed_at &&
        task.warning_minutes !== 0 &&
        now >= new Date(new Date(task.due_at).getTime() - task.warning_minutes * 60000) &&
        now < new Date(task.due_at)
).length;

const pastDueCount = computed(() => {
    const now = new Date();
    return load.tasks.filter(task => task.due_at && !task.completed_at && now >= new Date(task.due_at)).length;
});

const pastDueOrWarningTooltipText = computed(() => {
    const messages = [];

    if (pastDueCount.value > 0) {
        messages.push(`${pastDueCount.value} overdue task(s)`);
    }

    if (warningCount.value > 0) {
        messages.push(`${warningCount.value} task(s) due soon`);
    }

    return messages.join(', ');
});
</script>
