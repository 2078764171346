<template>
    <AppCard
        title="EDI"
        theme="light_gray"
        content-class="p-2">
        <template #nav>
            <AppButton
                :disabled="customer.edi_request_submitted_at"
                @click="openCustomerEDISetupRequestModal(customer)">
                {{ $t('EDI Setup Request') }}
            </AppButton>
        </template>

        <AppTabs>
            <AppTab
                :id="getId('customer-*-edi-partners-tab', [customer])"
                title="Partners"
                :active="true"
                class="grid gap-4">
                <div
                    :id="getId('customer-*-edi-partner-info-card', [customer])"
                    :title="$t('EDI Support Contact')"
                    class="my-4 space-y-4 p-2">
                    <CustomerEdiContacts :customer="customer" />

                    <CustomerEdiDocTypes
                        :customer="customer"
                        :options="options.documents" />
                </div>
            </AppTab>

            <AppTab
                :id="getId('customer-*-edi-connections-tab', [customer])"
                :title="$t('Connections')"
                class="my-4 space-y-4 p-2">
                <CustomerEdiConnections
                    :customer="customer"
                    :connection-modes="options.connectionModes"
                    :connection-reasons="options.connectionReasons"
                    :connection-types="options.connectionTypes"
                    :hashing-algorithms="options.hashingAlgorithms"
                    :encryption-algorithms="options.encryptionAlgorithms"
                    :partner-vendors="options.partnerVendors" />
            </AppTab>
        </AppTabs>
    </AppCard>
</template>

<script setup>
import { getId } from '@/helpers';

const { customer, options } = defineProps({
    customer: {
        type: Object,
        required: true
    },
    options: {
        type: Object,
        default: () => ({
            documents: {},
            connectionModes: [],
            connectionReasons: [],
            connectionTypes: {},
            hashingAlgorithms: {},
            encryptionAlgorithms: {},
            partnerVendors: []
        }),
        required: true
    }
});

const { open: CustomerEDISetupRequestModal } = useModal('CustomerEDISetupRequestModal');

const openCustomerEDISetupRequestModal = row => {
    CustomerEDISetupRequestModal({
        modalId: 'customer-edi-setup-request-modal',
        title: 'EDI Setup Request',
        ticketType: 'EDI Setup',
        customer: row
    });
};
</script>
