<template>
    <div>
        <AppHeading
            :id="getId('load-*-billing-numbers-heading', [load])"
            size="xl">
            {{ $t('Customer Billing Numbers') }}
        </AppHeading>

        <div
            :id="getId('load-*-billing-numbers', [load])"
            class="my-4 grid grid-cols-4 gap-4">
            <div
                v-for="billingNumber in load.customer_billing_numbers"
                :key="billingNumber.description + billingNumber.number"
                class="flex flex-col">
                <p class="mb-2 font-bold">{{ billingNumber.description }}</p>
                <p class="mt-auto">{{ billingNumber.number }}</p>
            </div>
        </div>
        <!-- TODO: Will be turned into a tabbed component in PX-2787 -->
        <AppTextarea
            :id="getId('load-*-billing-notes', [load])"
            v-model="load.billing_notes"
            permission="update_load_billing_details"
            name="billing_notes"
            label="Internal Billing Notes"
            input-class="resize-none"
            :rows="textAreaRows"
            :url="url"
            :disabled="isDisabled" />
    </div>
</template>

<script setup>
import { getId } from '@/helpers.js';

const { load } = defineProps({
    load: Object,
    textAreaRows: {
        type: Number,
        default: 6
    }
});

const isDisabled = computed(
    () =>
        load.is_canceled_or_tonu ||
        load.tasks.filter(task => task.name === 'ready_to_be_invoiced').completed_at !== null
);

const url = route('loads.update', load);
</script>
