<template>
    <div class="flex items-center gap-4">
        <AppCard
            v-for="box in boxes"
            :key="box.title"
            :title="box.title"
            theme="blue"
            content-class="p-4 grid grid-cols-2 gap-4 text-sm whitespace-nowrap">
            <div
                v-for="cell in box.cells"
                :key="cell.label"
                class="w-1/2">
                <div>
                    <strong>{{ cell.label }}</strong>
                </div>
                <div class="capitalize">{{ cell.value }}</div>
            </div>
        </AppCard>
    </div>
</template>

<script setup>
import { formatMoney } from '@/helpers';

const { load } = defineProps({
    load: Object
});

const loadStats = useLoadStats(load);

const boxes = computed(() => {
    const data = [
        {
            title: 'Billing Details',
            hidden: !load.can.view_load_details ? true : false,
            cells: [
                {
                    label: 'Margin',
                    value: !load.can.view_load_details ? '-' : loadStats.margin.value.toFixed(2) + '%'
                },
                {
                    label: 'Profit',
                    value: !load.can.view_load_details ? '-' : formatMoney(loadStats.profit.value)
                },
                { label: 'Billing Lines', value: formatMoney(loadStats.customerBilling.value) },
                { label: 'Accessorials', value: formatMoney(loadStats.customerAccessorials.value) },
                { label: 'Total Bill', value: formatMoney(loadStats.customerBillingTotal.value) }
            ]
        },
        {
            title: 'Carrier Pay Details',
            hidden: false,
            cells: [
                { label: 'Pay Truck', value: formatMoney(loadStats.carrierPay.value) },
                { label: 'Accessorials', value: formatMoney(loadStats.carrierAccessorials.value) },
                { label: 'Advances', value: formatMoney(loadStats.carrierAdvances.value) },
                { label: 'Total Pay Truck', value: formatMoney(loadStats.carrierTotalPay.value) },
                { label: 'Total 3rd Party', value: formatMoney(loadStats.carrierAdvancesThirdParty.value) }
            ]
        },
        {
            title: 'Load Details',
            hidden: false,
            cells: [
                {
                    label: 'Equipment',
                    value: load.required_equipment_type
                        ? [load.equipment_length + "'", load.required_equipment_type].join(' ')
                        : '-'
                },
                {
                    label: 'Temp',
                    value: load.temperature ? [load.temperature, load.temperature_units?.toUpperCase()].join(' ') : '-'
                },
                { label: 'Total Trip Miles', value: load.trip_miles ?? '-' },
                { label: 'Commodity', value: load.commodity_value ?? '-' },
                {
                    label: 'Pallets/Pieces',
                    value: `${load.total_pallets ?? '-'}/${load.total_pieces ?? '-'}`
                },
                { label: 'Total Load Weight', value: load.total_load_weight ?? '-' }
            ]
        }
    ];

    if (!load.can.view_load_details) {
        data.shift(); // removes first element (billing details)
    }

    return data;
});
</script>
