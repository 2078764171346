<template>
    <AppTablePill
        id="active-tenders-table"
        title="Active Tenders"
        :columns="[
            { name: 'edi_shipment_id', label: $t('Shipment ID #') },
            { name: 'customer', label: $t('Customer') },
            { name: 'number_of_stops', label: $t('# of Stops') },
            { name: 'weight', label: $t('Weight') },
            { name: 'trip_miles', label: $t('Total Miles') },
            { name: 'equipment', label: $t('Equipment') },
            { name: 'lane', label: $t('Lane') },
            { name: 'date_time', label: $t('Date/Time') },
            { name: 'rate', label: $t('Rate') },
            { name: 'expires_at', label: $t('Expiration') },
            { name: 'actions', label: '' },
            { name: 'checkbox', label: '' }
        ]"
        data-key="tenders"
        :data="active.data"
        :classes="{ row: 'cursor-pointer' }"
        @row-click="openTender">
        <template #customer-cell="{ row }">
            <Link
                :href="route('customers.show', row.id)"
                class="font-bold text-iel-blue underline">
                {{ row.customer?.name }}
            </Link>
        </template>
        <template #number_of_stops-cell="{ row }">
            <p>{{ row.stops.length }}</p>
        </template>
        <template #weight-cell="{ row }">
            <div
                v-if="row.stops"
                class="text-sm">
                {{ row.total_load_weight }} {{ row.total_load_weight_description }}
            </div>
        </template>
        <template #trip_miles-cell="{ row }">
            <p v-if="row.trip_miles">{{ row.trip_miles }} mi</p>
        </template>
        <template #equipment-cell="{ row }">
            <p>{{ row.equipment_length }}' {{ row.required_equipment_type }}</p>
        </template>
        <template #lane-cell="{ row }">
            <div
                v-if="formatLane(row)"
                class="space-y-1">
                <p class="text-sm">{{ formatLane(row).origin }}</p>
                <p class="text-sm">{{ formatLane(row).destination }}</p>
            </div>
        </template>
        <template #date_time-cell="{ row }">
            <div
                v-if="formatLane(row)"
                class="space-y-1">
                <p class="text-sm">{{ formatLane(row).startDate }}</p>
                <p class="text-sm">{{ formatLane(row).endDate }}</p>
            </div>
        </template>
        <template #rate-cell="{ row }">
            <p>{{ formatMoney(row.rate) }}</p>
        </template>
        <template #actions-cell="{ row }">
            <div class="flex w-full justify-end gap-4">
                <TenderTasks
                    :tender="row"
                    :options="options" />
            </div>
        </template>
        <template #checkbox-cell="{ row }">
            <div class="flex w-full justify-end gap-4">
                <AppCheckbox
                    :disabled="row.edi_tender.accepted_at !== null || row.edi_tender.rejected_at !== null"
                    name="tender_action_checkbox"
                    label=""
                    @click.stop="addTender(row)" />
            </div>
        </template>
    </AppTablePill>
    <div class="mb-4 inline-flex w-full justify-between">
        <AppPagination
            :meta="active.meta"
            variant="beginning" />
        <div
            v-if="active.data.length > 0"
            class="mr-2 flex items-end space-x-2">
            <AppButton
                @click.stop="acceptTenders"
                :disabled="!bulkSelect.length"
                :id="getId('active-tender-*-accept-bid', [active])"
                name="accept_bid"
                icon="far fa-circle-check">
                {{ $t(bulkSelect.length === 0 ? 'Accept' : `Accept (${bulkSelect.length})`) }}
            </AppButton>
            <AppButton
                @click.stop="rejectTenders"
                :disabled="!bulkSelect.length"
                :id="getId('active-tender-*-reject-bid', [active])"
                name="reject_bid"
                icon="far fa-circle-xmark">
                {{ $t(bulkSelect.length === 0 ? 'Reject' : `Reject (${bulkSelect.length})`) }}
            </AppButton>
        </div>
    </div>
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import QuotesLayout from '@/Layouts/QuotesLayout.vue';
import { formatLane, formatMoney, getId } from '@/helpers';
import { router } from '@inertiajs/vue3';
import Swal from 'sweetalert2';
const { open: rejectTenderModal } = useModal('RejectTenderModal');

defineOptions({
    layout: [AuthenticatedLayout, QuotesLayout]
});

const { active, options } = defineProps({
    active: {
        type: Object,
        required: true,
        default: () => {}
    },
    options: {
        type: Object,
        required: true,
        default: () => ({
            rejection_reasons: []
        })
    }
});

const bulkSelect = ref([]);

function addTender(row) {
    const index = bulkSelect.value.indexOf(row.tender_id);
    if (index === -1) {
        bulkSelect.value.push(row.tender_id);
    } else {
        bulkSelect.value.splice(index, 1);
    }
}

function openTender(row) {
    router.visit(route('tenders.show', row.id));
}

function acceptTenders() {
    router.post(
        route('tenders.bulkAccept'),
        { tender_ids: bulkSelect.value },
        {
            preserveScroll: true,
            onSuccess: page => {
                bulkSelect.value = [];
                Swal.fire({
                    title: 'Tenders Accepted',
                    text: page.props.response.message
                });
            },
            onError: e => {
                return Swal.fire({
                    title: 'Unable to Accept Tender',
                    text: e.message || 'Failed',
                    icon: 'error'
                });
            }
        }
    );
}

function rejectTenders() {
    rejectTenderModal({
        tender_ids: bulkSelect.value,
        rejection_reasons: options.rejection_reasons
    });
}
</script>
