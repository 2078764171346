<template>
    <AppModal
        id="carrier-vehicle-create-modal"
        :show="isOpen"
        :title="context.title"
        class="max-w-2xl"
        @close="close"
        @closed="onClosed">
        <div class="text-md mb-4 font-serif italic text-iel-dark-gray">
            A VIN must be 17 alphanumeric characters, excluding the letters I, O, and Q.
        </div>
        <form
            class="contents"
            @submit.prevent="submit">
            <table>
                <thead>
                    <tr>
                        <th><AppHeading class="text-left">Vehicle VIN</AppHeading></th>
                        <th><AppHeading>Scheduled Auto</AppHeading></th>
                    </tr>
                </thead>
                <tr
                    v-for="(vehicle, index) in vehicles"
                    :key="index">
                    <td class="py-1">
                        <AppInput
                            id="carrier-vehicle-vin-input-create"
                            v-model="vehicle.vin"
                            class="flex-1"
                            label=""
                            name="vin"
                            required
                            uppercase />
                        <AppErrorMessage
                            :name="`vehicles.${index}.vin`"
                            :error="vehicleErrors[index]?.vin" />
                        <AppErrorMessage
                            :name="`vin-checker-error-${index}`"
                            :error="vehicleErrors[index]?.vinCheckerError" />
                        <p>{{ vehicleErrors[index]?.vinCheckerError }}</p>
                    </td>
                    <td>
                        <AppCheckbox
                            id="carrier-vehicle-scheduled-auto-checkbox"
                            v-model="vehicle.scheduled_auto_only"
                            name="scheduled_auto_only"
                            centered
                            label="" />
                    </td>
                    <td>
                        <AppButton
                            icon="far fa-trash-can"
                            variant="outline"
                            @click="removeRow(index)" />
                    </td>
                </tr>
            </table>

            <div>
                <AppButton
                    id="carrier-vehicle-add-row"
                    icon="far fa-circle-plus"
                    @click="addRow">
                    Add Row
                </AppButton>
            </div>

            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    id="save-carrier-vehicle"
                    type="submit"
                    :disabled="hasErrors">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { router } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CarrierVehicleCreateModal');
const { validateVin } = useVinValidation();

const vehicleTemplate = { vin: null, scheduled_auto_only: false };
const vehicles = ref([]);
const vehicleErrors = ref([]);

function addRow() {
    vehicles.value.push(structuredClone(vehicleTemplate));
    vehicleErrors.value.push({});
}

function removeRow(index) {
    vehicles.value.splice(index, 1);
    vehicleErrors.value.splice(index, 1);
}

watch(
    vehicles,
    () => {
        vehicleErrors.value = vehicleErrors.value.map(() => ({}));
        vehicles.value.forEach((vehicle, index) => {
            const vinError = validateVin(vehicle.vin);
            if (vinError) {
                vehicleErrors.value[index].vin = vinError;
            }
        });
    },
    { deep: true }
);

watch(isOpen, () => {
    vehicles.value = [structuredClone(vehicleTemplate)];
    vehicleErrors.value = [{ vin: '' }];
});

const hasErrors = computed(
    () => Array.isArray(vehicleErrors.value) && vehicleErrors.value.some(err => Object.keys(err).length > 0)
);

function submit() {
    vehicleErrors.value = [];

    let isValid = true;
    vehicles.value.forEach((vehicle, index) => {
        const vinError = validateVin(vehicle.vin);
        if (vinError) {
            isValid = false;
            vehicleErrors.value[index] = { vin: vinError };
        }
    });

    if (isValid) {
        router.post(
            route('carriers.vehicles.store', {
                carrier: context.value.carrier
            }),
            {
                vehicles: vehicles.value
            },
            {
                preserveScroll: true,
                onSuccess() {
                    close();
                },
                onError(e) {
                    vehicleErrors.value = e;
                }
            }
        );
    }
}
</script>
