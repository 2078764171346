<script setup>
defineProps({
    href: {
        type: String,
        required: true
    }
});
</script>

<template>
    <Link
        :href="href"
        class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none">
        <slot />
    </Link>
</template>
