<template>
    <section
        :id="getId('load-*-stops-*-box-activity', [load, stop])"
        class="flex flex-col gap-4">
        <div>
            <LoadTaskbar v-bind="{ load, tasks, triggersTabChange: false }" />
            <div class="grid w-96 grid-cols-3">
                <div
                    v-for="task in tasks"
                    :key="task.id"
                    class="text-xs text-iel-dark-gray">
                    {{ task.completed_at }}
                </div>
            </div>
        </div>
        <div class="flex gap-2">
            <AppButton
                :id="getId('load-*-stops-*-box-activity-add-task', [load, stop])"
                icon="far fa-circle-plus"
                @click="createTask">
                {{ $t('Task') }}
            </AppButton>
            <AppButton
                :id="getId('load-*-stops-*-box-activity-add-checkcall', [load, stop])"
                icon="far fa-circle-plus"
                @click="createCheckcall">
                {{ $t('Checkcall') }}
            </AppButton>
        </div>
        <div>
            <AppInput
                v-model="stop.internal_note"
                permission="update_stops"
                name="internal_note"
                :scope="stop.id"
                :url="url"
                label="Internal Stop Note" />
        </div>
    </section>
</template>

<script setup>
import { getId } from '@/helpers';
import { last, endsWith, groupBy } from 'lodash-es';

const { stop, load } = defineProps({
    stop: Object,
    load: Object
});

const url = route('stops.update', stop);

const tasksByPhase = computed(() => groupBy(load.tasks, 'phase'));

const tasks = computed(() => {
    return tasksByPhase.value['ship']
        .filter(task => {
            return task.stop_id === stop.id;
        })
        .map(task => {
            const completedTaskTitles = {
                pickup: 'Loaded',
                dropoff: 'Unloaded'
            };

            const updatedTitle = endsWith(task.name, 'completed')
                ? completedTaskTitles[stop.type]
                : endsWith(task.name, 'arrived') || endsWith(task.name, 'departed')
                  ? last(task.title.split(' '))
                  : task.title;

            return {
                ...task,
                title: updatedTitle
            };
        });
});

function createCheckcall() {
    useModal('LoadCheckcallModal').open({
        mode: 'create',
        load: load,
        stop: stop,
        defaults: { event: 'En Route' }
    });
}

function createTask() {
    useModal('LoadTaskModal').open({
        mode: 'create',
        load: load,
        stop: stop
    });
}
</script>
