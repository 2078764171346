<template>
    <div>
        <AppHeading :size="headingSize">
            {{ $t('Customer Billing Lines') }}
        </AppHeading>

        <table
            v-if="load.customerTransactions.length"
            :id="getId('load-*-customer-*-billing-rates-table', [load, customer])"
            class="NX-mobile-stacked w-full">
            <thead>
                <tr>
                    <th>{{ $t('Location') }}</th>
                    <th>{{ $t('Type') }}</th>
                    <th>{{ $t('Quantity') }}</th>
                    <th>{{ $t('Amount') }}</th>
                    <th>{{ $t('Total') }}</th>
                    <th>{{ $t('Note') }}</th>
                </tr>
            </thead>

            <tbody>
                <LoadTransaction
                    v-for="rate in customerTransactions"
                    :key="rate.id"
                    permission="update_load_billing_details"
                    :load="load"
                    :transaction="rate"
                    :override-url="route('loads.customers.transactions.update', [load.id, customer.id, rate.id])"
                    :disable-context="load.invoiceReady || !load.can.view_load_details" />

                <tr class="font-bold">
                    <td colspan="8">
                        <AppHeading
                            size="md"
                            class="mr-4 mt-4 flex justify-end">
                            {{ $t('Total') }} {{ formatMoney(load.total_customer_billing_amount) }}
                        </AppHeading>
                    </td>
                </tr>
            </tbody>
        </table>

        <div
            v-else
            class="text-sm">
            {{ $t('This customer has no customer billing line items on this load.') }}
        </div>

        <div class="mt-2 flex items-center justify-between gap-2">
            <AppButton
                id="load-add-customer-rate-button"
                type="button"
                icon="far fa-circle-plus"
                permission="update_load_billing_details"
                :disabled="
                    load.is_canceled_or_tonu || Boolean(load.billingDetail?.invoiced_at) || !load.can.view_load_details
                "
                @click="debouncedAddCustomerRate">
                {{ $t('Customer Billing Line') }}
            </AppButton>
        </div>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { debounce } from 'lodash-es';
import { formatMoney, getId } from '@/helpers';

const { load, customer } = defineProps({
    load: {
        type: Object,
        required: true
    },
    customer: {
        type: Object,
        required: true
    },
    headingSize: {
        type: String,
        default: 'lg'
    }
});

const customerTransactions = computed(() => {
    return load.customerTransactions.filter(item => {
        return customer.statement_ids.includes(item.invoice_id);
    });
});

function addCustomerRate() {
    router.post(
        route('loads.customers.transactions.store', [load.id, customer.id]),
        {},
        {
            preserveScroll: true,
            only: ['load']
        }
    );
}

const debouncedAddCustomerRate = debounce(addCustomerRate, 300);
</script>
