<template>
    <AppTablePill
        :columns="[
            { name: 'name', label: $t('Name'), sortable: true },
            { name: 'external_customer_name', label: $t('Contact') },
            { name: 'phone_number', label: $t('Phone Number') },
            { name: 'completed_loads_within_last_year', label: $t('Rolling 12/yr') },
            { name: 'total_loads', label: $t('Total Loads') },
            { name: 'unbilled_ar_formatted', label: $t('Unbilled A/R') },
            { name: 'billed_ar_formatted', label: $t('Billed A/R') },
            { name: 'available_credit_formatted', label: $t('Avail. Credit') },
            { name: 'overdue_beyond_30_days', label: $t('Over 30') },
            { name: 'actions', label: '' }
        ]"
        :data="filteredCustomers">
        <template #name-cell="{ row }">
            <div class="flex justify-start gap-2">
                <Link
                    v-if="row.can.show"
                    :id="`${row.name}-customer`"
                    :href="route('customers.show', row.id)">
                    <div class="flex gap-2 text-iel-blue">
                        <AppIcon
                            :name="row.is_active ? 'far fa-circle-check' : 'fal fa-circle-xmark'"
                            :class="row.is_active ? 'text-iel-green' : 'text-iel-red'"
                            class="h-5 w-5"
                            solid />
                        <strong>{{ row.name }}</strong>
                    </div>
                </Link>
                <p
                    v-else
                    class="flex gap-2">
                    <span class="h-5 w-5"></span>
                    <strong>{{ row.name }}</strong>
                </p>
                <div v-if="row.customer_group">
                    <Link
                        v-if="row.can.show"
                        :id="`${row.name}-customer-group-${row.customer_group.name}`"
                        :href="route('customers.groups.show', row.customer_group.id)"
                        class="mx-2 whitespace-nowrap rounded-full bg-iel-dark-red px-1.5 py-1 text-xs font-semibold text-white">
                        {{ row.customer_group?.name }}
                    </Link>
                    <p
                        v-else
                        class="mx-2 whitespace-nowrap rounded-full bg-iel-dark-red px-1.5 py-1 text-xs font-semibold text-white">
                        {{ row.customer_group?.name }}
                    </p>
                </div>
            </div>
        </template>

        <template #actions-cell="{ row }">
            <div class="flex w-full justify-end gap-4">
                <CustomerActionMenu
                    v-if="row.can.show"
                    :customer="row" />
            </div>
        </template>
    </AppTablePill>

    <AppPagination :meta="customers.meta" />
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import CustomerLayout from '@/Layouts/CustomerLayout.vue';

defineOptions({
    layout: [AuthenticatedLayout, CustomerLayout]
});

const props = defineProps({
    customers: Object,
    customerGroups: Array
});

const selectedGroup = ref(null);

const filteredCustomers = computed(() => {
    if (!selectedGroup.value) {
        return props.customers.data;
    }
    return props.customers.data.filter(customer => customer.customer_group?.id === selectedGroup.value);
});
</script>
