<template>
    <AppModal
        id="create-note-modal"
        :show="isOpen"
        title="Create Note"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="handleSubmit">
            <AppTextarea
                v-model="form.content"
                name="content"
                label=""
                required />
            <AppCheckbox
                v-if="primarySelectable"
                v-model="form.is_primary"
                name="Primary Note" />
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Save') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('AppNoteCreateModal');

// Context must have
// - endpoint string
// - models array
// - type string (optional)

const form = useForm({
    content: '',
    is_primary: false
});

const primarySelectable = computed(() => context.value.primarySelectable || false);

const endpoint = computed(() => {
    const endpoints = {
        // Load
        // optional type: claim, carrier_relations
        load: 'loads.notes.store',
        // Request
        // no type
        tickets: 'tickets.notes.store',
        // Claim flag
        // type: claims (plural)
        claim: 'loads.claims.notes.store',
        // Carrier relations flag
        // type: carrier-incident (singular)
        carrierRelations: 'loads.carrier-incident.notes.store',
        // provisional loads
        provisionalLoads: 'provisionalLoads.notes.store'
    };

    return endpoints[context.value.endpoint];
});

provide('form', form);

watch(isOpen, () => {
    form.reset();
    form.clearErrors();
});

function handleSubmit() {
    form.transform(data => Object.assign(data, { type: context.value.type })).post(
        route(endpoint.value, context.value.models),
        {
            preserveScroll: true,
            onSuccess() {
                close();
            }
        }
    );
}
</script>
