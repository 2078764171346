<template>
    <div
        v-if="request.type === 'Credit Increase'"
        class="grid gap-2">
        <CustomerCreditDenialForm
            v-if="denialForm.isOpen"
            :request="request"
            @success="success" />
        <CustomerCreditApprovalForm
            v-if="approvalForm.isOpen"
            :request="request"
            @success="success" />
    </div>
    <AppHeading
        size="sm"
        class="pb-2 text-iel-gray">
        {{ $t(request.type) }}
    </AppHeading>
    <div
        v-if="request.type === 'Customer Activation'"
        class="grid gap-2">
        <div class="grid grid-cols-2 gap-2">
            <AppDisplayField
                label="Customer Name"
                :value="request.entity.name" />
            <AppDisplayField
                label="Broker Name"
                :value="request.broker.name" />
            <AppDisplayField
                label="Customer Address"
                :value="formattedAddress" />
            <AppDisplayField
                v-if="request.entity.customer_group"
                label="Group Reason"
                :value="reason()" />
            <AppDisplayField
                v-if="reason() != 'Load Management'"
                label="AP Contact Name"
                :value="request.entity?.ap_name" />
            <AppDisplayField
                v-if="reason() != 'Load Management'"
                label="AP Contact Email"
                :value="request.entity?.ap_email" />
            <AppDisplayField
                label="AR Balance"
                :value="arBalance" />
            <AppDisplayField
                label="Credit Limit"
                :value="request.entity?.credit_limit" />
            <AppDisplayField
                v-if="request.entity.customer_group"
                label="Group Credit Limit"
                :value="request.entity.customer_group?.group_credit_limit" />
        </div>
    </div>
    <div
        v-if="request.type === 'Credit Increase'"
        class="grid gap-2">
        <div class="grid gap-2">
            <AppDisplayField
                label="Customer Name"
                :value="request.entity.name" />
            <AppDisplayField
                label="Increase Amount"
                :value="increaseAmount" />
            <AppDisplayField
                label="Update Over 30 Days Note"
                :value="request.details.updates" />
            <AppDisplayField
                v-if="request.entity.customer_group"
                label="Group Credit Limit"
                :value="request.entity.customer_group?.group_credit_limit" />
        </div>
    </div>
    <div
        v-if="request.type === 'Add Customer to Group'"
        class="grid gap-2">
        <div class="grid gap-2">
            <AppDisplayField
                label="Customer Name"
                :value="request.entity.name" />
            <AppDisplayField
                label="Group Name"
                :value="request.prospective_group_name" />
            <AppDisplayField
                label="Group Reason"
                :value="reason()" />
            <div v-if="reason() === 'Billing'">
                <AppDisplayField
                    label="AP Contact Name"
                    :value="request.details.billing_contact_name" />
                <AppDisplayField
                    label="AP Contact Email"
                    :value="request.details.billing_contact_emails" />
            </div>
            <AppDisplayField
                label="Group Credit Limit"
                :value="request.prospective_group_credit_limit" />
        </div>
    </div>
    <div
        v-if="request.type === 'Customer Insurance'"
        class="grid gap-2">
        <div class="grid gap-2">
            <AppDisplayField
                label="Customer Name"
                :value="request.entity.name" />
            <AppDisplayField
                label="Customer Address"
                :value="formattedAddress" />
            <AppDisplayField
                label="Insurance Types"
                :value="formattedInsuranceTypes" />
            <AppDisplayField
                label="Special Instructions"
                :value="request.details.special_instructions" />
        </div>
    </div>
    <div
        v-if="request.type === 'Customer Contract'"
        class="grid gap-2">
        <div class="grid gap-2">
            <AppDisplayField
                label="Customer Name"
                :value="request.entity.name" />
            <AppDisplayField
                label="Customer Address"
                :value="formattedAddress" />
            <AppDisplayField
                label="Special Instructions"
                :value="request.details.special_instructions" />
        </div>
    </div>
</template>

<script setup>
import { approvalForm, denialForm } from '@/stores';

const props = defineProps({
    request: Object
});
const emit = defineEmits(['success']);

const formattedAddress = computed(() => {
    const address1 = props.request.entity.address?.address_1 ?? '';
    const address2 = props.request.entity.address?.address_2 ?? '';
    const city = props.request.entity.address?.city ?? '';
    const state = props.request.entity.address?.region ?? '';
    const zip = props.request.entity.address?.zip ?? '';

    if (props.request.entity.address) {
        return address1 + ' ' + address2 + ' ' + city + ', ' + state + ' ' + zip;
    }
    return '-';
});

const arBalance = computed(() => {
    if (props.request.entity.unbilled_ar) {
        return props.request.entity.unbilled_ar.formatted + ' ' + props.request.entity.unbilled_ar.currency;
    } else {
        return '-';
    }
});

const increaseAmount = computed(() => {
    return (
        '$' +
        parseFloat(props.request.details.increase_amount).toFixed(2) +
        ' ' +
        props.request.details.increase_currency
    );
});

const formattedInsuranceTypes = computed(() => {
    const modifiedInsuranceTypes = props.request.details.insurance_types.map(str => {
        return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    });

    return modifiedInsuranceTypes.join(', ');
});

function reason() {
    if (
        props.request.details.group_reason === 'load_management' ||
        props.request.entity.group_reason === 'load_management'
    ) {
        return 'Load Management';
    } else if (props.request.details.group_reason === 'billing' || props.request.entity.group_reason === 'billing') {
        return 'Billing';
    } else {
        return '-';
    }
}

function success() {
    emit('success');
}
</script>
