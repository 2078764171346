<template>
    <div
        :class="bottomBorder ? 'space-y-0' : 'space-y-1'"
        data-test="wrapper">
        <AppLabel v-bind="{ name: attributes.name, label: fieldLabel, required }" />
        <div class="relative">
            <select
                v-bind="{ ...attributes, multiple }"
                v-model="value"
                class="block w-full rounded border-iel-gray/40 pl-2 pr-8 capitalize [background-position-x:right_0.25rem] sm:text-sm"
                :class="bottomBorder ? 'rounded-none border-l-0 border-r-0 border-t-0 py-0' : 'rounded py-1'"
                :aria-label="name"
                @change="handleChange">
                <slot>
                    <option
                        v-if="emptyOption"
                        :value="undefined || null">
                        {{ emptyOption }}
                    </option>
                    <option
                        v-for="(optionLabel, optionValue) in options"
                        :key="optionValue"
                        :value="optionValue">
                        {{ optionLabel }}
                    </option>
                </slot>
            </select>
            <AppRequestStatus
                v-if="status || hasAutoSave"
                v-bind="status || { recentlySuccessful, processing, error }"
                class="mr-5" />
        </div>
        <AppErrorMessage
            :name="attributes.name"
            :error="error" />
    </div>
</template>

<script setup>
const props = defineProps({
    id: {
        type: String,
        default: null
    },
    name: {
        type: String,
        required: true
    },
    bottomBorder: Boolean,
    label: String,
    error: String,
    scope: [String, Number],
    placeholder: String,
    required: Boolean,
    multiple: Boolean,
    disabled: Boolean,
    url: String,
    confirmation: String,
    permission: String,
    options: Object,
    emptyOption: String,
    status: Object
});

const emit = defineEmits(['change', 'autoSaveChange']);

const hasAutoSave = props.url !== undefined;

const form = inject('form', undefined);

const {
    value: autoSaveValue,
    submit,
    processing,
    recentlySuccessful,
    error: autoSaveError,
    clearError
} = useAutoSave(props, 'modelValue');

const definedModel = defineModel({ type: [String, Number, Array] });

const value = hasAutoSave ? autoSaveValue : definedModel;

const error = hasAutoSave ? autoSaveError : computed(() => props.error || form?.errors?.[props.name]);

const { label: fieldLabel, attributes } = useField(props, error);

function handleChange() {
    if (hasAutoSave) {
        clearError();
        nextTick(() => {
            submit();
            emit('autoSaveChange', value.value);
        });
    } else {
        emit('change', value.value);
        form?.clearErrors(props.name);
    }
}
</script>
