<template>
    <div>
        <Draggable
            v-model="sortableConnections"
            group="connections"
            item-key="id"
            handle=".handle"
            @change="onDragChange">
            <template #item="{ element: connection }">
                <div class="mb-2 rounded-lg border border-iel-gray px-2 py-3">
                    <div class="flex items-center justify-between gap-4">
                        <div class="handle w-[3%] cursor-move">
                            <AppIcon
                                name="fal fa-bars"
                                class="block h-5 w-5 cursor-move bg-white text-iel-gray" />
                        </div>
                        <div class="w-[15%] truncate">
                            <span class="text-sm font-bold">{{ connection.protocol }}</span>
                        </div>
                        <div class="w-[22%] truncate">
                            <span class="text-sm">{{ connection.partner_vendor }}</span>
                        </div>
                        <div class="w-[30%] truncate">
                            <span class="text-sm">{{ connection.connection_reasons }}</span>
                        </div>
                        <div class="w-[20%] truncate">
                            <span
                                class="text-sm font-bold"
                                :class="{
                                    'text-iel-green': connection.is_active,
                                    'text-iel-gray': !connection.is_active
                                }">
                                {{ connection.is_active ? 'Active' : 'Disabled' }}
                            </span>
                        </div>
                        <div class="w-8 flex-shrink-0">
                            <AppIcon
                                :name="`fal ${icon}`"
                                class="block h-5 w-5 cursor-pointer bg-white hover:scale-110"
                                @click="toggleEditMode(connection.id)" />
                        </div>
                    </div>

                    <div
                        v-if="editingConnection && editable.id === connection.id"
                        class="mt-4">
                        <CustomerEdiConnectionForm
                            mode="edit"
                            :initial-value="editable"
                            :customer="customer"
                            :connection-modes="connectionModes"
                            :connection-reasons="connectionReasons"
                            :connection-types="connectionTypes"
                            :hashing-algorithms="hashingAlgorithms"
                            :encryption-algorithms="encryptionAlgorithms"
                            :partner-vendors="partnerVendors"
                            @close="exitEditingMode" />
                    </div>
                </div>
            </template>
        </Draggable>

        <AppButton
            class="mt-5"
            icon="far fa-circle-plus"
            permission="update_edi_details"
            @click="addNewConnection">
            Connection
        </AppButton>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import sortBy from 'lodash/sortBy';
import Draggable from 'vuedraggable';

const {
    customer,
    connectionModes,
    connectionReasons,
    connectionTypes,
    hashingAlgorithms,
    encryptionAlgorithms,
    partnerVendors
} = defineProps({
    customer: Object,
    connectionModes: Object,
    connectionReasons: Array,
    connectionTypes: Object,
    hashingAlgorithms: Object,
    encryptionAlgorithms: Object,
    partnerVendors: Array
});

const emit = defineEmits(['add-connection']);

const addNewConnection = () => emit('add-connection');

const editingConnection = ref(false);
const editable = ref({});

const sortableConnections = ref([]);

watch(
    () => customer.edi_connections,
    newConnections => {
        sortableConnections.value = sortBy(newConnections, 'sort').map(connection => ({
            protocol: connection.protocol,
            partner_vendor: connection.partner_vendor.name,
            connection_reasons: connection.connection_reasons.join(', '),
            is_active: connection.is_active,
            id: connection.id,
            sort: connection.sort
        }));
    },
    { immediate: true, deep: true }
);

const toggleEditMode = connectionId => {
    editingConnection.value = !editingConnection.value;
    editable.value = customer.edi_connections.find(connection => connection.id === connectionId);
};

const exitEditingMode = () => {
    editingConnection.value = false;
    editable.value = {};
};

const onDragChange = ({ moved }) => {
    router.put(
        route('customers.ediConnections.reorder', [customer, moved.element.id, moved.newIndex]),
        {},
        {
            preserveScroll: true
        }
    );
};

const icon = computed(() => (editingConnection.value ? 'fa-chevron-up' : 'fa-chevron-down'));

watch(
    () => customer.edi_connections.length,
    () => {
        exitEditingMode();
    }
);
</script>
