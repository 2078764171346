<template>
    <CustomerEdiSetupDate :customer="customer" />
    <CustomerEDIVendorIdentifier :customer="customer" />

    <hr class="my-4 border-iel-light-gray" />

    <AppHeading size="md">
        {{ $t('Partner Contacts') }}
    </AppHeading>

    <table
        v-if="customer.ediContacts && customer.ediContacts.length > 0"
        :id="getId('customer-*-edi-contacts-list', [customer])"
        class="w-full whitespace-nowrap text-left text-sm">
        <thead>
            <tr>
                <th class="py-2">{{ $t('Name') }}</th>
                <th class="py-2">{{ $t('Phone') }}</th>
                <th class="py-2">{{ $t('Email') }}</th>
                <th class="py-2">{{ $t('Actions') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="contact in customer.ediContacts"
                :key="contact.id"
                class="border-t border-iel-gray">
                <td class="py-2">{{ contact.name }}</td>
                <td class="py-2">{{ contact.phone_number }}</td>
                <td class="py-2">{{ contact.email_address }}</td>
                <td class="flex gap-2 py-2">
                    <AppButton
                        icon="far fa-pen"
                        variant="outline"
                        permission="update_edi_details"
                        @click="editContact(contact)" />

                    <AppButton
                        icon="far fa-trash-can"
                        variant="outline"
                        permission="update_edi_details"
                        @click="deleteContact(contact)" />
                </td>
            </tr>
        </tbody>
    </table>

    <p v-else>
        {{ $t('No EDI contacts found') }}
    </p>

    <form
        v-if="showForm"
        :id="getId('customer-*-add-edi-contact', [customer])"
        class="my-6 grid grid-cols-12 gap-2"
        @submit.prevent="submit">
        <AppInput
            v-model="form.name"
            placeholder="Name"
            label=""
            class="col-span-3"
            name="name"
            permission="update_edi_details"
            required />
        <AppInput
            v-model="form.phone_number"
            placeholder="Phone Number"
            label=""
            class="col-span-3"
            name="phone_number"
            permission="update_edi_details"
            required />
        <AppInput
            v-model="form.email_address"
            placeholder="Email"
            label=""
            class="col-span-3"
            name="email_address"
            permission="update_edi_details"
            required />
        <div class="col-span-3 grid grid-cols-2 gap-2">
            <AppButton
                id="save-customer-contact"
                permission="update_edi_details"
                type="submit">
                {{ $t('Save') }}
            </AppButton>
            <AppButton
                id="cancel-contact-form"
                permission="update_edi_details"
                @click="showForm = false">
                {{ $t('Cancel') }}
            </AppButton>
        </div>
    </form>

    <AppButton
        v-if="!showForm"
        :id="getId('customer-*-edi-show-contact-form', [customer])"
        icon="far fa-circle-plus"
        class="ml-auto mt-4"
        permission="update_edi_details"
        @click="showForm = true">
        {{ $t('Add Contact') }}
    </AppButton>

    <hr class="my-4 border-iel-light-gray" />

    <AppTextarea
        :id="getId('customer-*-edi-note', [customer])"
        :model-value="customer.edi_note"
        name="edi_note"
        :label="$t('Note')"
        permission="update_edi_details"
        :url="url" />

    <hr class="my-4 border-iel-light-gray" />
</template>

<script setup>
import { getId } from '@/helpers';
import { useForm, router } from '@inertiajs/vue3';

const { customer } = defineProps({
    customer: {
        type: Object,
        required: true
    }
});

const showForm = ref(false);

const form = useForm({
    name: '',
    email_address: '',
    phone_number: ''
});

provide('form', form);

function submit() {
    if (form.contactId) {
        form.patch(route('customers.edi-contacts.update', [customer, form.contactId]), {
            preserveScroll: true,
            onSuccess() {
                form.reset();
                form.contactId = null;
                showForm.value = false;
                router.reload({ only: ['customer'] });
            }
        });
    } else {
        form.post(route('customers.edi-contacts.store', customer), {
            preserveScroll: true,
            onSuccess() {
                form.reset();
                showForm.value = false;
                router.reload({ only: ['customer'] });
            }
        });
    }
}

function deleteContact(contact) {
    if (confirm('Are you sure you want to delete this contact?')) {
        form.delete(route('customers.edi-contacts.delete', [customer, contact]), {
            preserveScroll: true,
            onSuccess() {
                router.reload({ only: ['customer'] });
            }
        });
    }
}

const editContact = contact => {
    form.name = contact.name;
    form.phone_number = contact.phone_number;
    form.email_address = contact.email_address;
    form.contactId = contact.id;

    showForm.value = true;
};

const url = route('customers.update', customer);
</script>
