<template>
    <AppCard
        title="Billing"
        theme="light_gray"
        content-class="p-2">
        <div class="grid gap-2">
            <div
                v-if="customer.use_group_billing"
                class="border-y border-iel-light-blue bg-iel-lightest-blue px-4 py-3 text-iel-blue">
                <p class="uppercase">
                    {{ $t('Inherited from group') }}
                </p>
            </div>
            <div class="grid gap-2 md:grid-cols-2">
                <div class="grid gap-2">
                    <div class="grid gap-2 md:grid-cols-2">
                        <AppSelect
                            name="billing_method"
                            permission="update_customer_billing"
                            :disabled="customer.use_group_billing"
                            :model-value="customer.billing_method"
                            :url="url"
                            :label="$t('Billing Method')"
                            :options="{ email: 'Email', mail: 'Mail' }" />
                        <AppSelect
                            name="billing_terms"
                            permission="update_customer_billing"
                            :disabled="customer.use_group_billing"
                            :model-value="customer.billing_terms"
                            :url="url"
                            :label="$t('Billing Terms')"
                            :options="{ 15: 15, 30: 30, 45: 45, 60: 60, 90: 90 }" />
                    </div>
                    <AppSelectTags
                        id="select-label-customer-billing"
                        name="required_documents"
                        permission="update_customer_billing"
                        :disabled="customer.use_group_billing"
                        :model-value="customer.required_documents"
                        :url="url"
                        :label="$t('Required Paperwork')"
                        :placeholder="$t('Select...')"
                        :options="loadDocumentTypes"
                        :taggable="false" />
                </div>
                <div>
                    <AppCheckbox
                        name="no_unloading_charges"
                        permission="update_customer_billing"
                        :disabled="customer.use_group_billing"
                        :model-value="customer.no_unloading_charges"
                        :url="url"
                        :label="$t('No unloading charges')"
                        class="mb-2" />
                    <AppTextarea
                        permission="update_customer_billing"
                        :disabled="customer.use_group_billing"
                        :model-value="customer.billing_notes"
                        :url="url"
                        :label="$t('Billing Notes')"
                        name="billing_notes" />
                </div>
            </div>
            <div class="grid gap-2">
                <div class="grid gap-2 md:grid-cols-2">
                    <AppInput
                        permission="update_customer_billing"
                        :disabled="customer.use_group_billing"
                        :model-value="customer.billing_contact_name"
                        :url="url"
                        :label="$t('Billing Contact Name')"
                        name="billing_contact_name" />
                    <AppInput
                        permission="update_customer_billing"
                        :disabled="customer.use_group_billing"
                        :model-value="customer.billing_contact_emails"
                        :url="url"
                        :label="$t('Billing Contact Emails')"
                        name="billing_contact_emails" />
                </div>
                <CustomerBillingAddressCard />
            </div>
        </div>
    </AppCard>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import { loadDocumentTypes } from '@/data.js';

const page = usePage();

const customer = computed(() => page.props.customer);

const url = route('customers.billing.update', page.props.customer);
</script>
