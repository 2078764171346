<template>
    <div class="flex flex-1 overflow-hidden">
        <div
            class="flex-shrink-0 flex-grow-0 overflow-auto px-2"
            :class="{ 'w-1/2': selectedRequest, 'w-full': !selectedRequest }">
            <AppTablePill
                :columns="[
                    { name: 'created_at', label: 'Request Date', sortable: true },
                    { name: 'type', label: 'Request Type', sortable: true },
                    { name: 'po_number', label: 'PO Number', sortable: true },
                    { name: 'dot', label: 'DOT', sortable: true },
                    { name: 'entity', label: 'Customer/Carrier', sortable: true },
                    { name: 'broker', label: 'Broker', sortable: true },
                    { name: 'assignee', label: 'Assignee', sortable: true },
                    { name: 'completed_at', label: 'Completed Date', sortable: true }
                ]"
                :data="tickets.data"
                data-key="tickets"
                :classes="{ row: 'cursor-pointer' }"
                :sticky-header="true"
                @row-click="selectRequest">
                <template #entity-cell="{ row }">
                    <Link
                        :href="
                            row.entity_type === 'customer'
                                ? route('customers.show', row.entity.id)
                                : route('carriers.show', row.entity.id)
                        "
                        class="flex cursor-pointer items-center !px-0 underline">
                        {{ row.entity.name }}
                    </Link>
                </template>
                <template #dot-cell="{ row }">
                    <div v-if="row.entity.dot_number">
                        {{ row.entity.dot_number }}
                    </div>
                </template>
                <template #assignee-cell="{ row }">
                    <div class="h-8">
                        <template v-if="hasPermissionToOverride(row.type)">
                            <AppSelect
                                :id="`report-${row.id}-assignment-select`"
                                label=""
                                :name="`report-${row.id}-assignment`"
                                empty-option="Unassigned"
                                :options="props.options[permissionToAssign(row.type)]"
                                :model-value="row.assignee_id"
                                :status="status[row.id]"
                                @click.stop
                                @change="assign(row, $event)" />
                        </template>
                        <template v-else-if="row.assignee === 'Unassigned'">
                            <AppButton
                                v-if="!row.completed_at"
                                :id="`report-${row.id}-assign-button`"
                                variant="primary"
                                size="sm"
                                class="cursor-pointer"
                                :permission="permissionToAssign(row.type)"
                                @click.stop="assign(row, page.props.auth.user.id)">
                                {{ $t('Assign To Me') }}
                            </AppButton>
                        </template>
                        <template v-else-if="row.assignee_id === page.props.auth.user.id">
                            <div class="flex h-7 pt-1">
                                <p>{{ row.assignee }}</p>
                                <AppIcon
                                    v-if="!row.completed_at"
                                    name="fal fa-circle-xmark"
                                    class="ml-2 h-5 w-5 cursor-pointer text-iel-dark-red"
                                    title="Click to unassign yourself."
                                    @click.stop="assign(row, null)" />
                            </div>
                        </template>
                        <template v-else>
                            <div class="flex h-7 pt-1">
                                <p class="text-iel-gray">{{ row.assignee }}</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #created-at-cell="{ row }">
                    <AppButton
                        class="flex cursor-pointer items-center !px-0"
                        variant="invisible"
                        size="invisible">
                        {{ row.created_at }}
                    </AppButton>
                </template>
                <template #type-cell="{ row }">
                    <AppButton
                        class="flex cursor-pointer items-center !px-0"
                        variant="invisible"
                        size="invisible">
                        {{ row.type }}
                    </AppButton>
                </template>
                <template #po_number-cell="{ row }">
                    <a
                        v-if="row.po_number"
                        class="flex cursor-pointer items-center !px-0"
                        variant="invisible"
                        size="invisible"
                        :href="route('loads.showByPO', row.po_number)"
                        target="_blank">
                        {{ row.po_number }}
                    </a>
                </template>
                <template #broker-cell="{ row }">
                    <AppButton
                        class="flex cursor-pointer items-center !px-0"
                        variant="invisible"
                        size="invisible">
                        {{ row.broker?.name }}
                    </AppButton>
                </template>
                <template #completed-at-cell="{ row }">
                    <AppButton
                        class="flex cursor-pointer items-center !px-0"
                        variant="invisible"
                        size="invisible">
                        {{ row.completed_at }}
                    </AppButton>
                </template>
            </AppTablePill>
        </div>
        <div
            v-if="selectedRequest"
            class="flex flex-1 flex-col border-l border-iel-light-gray bg-white">
            <div class="flex justify-between bg-iel-blue px-4 py-2 text-white">
                <div class="flex items-center gap-2 font-semibold">
                    <div class="uppercase">
                        <AppIcon name="far fa-clipboard-list" />
                        {{ selectedRequest.type }}
                    </div>
                    <span v-if="selectedRequest.entity_type === 'carrier'">
                        - DOT: {{ selectedRequest.entity?.dot_number || '-' }}
                    </span>
                    <p>-</p>
                    <a
                        :href="
                            route(
                                selectedRequest.entity_type === 'carrier' ? 'carriers.show' : 'customers.show',
                                selectedRequest.entity.id
                            )
                        "
                        class="cursor-pointer underline"
                        target="_blank">
                        {{ selectedRequest.entity.name }}
                    </a>
                </div>
                <AppButton
                    class="flex items-center text-lg text-white"
                    icon="far fa-circle-xmark"
                    icon-size="lg"
                    @click="deselectRequest" />
            </div>
            <div
                v-if="isFetching"
                class="flex flex-1 items-center justify-center">
                <AppIcon
                    name="fal fa-arrows-rotate"
                    class="size-10 animate-spin text-iel-gray" />
            </div>
            <div
                v-else-if="selectedRequestInfo"
                class="flex-1 overflow-auto p-2">
                <CarrierCard
                    v-if="selectedRequest.entity_type === 'carrier'"
                    :request="selectedRequestInfo"
                    @success="refreshSelectedRequestInfo" />
                <CustomerCard
                    v-if="selectedRequest.entity_type === 'customer'"
                    :request="selectedRequestInfo"
                    @success="refreshSelectedRequestInfo" />
            </div>
        </div>
        <AppFeedback />
    </div>
    <AppPagination
        :meta="tickets.meta"
        class="px-2 py-4" />
</template>

<script setup>
import RequestsLayout from '@/Layouts/RequestsLayout.vue';
import { useFetch, useUrlSearchParams } from '@vueuse/core';
import { useForm, usePage } from '@inertiajs/vue3';
import { approvalForm, denialForm } from '@/stores';
import { useI18n } from 'vue-i18n';

defineOptions({
    layout: [RequestsLayout]
});

const props = defineProps({
    tickets: Object,
    options: Object
});

const { t } = useI18n();

const { can } = useAuth();

const page = usePage();

const params = useUrlSearchParams('history');

const status = reactive({});

const selectedRequest = computed(() => props.tickets.data.find(ticket => ticket.id == params.id));

function selectRequest(row) {
    // Broker can always view their requests
    if (!row.broker || row.broker.id === page.props.auth.user.id) {
        params.id = row.id;
        return;
    }

    if (row.type === 'Credit Increase' && !can('view_customer_credit_request')) {
        alert(t('You do not have permission to view this request.'));
        return;
    }

    if (!row.assignee_id || row.assignee_id === page.props.auth.user.id) {
        params.id = row.id;
    }
}

function deselectRequest() {
    params.id = null;
}

const selectedRequestInfoUrl = ref(null);

const {
    isFetching,
    data: selectedRequestInfo,
    execute: refreshSelectedRequestInfo
} = useFetch(selectedRequestInfoUrl, { refetch: true, immediate: false }).json();

watch(
    params,
    () => {
        if (params.id) {
            selectedRequestInfoUrl.value = `/tickets/${params.id}`;
        }
    },
    { immediate: true }
);

onMounted(() => {
    approvalForm.value.setIsOpen(false);
    approvalForm.value.setAmount(null);
    denialForm.value.setIsOpen(false);
});

function assign(row, assignee = null) {
    status[row.id] = useForm({
        assigned_to: assignee
    });

    status[row.id].patch(route('tickets.assignment', row.id), {
        preserveScroll: true,
        onSuccess: () => {
            if (selectedRequest.value) {
                refreshSelectedRequestInfo();
            }
        }
    });
}

function hasPermissionToOverride(type) {
    return can(permissionToAssign(type)) && can('override_request_assignment');
}

function permissionToAssign(type) {
    const permissions = {
        'Carrier Activation': 'update_carrier_activation_request',
        'Insurance Update': 'update_carrier_insurance_request',
        'Customer Activation': 'update_customer_activation_request',
        'Credit Increase': 'update_customer_credit_request',
        'Customer Contract': 'update_customer_contract_request',
        'Customer Insurance': 'update_customer_insurance_request',
        'Advance Terms Change': 'update_carrier_advance_terms_request',
        'Add Customer to Group': 'update_customer_group_add_existing_request'
    };
    return permissions[type];
}
</script>
