<template>
    <div :id="getId('load-*-accessorials', [load])">
        <table
            v-if="refinedAccessorials.length"
            :id="getId('load-*-accessorials-table', [load])"
            class="NX-mobile-stacked w-full">
            <thead>
                <tr>
                    <th>{{ $t('Location') }}</th>
                    <th>{{ $t('Type') }}</th>
                    <th>{{ $t('Quantity') }}</th>
                    <th>{{ $t('Amount') }}</th>
                    <th>{{ $t('Total') }}</th>
                    <th class="text-center text-xs">{{ $t('Bill to Customer') }}</th>
                    <th class="text-center text-xs">{{ $t('Pay Carrier') }}</th>
                    <th class="text-center text-xs">{{ $t('Receipt Required') }}</th>
                    <th>{{ $t('Note') }}</th>
                </tr>
            </thead>
            <tbody :id="getId('load-*-accessotial-transactions', [load])">
                <LoadTransaction
                    v-for="rate in refinedAccessorials"
                    :key="rate.id"
                    permission="update_accessorial_charges"
                    :load="load"
                    :transaction="rate"
                    :disable-context="disableTransaction(rate)" />
            </tbody>
        </table>

        <div
            v-else
            class="text-sm">
            {{ $t('This load has no accessorial charges.') }}
        </div>

        <AppButton
            :id="getId('load-*-add-accessotial-item-button', [load])"
            type="button"
            permission="update_accessorial_charges"
            icon="far fa-circle-plus"
            :disabled="
                load.is_canceled_or_tonu || (load.paidCarrier && load.invoiceReady) || !load.can.view_load_details
            "
            class="mt-2"
            @click="debouncedAddAccessorialRate">
            {{ $t('Accessorial') }}
        </AppButton>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { debounce } from 'lodash-es';
import { getId } from '@/helpers';

const { load } = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const refinedAccessorials = computed(() => {
    return load.can.view_load_details
        ? load.accessorialTransactions
        : load.accessorialTransactions.filter(transaction => {
              return !transaction.bill_to_customer || (transaction.bill_to_customer && transaction.pay_carrier);
          });
});

function addAccessorialRate() {
    router.post(
        route('loads.billing.store', load.id),
        { quantity: 1, amount: 0, bill_type: 'accessorial' },
        {
            preserveScroll: true,
            only: ['load']
        }
    );
}

const debouncedAddAccessorialRate = debounce(addAccessorialRate, 1000);

function disableTransaction(rate) {
    return (
        (load.invoiceReady && rate.bill_to_customer) ||
        (load.paidCarrier && rate.pay_carrier) ||
        !load.can.view_load_details
    );
}
</script>
