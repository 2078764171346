<template>
    <AppModal
        id="create-bid-modal"
        :show="isOpen"
        :title="$t('Create Bid')"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="submit">
            <AppInput
                v-model="form.amount"
                name="amount"
                type="number"
                :label="$t('Bid Amount')"
                :min="0"
                step=".01" />

            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    type="submit"
                    :disabled="form.processing || !form.amount"
                    :loading="form.processing">
                    {{ $t('Submit') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('QuoteBidCreateModal');

const form = useForm({
    amount: null,
    amount_currency: 'USD'
});

provide('form', form);

watch(isOpen, () => {
    form.reset();
    form.clearErrors();
});

function submit() {
    form.post(route('quotes.bid', context.value.quote.id), {
        preserveScroll: true,
        onSuccess() {
            close();
        }
    });
}
</script>
