<template>
    <div
        class="space-y-1"
        data-test="wrapper">
        <AppLabel
            :label="fieldLabel"
            :required="required" />
        <div class="relative">
            <multiselect
                v-model="value"
                :name="attributes.name"
                :options="options"
                :placeholder="attributes.placeholder"
                :track-by="multiSelectTrackBy"
                :label="multiSelectLabel"
                :disabled="attributes.disabled"
                hide-selected
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :taggable="taggable"
                :max-height="maxHeight"
                :open-direction="openDirection"
                :searchable="searchable"
                select-label="Enter to select"
                aria-multiselectable="true"
                @select="handleChange"
                @remove="handleChange">
                <template #caret>{{}}</template>
            </multiselect>
            <AppRequestStatus
                v-if="hasAutoSave"
                v-bind="{ recentlySuccessful, processing, error }" />
        </div>
        <AppErrorMessage
            :name="attributes.name"
            :error="error" />
    </div>
</template>

<script setup>
import multiselect from 'vue-multiselect';
import { isPlainObject } from 'lodash-es';

const props = defineProps({
    name: {
        type: String,
        required: false
    },
    label: String,
    error: String,
    scope: [String, Number],
    placeholder: String,
    id: {
        type: String,
        default: null
    },
    required: Boolean,
    disabled: {
        type: Boolean,
        default: false
    },
    url: String,
    confirmation: String,
    permission: String,
    options: Array,
    taggable: {
        type: Boolean,
        default: true
    },
    searchable: {
        type: Boolean,
        default: true
    },
    maxHeight: {
        type: Number,
        default: 250
    },
    openDirection: {
        type: String,
        default: 'bottom'
    }
});

const hasAutoSave = props.url !== undefined;

const form = inject('form', undefined);

const {
    value: autoSaveValue,
    submit,
    processing,
    recentlySuccessful,
    error: autoSaveError,
    clearError
} = useAutoSave(props, 'modelValue', selection => {
    if (isPlainObject(props.options?.[0])) {
        return { [props.name]: selection[props.name].map(option => option.value) };
    }

    return selection;
});

const definedModel = defineModel({ type: [String, Number, Array] });

const value = hasAutoSave ? autoSaveValue : definedModel;

const error = hasAutoSave ? autoSaveError : computed(() => props.error || form?.errors?.[props.name]);

const { label: fieldLabel, attributes } = useField(props, error);

const multiSelectTrackBy = computed(() => (isPlainObject(props.options?.[0]) ? 'value' : null));

const multiSelectLabel = computed(() => (isPlainObject(props.options?.[0]) ? 'label' : null));

function handleChange() {
    if (hasAutoSave) {
        clearError();
        nextTick(() => submit());
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.multiselect {
    @apply min-h-0 leading-none;
}
.multiselect__tags {
    @apply min-h-0 rounded border border-iel-gray/40 p-1 pr-8 !leading-none sm:text-sm;
}
.multiselect__tags-wrap {
    @apply inline-flex flex-wrap gap-1;
}
.multiselect__tag {
    @apply m-0 inline-flex items-center gap-1.5 rounded bg-iel-light-gray px-1.5 py-0.5 text-iel-dark-gray;
}
.multiselect__tag-icon {
    @apply static !m-0 w-auto cursor-pointer font-normal leading-none;
}
.multiselect__tag-icon::after {
    @apply text-iel-dark-gray hover:text-iel-dark-red;
}
.multiselect__input {
    @apply my-1 min-h-0 !border-none !p-0 text-base leading-none text-iel-gray !ring-0 placeholder:text-iel-gray sm:!text-sm;
}
.multiselect__placeholder {
    @apply my-1 h-[20px];
}
.multiselect__option {
    @apply flex min-h-0 cursor-pointer items-center px-1.5 py-0.5 text-base leading-normal sm:text-sm;
}
.multiselect__option::after {
    @apply static ml-auto p-0 text-sm leading-none !text-iel-gray sm:text-xs;
}
.multiselect__option--highlight {
    @apply bg-iel-lightest-blue text-iel-dark-gray;
}
.multiselect__option--highlight::after {
    @apply bg-iel-lightest-blue text-iel-dark-gray;
}
</style>
