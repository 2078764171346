<template>
    <AppTablePill
        id="inactive-tenders-table"
        title="Inactive Tenders"
        :columns="[
            { name: 'edi_shipment_id', label: $t('Shipment ID #') },
            { name: 'customer', label: $t('Customer') },
            { name: 'number_of_stops', label: $t('# of Stops') },
            { name: 'weight', label: $t('Weight') },
            { name: 'trip_miles', label: $t('Total Miles') },
            { name: 'equipment', label: $t('Equipment') },
            { name: 'lane', label: $t('Lane') },
            { name: 'date_time', label: $t('Date/Time') },
            { name: 'rate', label: $t('Rate') },
            { name: 'status', label: $t('Status') }
        ]"
        data-key="tenders"
        :data="inactive.data"
        :classes="{ row: 'cursor-pointer' }"
        @row-click="openTender">
        <template #customer-cell="{ row }">
            <Link
                :href="route('customers.show', row.id)"
                class="font-bold text-iel-blue underline">
                {{ row.customer?.name }}
            </Link>
        </template>
        <template #number_of_stops-cell="{ row }">
            <p>{{ row.stops.length }}</p>
        </template>
        <template #weight-cell="{ row }">
            <div
                v-if="row.stops"
                class="text-sm">
                {{ row.total_load_weight }} {{ row.total_load_weight_description }}
            </div>
        </template>
        <template #trip_miles-cell="{ row }">
            <p v-if="row.trip_miles">{{ row.trip_miles }} mi</p>
        </template>
        <template #equipment-cell="{ row }">
            <p>{{ row.equipment_length }}' {{ row.required_equipment_type }}</p>
        </template>
        <template #lane-cell="{ row }">
            <div
                v-if="formatLane(row)"
                class="space-y-1">
                <p class="text-sm">{{ formatLane(row).origin }}</p>
                <p class="text-sm">{{ formatLane(row).destination }}</p>
            </div>
        </template>
        <template #date_time-cell="{ row }">
            <div
                v-if="formatLane(row)"
                class="space-y-1">
                <p class="text-sm">{{ formatLane(row).startDate }}</p>
                <p class="text-sm">{{ formatLane(row).endDate }}</p>
            </div>
        </template>
        <template #rate-cell="{ row }">
            <p>{{ formatMoney(row.rate) }}</p>
        </template>
    </AppTablePill>
    <AppPagination :meta="inactive.meta" />
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import QuotesLayout from '@/Layouts/QuotesLayout.vue';
import { formatLane, formatMoney } from '@/helpers';
import { router } from '@inertiajs/vue3';

defineOptions({
    layout: [AuthenticatedLayout, QuotesLayout]
});

const { inactive } = defineProps({
    inactive: {
        type: Object,
        required: true,
        default: () => {}
    }
});

function openTender(row) {
    router.visit(route('tenders.show', row.id));
}
</script>
