<template>
    <AppCard
        id="customer-internal-card"
        title="Internal"
        theme="light_gray"
        class="min-h-96"
        content-class="p-2">
        <AppTabs>
            <div>
                <AppTab
                    title="Sales Contact"
                    class="p-2">
                    <table class="w-full">
                        <tbody>
                            <tr>
                                <td>
                                    <AppAutocomplete
                                        :id="getId('sales-contact-*', [primarySalesUser])"
                                        v-model="primarySalesUser.id"
                                        name="primaryUser"
                                        label="Primary Sales User"
                                        permission="add_primary_sales_user"
                                        :url="route('customers.salesuser', [customer.id])"
                                        :options-url="route('users.autocomplete')"
                                        :initial-options="[
                                            { value: primarySalesUser.id, display: primarySalesUser.name }
                                        ]"
                                        :placeholder="$t('Search for a primary user...')"
                                        class="w-full" />
                                </td>
                                <td>
                                    <AppInput
                                        v-model="primarySalesUser.email"
                                        name="email"
                                        label="Email"
                                        :disabled="true" />
                                </td>
                            </tr>
                            <tr
                                v-for="(user, index) in secondarySalesUsers"
                                :key="user.id">
                                <td>
                                    <AppAutocomplete
                                        :id="getId('sales-contact-*', [user])"
                                        v-model="secondarySalesUsers[index].id"
                                        name="user"
                                        label="Secondary Sales User"
                                        :url="route('customers.salesuser', [customer.id])"
                                        :options-url="route('users.autocomplete')"
                                        :initial-options="[
                                            {
                                                value: secondarySalesUsers[index].id,
                                                display: secondarySalesUsers[index].name
                                            }
                                        ]"
                                        :disabled="true"
                                        class="w-full" />
                                </td>
                                <td>
                                    <AppInput
                                        v-model="user.email"
                                        label="Email"
                                        :disabled="true" />
                                </td>
                                <td>
                                    <AppButton
                                        class="mt-6"
                                        icon="far fa-circle-minus"
                                        :disabled="!hasSecondaryPermissions"
                                        @click="removeSecondaryUser(index)">
                                        User
                                    </AppButton>
                                </td>
                            </tr>
                            <tr
                                v-for="(user, pindex) in pendingUsers"
                                :key="pindex">
                                <td>
                                    <AppAutocomplete
                                        :id="getIdFromIndex('pending-sales-contact-*', pindex)"
                                        v-model="pendingUsers[pindex].id"
                                        name="user"
                                        label="Adding Sales User"
                                        :url="route('customers.salesuser', [customer.id])"
                                        :options-url="route('users.autocomplete')"
                                        :placeholder="$t('Search for an user...')"
                                        class="w-full"
                                        :index="pindex"
                                        :disabled="!hasSecondaryPermissions"
                                        @selected="setPendingValues" />
                                    <AppErrorMessage :error="pendingUsers[pindex].error" />
                                </td>
                                <td>
                                    <AppButton
                                        class="mt-6"
                                        icon="far fa-circle-minus"
                                        :disabled="!hasSecondaryPermissions"
                                        @click="removePendingUser(pindex)">
                                        User
                                    </AppButton>
                                </td>
                            </tr>
                            <tr>
                                <td class="pb-5 pt-10">
                                    <AppButton
                                        icon="far fa-circle-plus"
                                        :disabled="!hasSecondaryPermissions"
                                        @click="addRow">
                                        User
                                    </AppButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </AppTab>
                <AppTab
                    :active="true"
                    title="RateCon"
                    class="p-2">
                    <AppInput
                        v-model="customer.team_contact_name"
                        name="team_contact_name"
                        label="Sales Team Member Name"
                        permission="update_ratecon"
                        :url="url" />
                    <AppInput
                        v-model="customer.team_contact_email"
                        name="team_contact_email"
                        label="Sales Team Member Email"
                        permission="update_ratecon"
                        :url="url" />
                    <AppInput
                        v-model="customer.team_contact_phone"
                        name="team_contact_phone"
                        label="Sales Team Member Phone"
                        permission="update_ratecon"
                        :url="url" />
                    <AppInput
                        v-model="customer.team_contact_fax"
                        name="team_contact_fax"
                        label="Sales Team Member Fax"
                        permission="update_ratecon"
                        :url="url" />
                    <AppTextarea
                        v-model="customer.special_instructions"
                        name="special_instructions"
                        label="RateCon Special Instructions"
                        permission="update_ratecon"
                        class="md:col-span-2"
                        :url="url" />
                </AppTab>
            </div>
        </AppTabs>
    </AppCard>
</template>

<script setup>
import { router, usePage } from '@inertiajs/vue3';
import { nextTick } from 'vue';
import { watch } from 'vue';
import { getId } from '@/helpers';
import { useAuth } from '@/Composables/useAuth';

const { can } = useAuth();

const page = usePage();

const customer = computed(() => page.props.customer);

const url = route('customers.ratecon.update', page.props.customer);

const salesUsers = computed(() => customer?.value?.sales_users ?? []);
const primarySalesUser = computed(() => salesUsers.value.find(user => user.is_primary === true) ?? []);
const secondarySalesUsers = computed(() => salesUsers.value.filter(user => user.is_primary !== true) ?? []);
const pendingUsers = ref([]);

const hasSecondaryPermissions = computed(
    () => can('add_secondary_sales_user') || page.props.auth.user.id == primarySalesUser.value?.id
);

watch(secondarySalesUsers, () => {
    for (let i = pendingUsers.value.length - 1; i >= 0; i--) {
        if (pendingUsers.value[i].id != undefined) {
            if (page.props.errors.error) {
                pendingUsers.value[i].error = page.props.errors.error;
            } else {
                pendingUsers.value.splice(i, 1);
            }
        }
    }
});

function clearErrors() {
    page.props.errors.error = '';
}

function removeSecondaryUser(index) {
    clearErrors();
    router.delete(route('customers.salesuser.delete', [customer.value.id, secondarySalesUsers.value[index].id]), {
        preserveScroll: true
    });
}

function removePendingUser(index) {
    pendingUsers.value.splice(index, 1);
    pendingUsers.value = { ...pendingUsers.value };
}

function addRow() {
    pendingUsers.value.push({});
}

function setPendingValues(index, value) {
    nextTick(() => {
        pendingUsers.value[index].id = value;
    });
}

function getIdFromIndex(template, index) {
    return template.replace(/\*/g, index);
}
</script>

<style scoped>
td {
    @apply pr-5;
}
</style>
