<template>
    <ul
        v-if="tenders.length > 0"
        class="grid grid-cols-3 gap-2">
        <li
            v-for="tender in tenders"
            :id="getId('tender-*-card', [tender])"
            :key="tender.id">
            <AppCard
                theme="blue"
                index>
                <template #title>
                    <Link
                        :href="route('tenders.show', tender.id)"
                        class="flex gap-2 overflow-hidden font-semibold text-white underline">
                        {{ tender.customer?.name }} {{ tender.edi_tender.shipment_id }}
                    </Link>
                </template>

                <div class="border-b">
                    <TenderTasks
                        :tender="tender"
                        :options="options" />
                </div>

                <p class="my-4 px-2">
                    <strong>@TODO Expiration Date:</strong>
                    6/6/06
                </p>

                <div
                    :id="getId('tender-*-load-detail-display', [tender])"
                    class="grid w-full gap-4 px-2 pb-4">
                    <table class="w-full whitespace-nowrap text-left">
                        <thead>
                            <tr class="pb-4">
                                <th>{{ $t('Lane') }}</th>
                                <th>{{ $t('Date') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="py-1 text-sm">
                                    <div
                                        v-if="formatLane(tender)"
                                        class="space-y-1">
                                        <p class="text-sm">{{ formatLane(tender).origin }}</p>
                                        <p class="text-sm">{{ formatLane(tender).destination }}</p>
                                    </div>
                                </td>
                                <td class="py-1 text-sm">
                                    <div
                                        v-if="formatLane(tender)"
                                        class="space-y-1">
                                        <p class="text-sm">{{ formatLane(tender).startDate }}</p>
                                        <p class="text-sm">{{ formatLane(tender).endDate }}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="flex items-start gap-8">
                        <div>
                            <p class="mb-2 font-semibold">Equipment Required</p>
                            <p class="text-sm">{{ tender.equipment_length }} {{ tender.required_equipment_type }}</p>
                        </div>
                        <div>
                            <p class="mb-2 font-semibold">Weight</p>
                            <p class="text-sm">{{ tender.total_load_weight }}</p>
                        </div>
                        <div>
                            <p class="mb-2 font-semibold">Miles</p>
                            <p class="text-sm">{{ tender.trip_miles }}</p>
                        </div>
                        <div>
                            <p class="mb-2 font-semibold">Rate</p>
                            <p class="text-sm">@TODO</p>
                        </div>
                    </div>
                </div>

                <template #nav>
                    <Link
                        :id="getId('view-tender-*-details', [tender])"
                        :href="route('tenders.show', tender.id)"
                        aria-label="navigate to this tender's detail page">
                        <AppIcon
                            name="far fa-arrows-maximize"
                            class="text-white" />
                    </Link>
                </template>
            </AppCard>
        </li>
    </ul>

    <p
        v-else
        class="p-4">
        {{ $t('There are no tenders.') }}
    </p>
</template>

<script setup>
import { getId, formatLane } from '@/helpers';

const { tenders, options } = defineProps({
    tenders: {
        type: Object,
        required: true,
        default: () => ({})
    },
    options: {
        type: Object,
        required: true,
        default: () => ({
            rejection_reasons: []
        })
    }
});
</script>
