<template>
    <AppCard
        :id="getId('quote-*-notes', [quote])"
        :title="$t('Quote Notes')"
        theme="blue"
        content-class="p-4">
        <AppButton
            id="quote-create-note-button"
            class="mt-2"
            icon="far fa-circle-plus"
            @click="openCreateNoteModal">
            {{ $t('Note') }}
        </AppButton>

        <ul
            v-if="quote.notes?.length > 0"
            role="list"
            class="mt-4 grid max-h-96 gap-2 overflow-y-auto"
            tabindex="0">
            <li
                v-if="primaryNote"
                :id="`note-${primaryNote.id}`"
                :key="primaryNoteKey">
                <AppMessage
                    :user="primaryNote?.user?.name"
                    :title="primaryNote.title"
                    :body="primaryNote.content"
                    :created-at="primaryNote.created_at"
                    :icon="primaryNote.icon"
                    size="lg">
                    <AppButton
                        variant="outline"
                        icon="fas fa-star"
                        @click="setPrimaryNote(primaryNote)" />
                </AppMessage>
            </li>

            <li
                v-for="note in secondaryNotes"
                :id="`note-${note.id}`"
                :key="note.id">
                <AppMessage
                    :user="note.user?.name"
                    :title="note.title"
                    :body="note.content"
                    :created-at="note.created_at"
                    :icon="note.icon"
                    size="lg">
                    <AppButton
                        variant="outline"
                        icon="far fa-star"
                        @click="setPrimaryNote(note)" />
                </AppMessage>
            </li>
        </ul>

        <div v-else>{{ $t('No notes found on this quote.') }}</div>
    </AppCard>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { getId } from '@/helpers';
import axios from 'axios';

const { quote } = defineProps({
    quote: Object
});

const initialNoteIds = ref([]);
const primaryNoteKey = ref(0);

const noteTypes = {
    claim: { title: 'Claim', icon: 'fas fa-gavel' },
    carrier_relations: { title: 'Carrier Relations', icon: 'fas fa-handshake' },
    task_snooze: { icon: 'fas fa-alarm-snooze' }
};

const notes = computed(() =>
    (quote.notes ?? []).map(note => {
        const noteType = noteTypes[note.type] ?? {};

        return {
            ...note,
            icon: note.type ? noteType.icon : null,
            title: note.title ? note.title : noteType.title
        };
    })
);

const primaryNote = computed(() => notes.value.find(n => n.is_primary) || null);

const secondaryNotes = computed(() => notes.value.filter(n => !n.is_primary) || null);

onMounted(() => (initialNoteIds.value = quote.notes?.map(({ id }) => id) || []));

function openCreateNoteModal() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'provisionalLoads',
        models: { provisionalLoad: quote },
        primarySelectable: true
    });
}

async function setPrimaryNote(note) {
    await axios
        .put(
            route('notes.update', note.id),
            {
                content: note.content,
                is_primary: !note.is_primary
            },
            {
                headers: {
                    'X-Inertia': false
                }
            }
        )
        .then(() => {
            refreshNotes();
        });
}

function refreshNotes() {
    router.reload({
        preserveScroll: true,
        onSuccess: () => {
            primaryNoteKey.value++;
        }
    });
}
</script>
