<template>
    <div class="px-1 py-2">
        <AppButton
            v-if="quote.bids.length === 0"
            :id="getId('quote-*-create-bid', [quote])"
            name="bid_submitted"
            @click.stop="handleOpenQuoteBidCreateModal(quote)">
            {{ $t('Submit Bid') }}
        </AppButton>

        <div v-else>
            <div class="flex space-x-2">
                <AppButton
                    :id="getId('quote-*-accept-bid', [quote])"
                    name="accept_bid"
                    icon="far fa-circle-check"
                    @click.stop="acceptBid(quote)">
                    {{ $t('Accept Bid') }}
                </AppButton>
                <AppButton
                    :id="getId('quote-*-reject-bid', [quote])"
                    name="reject_bid"
                    icon="far fa-circle-xmark"
                    @click.stop="rejectBid(quote)">
                    {{ $t('Reject Bid') }}
                </AppButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getId } from '@/helpers';
import Swal from 'sweetalert2';
import { router } from '@inertiajs/vue3';

const { quote } = defineProps({
    quote: {
        type: Object,
        required: true,
        default: () => ({
            bids: []
        })
    }
});

const { open: openQuoteBidCreateModal } = useModal('QuoteBidCreateModal');

function handleOpenQuoteBidCreateModal(quote) {
    openQuoteBidCreateModal({ quote });
}

function acceptBid(quote) {
    const latestBid = quote.bids[quote.bids.length - 1];
    router.post(
        route('quotes.bids.accept', latestBid.id),
        {},
        {
            preserveScroll: true,
            onSuccess: page => {
                Swal.fire({
                    title: 'Bid Accepted',
                    text: page.props.response.message
                });
            }
        }
    );
}

function rejectBid(quote) {
    const latestBid = quote.bids[quote.bids.length - 1];
    router.post(
        route('quotes.bids.reject', latestBid.id),
        {},
        {
            preserveScroll: true,
            onSuccess: page => {
                Swal.fire({
                    title: 'Bid Rejected',
                    text: page.props.response.message
                });
            }
        }
    );
}
</script>
