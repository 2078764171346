<template>
    <AppCard
        :id="getId('load-*-customer-billing', [load])"
        :title="$t('Customer Billing')"
        theme="blue"
        content-class="p-4">
        <LoadBillCustomerBillingNumbers
            :id="getId('load-*-customer-billing-numbers', [load])"
            :load="load"
            :text-area-rows="3" />
        <hr class="my-4 border-iel-gray" />

        <AppHeading
            :id="getId('load-*-billing-details-heading', [load])"
            size="xl"
            class="mb-4">
            {{ $t('Billing Details') }}
        </AppHeading>
        <div>
            <LoadBillCustomerRates
                :id="getId('load-*-customer-rates', [load])"
                :load="load"
                :customer="load.customer"
                heading-size="md" />
            <LoadAccessorialTotal
                :id="getId('load-*-accessorials-total', [load])"
                :load="load" />
            <LoadBillingTotal
                :id="getId('load-*-billing-grand-total', [load])"
                :load="load" />
        </div>
        <hr class="my-4 border-iel-gray" />

        <LoadInvoiceDetails
            :id="getId('load-*-invoice-details', [load])"
            :load="load" />
    </AppCard>
</template>

<script setup>
import { getId } from '@/helpers';
import AppHeading from '@/Components/AppHeading.vue';

defineProps({
    load: {
        type: Object,
        required: true
    }
});
</script>
