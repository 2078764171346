<template>
    <ActiveTendersList
        :active="active"
        :options="options" />
    <InactiveTendersList
        :inactive="inactive"
        :options="options" />
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import QuotesLayout from '@/Layouts/QuotesLayout.vue';

defineOptions({
    layout: [AuthenticatedLayout, QuotesLayout]
});

const { active, inactive, options } = defineProps({
    active: {
        type: Object,
        required: true,
        default: () => {
            [];
        }
    },
    inactive: {
        type: Object,
        required: true,
        default: () => {
            [];
        }
    },
    options: {
        type: Object,
        required: true,
        default: () => ({
            rejection_reasons: []
        })
    }
});

// function openTender(row) {
//     router.visit(route('tenders.show', row.id));
// }
</script>
