export function formatDate(date) {
    if (!date) return '-';
    return new Date(date).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    });
}

export function formatStopDate(date) {
    if (!date) return '-';
    return (
        new Date(date).toLocaleDateString('en-us', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        }) +
        ' ' +
        new Date(date).toLocaleTimeString('en-us', {
            hour: '2-digit',
            minute: '2-digit'
        })
    );
}

export function getId(template, objects) {
    if (!template) return '';
    if (!objects) return template;

    let index = 0;
    return template.replace(/\*/g, () => objects[index++].id);
}

export function formatMoney(amount) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(amount);
}

export function formatComboVals(part1, part2, suffix = '') {
    if (part1 && part2) {
        return `${part1}${suffix} ${part2}`;
    } else if (part1) {
        return `${part1}${suffix}`;
    } else if (part2) {
        return part2;
    } else {
        return '-';
    }
}

export function formatLoadboardLane(input) {
    const item = input.quote || input.row || input;
    if (!item.lane) return null;

    const { start, end } = item.lane;

    return {
        startDate: formatDate(start.dateTime.dateTime),
        endDate: formatDate(end.dateTime.dateTime)
    };
}

export function formatLane(input) {
    const item = input.quote || input.row || input;
    if (!item.lane) return null;

    const { origin, destination } = item.lane;

    const formatLocation = ({ location }) => [location.city, location.region].filter(Boolean).join(', ');

    return {
        origin: formatLocation(origin),
        destination: formatLocation(destination),
        startDate: formatDate(origin.dateTime.dateTime),
        endDate: formatDate(destination.dateTime.dateTime)
    };
}
