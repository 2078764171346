<template>
    <div class="min-h-screen bg-iel-silver p-4">
        <div class="mx-auto grid max-w-[8.5in] gap-6">
            <img
                src="/images/arrow-IEL.png"
                alt="Logo"
                class="h-20" />
            <template v-if="!completedTasks.includes('send_carrier_rate_con') || version !== load.ratecon_hash">
                <h1 class="text-2xl font-bold">There has been an update to the load.</h1>
                <p>
                    Please check your email box for a new link. If you do not see a new link in your inbox, please
                    contact your IEL representative you booked the load with for a new rate confirmation.
                </p>
            </template>
            <template v-else-if="success || completedTasks.includes('receive_carrier_rate_con')">
                <h1 class="text-2xl font-bold">Your signature has been received.</h1>
            </template>
            <template v-else>
                <div class="overflow-hidden rounded-lg bg-white shadow">
                    <div class="border-b border-iel-lightest-gray bg-iel-silver px-6 py-5">
                        <h1 class="text-base font-semibold text-iel-darkest-gray">RateCon</h1>
                    </div>
                    <div class="p-4">
                        <iframe
                            id="accept-ratecon-iframe"
                            class="h-96 w-full"
                            :src="`/loads/${load.id}/ratecon`" />
                    </div>
                </div>
                <div class="flex flex-col gap-4">
                    <div class="flex-1">
                        <div class="text-xl font-bold xl:pr-40">
                            Please review the document and sign by filling and submitting this form.
                        </div>
                    </div>
                    <div class="xl:w-96">
                        <form
                            class="grid gap-2"
                            @submit.prevent="submit">
                            <AppInput
                                id="name"
                                v-model="form.name"
                                name="name"
                                type="text"
                                required
                                label="Full Name"
                                placeholder="Full Name"
                                :error="form.errors.name" />
                            <div>
                                <AppButton type="submit">Accept RateCon</AppButton>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
            <div class="border-t border-iel-lightest-gray pt-6 text-center text-sm text-iel-dark-gray">
                Copyright © 2007-{{ new Date().getFullYear() }} Integrity Express Logistics
            </div>
        </div>
    </div>
</template>

<script setup>
import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import { useForm } from '@inertiajs/vue3';

defineOptions({ layout: DefaultLayout });

const { completedTasks, load, version } = defineProps({
    completedTasks: Array,
    load: Object,
    version: String
});

const form = useForm({
    name: ''
});

const success = ref(false);

function submit() {
    form.post(route('loads.ratecon.sign', load.id), {
        onSuccess() {
            success.value = true;
        }
    });
}
</script>
