import { computed } from 'vue';
import { router } from '@inertiajs/vue3';
import { groupBy } from 'lodash-es';
import Swal from 'sweetalert2';

const { open: openLoadTaskModal } = useModal('LoadTaskModal');
const { open: openLoadCarrierBookModal } = useModal('LoadCarrierBookModal');
const { open: openLoadRateConSendModal } = useModal('LoadRateConSendModal');
const { open: openLoadCheckcallModal } = useModal('LoadCheckcallModal');

export function useTasks(load, options = {}) {
    const transformTasks =
        options.transformTasks ||
        (tasks => {
            const phaseTasks = tasks.filter(task => task.type === 'phase_task');
            const manualTasks = tasks.filter(task => task.type === 'manual');

            return [...phaseTasks, ...manualTasks];
        });

    const tasksByPhase = computed(() => {
        const tasks = transformTasks(load.value.tasks);

        return groupBy(tasks, 'phase');
    });
    const clickHandlers = {
        create_build: task => toggleTask(task),
        add_load_tender: task => toggleTask(task),
        route: task => toggleTask(task),
        setup_appointments: task => toggleTask(task),
        determine_carrier_rate: task => toggleTask(task),
        book_carrier: task => bookCarrier(task),
        send_carrier_rate_con: task => openRateConModal(task),
        invoice_customer: task =>
            load.value.customer.billing_method === 'mail' ? toggleTask(task) : invoiceCustomer(task),
        dispatch: task => openCheckcall(task)
    };

    function editManualTask(task) {
        openLoadTaskModal({
            title: 'Edit/Complete Task',
            mode: 'edit',
            load: load.value,
            task
        });
    }

    function toggleTask(task) {
        router.patch(
            route('loads.tasks.update', [load.value, task]),
            {
                is_complete: !task.is_complete
            },
            {
                preserveScroll: true,
                onSuccess() {
                    task.is_complete = !task.is_complete;
                }
            }
        );
    }

    function bookCarrier(task) {
        openLoadCarrierBookModal({
            load: load.value,
            task
        });
    }

    function openRateConModal(task) {
        openLoadRateConSendModal({
            load: load.value,
            task
        });
    }

    function invoiceCustomer(task) {
        router.post(
            route('loads.customerInvoice.send', [load.value]),
            {},
            {
                preserveScroll: true,
                onSuccess() {
                    task.is_complete = !task.is_complete;
                },
                onError(e) {
                    return Swal.fire({
                        title: e.message || 'Failed',
                        text: 'Ensure this load is ready to be invoiced.',
                        icon: 'error'
                    });
                }
            }
        );
    }

    function handleTaskClick(task) {
        if (task.type === 'manual') {
            editManualTask(task);
            return;
        }

        if (task.stop_id) {
            openCheckcall(task);
            return;
        }

        if (!clickHandlers[task.name]) {
            return;
        }

        clickHandlers[task.name](task);
    }

    return {
        tasksByPhase,
        handleTaskClick,
        hasCompletedTasks
    };

    function openCheckcall(task) {
        const stop = load.value.stops.find(stop => stop.id === task.stop_id);

        // Determine the event based on the stop type and task
        const defaultEvent = stop
            ? {
                  arrived: stop.type === 'pickup' ? 'Arrived at Pickup Location' : 'Arrived at Delivery Location',
                  completed: stop.type === 'pickup' ? 'Loaded' : 'Unloaded',
                  departed: stop.type === 'pickup' ? 'Departed Pickup Location' : 'Departed Delivery Location'
              }[task.name.split('_').pop()]
            : 'Dispatch';

        // Retrieve the existing checkcall for this stop and event, if there is one
        const existingCheckcall = load.value.trackings
            .filter(tracking => tracking.type === 'CC')
            .filter(({ deleted_at }) => !deleted_at)
            .find(
                ({ stop_id, event }) =>
                    // Ensure that the stop ID matches or that there is no stop ID (Dispatch task)
                    (stop_id === stop?.id || (stop_id === null && stop === undefined)) && event === defaultEvent
            );

        // Generate field values to pre-fill
        const defaults = existingCheckcall
            ? {
                  task: task.name,
                  stop_id: existingCheckcall.stop_id,
                  event: existingCheckcall.event,
                  reason: existingCheckcall.reason,
                  source: existingCheckcall.source,
                  // TODO get this date back in a format that's easier for us to handle
                  event_happened_at: existingCheckcall.event_happened_at.substring(0, 16),
                  event_timezone: existingCheckcall.event_timezone,
                  city: existingCheckcall.city,
                  region: existingCheckcall.region,
                  postal_code: existingCheckcall.postal_code,
                  temperature: existingCheckcall.temperature,
                  internal_note: existingCheckcall.internal_note,
                  external_note: existingCheckcall.external_note
              }
            : {
                  task: task.name,
                  event: defaultEvent,
                  stop_id: stop?.id
              };

        // Open the `checkcall` modal and provide it with the necessary context
        // to pre-fill its form fields and submit its data to the backend.
        openLoadCheckcallModal({
            mode: existingCheckcall ? 'edit' : 'create',
            load: load.value,
            stop,
            checkcall: existingCheckcall,
            defaults,
            task
        });
    }

    function hasCompletedTasks(taskNames) {
        return (
            load.tasks.filter(task => task.completed_at).filter(task => taskNames.includes(task.name)).length ===
            taskNames.length
        );
    }
}
