<template>
    <AppCard
        :id="getId('quote-*-bid', [quote])"
        :title="$t('Determine Customer Rate')"
        theme="blue"
        content-class="p-4">
        <div class="grid grid-cols-12 gap-x-2 gap-y-4">
            <div class="col-span-2">
                <AppButton
                    href="https://rateview.dat.com"
                    target="_blank">
                    {{ $t('DAT RateView') }}
                </AppButton>
            </div>

            <div class="col-span-2">
                <AppButton
                    href="https://app.greenscreens.ai/"
                    target="_blank">
                    {{ $t('Greenscreens') }}
                </AppButton>
            </div>
        </div>

        <hr class="my-4 border-iel-gray" />

        <div class="grid grid-cols-12 gap-x-2 gap-y-4">
            <form
                v-if="quote.bids?.length === 0"
                class="contents"
                @submit.prevent="submit">
                <div class="col-span-4">
                    <AppInput
                        :id="getId('quote-*-bid-input', [quote])"
                        v-model="form.amount"
                        :disabled="quote.bids?.length > 0"
                        name="amount"
                        label=""
                        :placeholder="$t('Enter bid amount')"
                        type="number"
                        :min="0"
                        step=".01"
                        class="mt-1 block w-full" />
                </div>

                <div class="col-span-2 flex items-end">
                    <AppButton
                        :id="getId('quote-*-bid-submit', [quote])"
                        icon="far fa-circle-arrow-right"
                        :trailing="true"
                        type="submit">
                        {{ $t('Submit Bid') }}
                    </AppButton>
                </div>
            </form>

            <div
                v-else
                class="col-span-4 flex items-center gap-8">
                <p class="text-lg font-semibold">{{ latestBid.amount }}</p>

                <QuoteTasks
                    v-if="quote.tasks.customer_responded === false"
                    :quote="quote" />
                <p
                    v-if="latestBid.accepted_at"
                    class="text-lg text-iel-dark-green">
                    {{ $t('Bid Accepted') }}
                </p>
                <p
                    v-if="latestBid.rejected_at"
                    class="text-lg text-iel-dark-red">
                    {{ $t('Bid Rejected') }}
                </p>
            </div>
        </div>

        <hr class="my-4 border-iel-gray" />

        <AppHeading
            :id="getId('quote-*-bid-history-header', [quote])"
            size="lg">
            {{ $t('Bid History') }}
        </AppHeading>

        <div
            v-if="quote.bids?.length > 0"
            :id="getId('quote-*-bids-table', [quote])"
            class="max-h-96 overflow-y-auto">
            <table class="w-full">
                <thead>
                    <tr class="border-b">
                        <th class="py-2 text-left">{{ $t('Amount') }}</th>
                        <th class="py-2 text-left">{{ $t('Created At') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="bid in quote.bids"
                        :id="`bid-${bid.id}`"
                        :key="bid.id">
                        <td class="py-2">{{ $t(bid.content) }}</td>
                        <td class="py-2">{{ $t(bid.created_at) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p v-else>
            {{ $t('No bids have been submitted yet.') }}
        </p>
    </AppCard>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { getId } from '@/helpers';

const { quote } = defineProps({
    quote: {
        type: Object,
        required: true,
        default: () => {}
    }
});

const form = useForm({
    amount: null,
    amount_currency: 'USD'
});

function submit() {
    form.post(route('quotes.bid', quote.id), {
        preserveScroll: true,
        onSuccess() {
            form.amount = null;
        }
    });
}

provide('form', form);

const latestBid = computed(() => quote.bids?.[0] || null);
</script>
