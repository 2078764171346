<template>
    <AppHeader
        id="tenders-header"
        :title="$t('Tenders')"
        :icon-component="IconTenders">
        <template #search>
            <AppSearchInput
                id="tenders-search"
                v-model="q"
                :placeholder="$t('Search tenders...')"
                class="w-52" />
        </template>

        <template #actions>
            <AppLayoutToggle />
        </template>
    </AppHeader>
</template>

<script setup>
import IconTenders from '/resources/img/icon-tenders.svg';

const { q } = useQueryParam({ only: ['tenders'] });
</script>
