<template>
    <form
        class="grid gap-5"
        @submit.prevent="handleSubmit">
        <AppSelect
            v-model="form.claim_type"
            name="claim_type"
            :options="claimTypesOptions" />
        <AppInput
            v-model="form.amount"
            name="amount"
            type="number"
            :min="0"
            step=".01"
            @blur="handleAmountBlur" />
        <AppTextarea
            v-model="form.description"
            name="description"
            :label="$t('Description')"
            input-class="resize-none"
            rows="11" />
        <div class="flex items-center justify-end gap-4">
            <AppButton
                variant="outline"
                type="button"
                @click="cancel">
                Cancel
            </AppButton>
            <AppButton type="submit">Submit</AppButton>
        </div>
    </form>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { zipObject } from 'lodash-es';
import { claimTypes } from '@/data';

const { load } = defineProps({
    load: Object
});

const form = useForm({
    claim_type: null,
    amount: 0,
    amount_currency: 'USD',
    required_load_docs: [],
    required_customer_docs: [],
    follow_up_at: null,
    follow_up_at_timezone: null,
    description: null
});

provide('form', form);

const emit = defineEmits(['cancel', 'success']);

const claimTypesOptions = zipObject(claimTypes, claimTypes);

function cancel() {
    form.reset();
    form.clearErrors();
    emit('cancel');
}

function handleSubmit() {
    form.post(route('loads.claims.store', load), {
        preserveScroll: true,
        onSuccess() {
            emit('success');
        }
    });
}

function handleAmountBlur() {
    form.amount = parseFloat(form.amount.toFixed(2));
}
</script>
